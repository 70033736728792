#footer nav ul.nav li:after {
  margin-left: 2px;
  margin-right: 2px;
}

@media (max-height: 1024px) {
  #filter-dialog {
    height: auto;
  }
}

.nameText {
  margin-left: 5px;
  margin-right: 5px;
}

.block {
  display: block;
}

.hide {
  display: none;
}

@media (max-width: 768px) {
  .noFocus {
    display: block !important;
  }
}

.filter-name {
  width: 90%;
  padding-left: 2% !important;
  text-overflow: ellipsis;
  box-shadow: none !important;
}

.custom-select {
  width: 100%;
  margin: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  font-size: 14px !important;
  font-family: robotolight, sans-serif;
  color: #555;
  box-shadow: inset 0 1px 1px #00000013;
  height: 34px;
  padding: 3px 6px;
  background-color: #fff;
  transition: background 0.5s ease;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid #ccc;
  outline: none;
}

.radio-group input[type="radio"] {
  display: none;
}

.radio-group input[type="radio"]:checked + label {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad;
  box-shadow: inset 0 3px 5px #00000020;
}

.clear-button {
  position: absolute;
  right: 30px;
  top: 4px;
  background: none;
  border: none;
}

.clear-button::before {
  content: "\2715";
}

.container-show-items > .first-show-item {
  max-height: 100%;
  visibility: visible;
}

.container-show-items > .second-show-item {
  visibility: hidden;
  max-height: 0 !important;
}

.container-show-items:hover > .first-show-item {
  visibility: hidden;
  max-height: 0 !important;
}

.container-show-items:hover > .second-show-item {
  visibility: visible;
  max-height: 100%;
}

.error-color {
  color: #b94a48 !important;
}

.image-background {
  background-image: url("/assets/images/coveredbg.jpg");
  height: auto;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.default-button {
  border: none;
  background: none;
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.ng-select.custom {
  margin-top: 0;
}
.ng-select.ng-select-single .ng-select-container {
  min-height: 28px;
  margin: 0;
  max-height: 28px;
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ms-ctn input {
  width: 100%;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-40 {
  font-size: 40px;
}
.h-20 {
  height: 20px;
}
.no-margin {
  margin: 0;
  padding: 0;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 8, 2014 */

@font-face {
  font-family: "robotoblack";
  src: url("roboto-black-webfont.eot");
  src:
    url("roboto-black-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-black-webfont.woff") format("woff"),
    url("roboto-black-webfont.ttf") format("truetype"),
    url("roboto-black-webfont.svg#robotoblack") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoblack_italic";
  src: url("roboto-blackitalic-webfont.eot");
  src:
    url("roboto-blackitalic-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-blackitalic-webfont.woff") format("woff"),
    url("roboto-blackitalic-webfont.ttf") format("truetype"),
    url("roboto-blackitalic-webfont.svg#robotoblack_italic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotobold";
  src: url("roboto-bold-webfont.eot");
  src:
    url("roboto-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-bold-webfont.woff") format("woff"),
    url("roboto-bold-webfont.ttf") format("truetype"),
    url("roboto-bold-webfont.svg#robotobold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotobold_italic";
  src: url("roboto-bolditalic-webfont.eot");
  src:
    url("roboto-bolditalic-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-bolditalic-webfont.woff") format("woff"),
    url("roboto-bolditalic-webfont.ttf") format("truetype"),
    url("roboto-bolditalic-webfont.svg#robotobold_italic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoitalic";
  src: url("roboto-italic-webfont.eot");
  src:
    url("roboto-italic-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-italic-webfont.woff") format("woff"),
    url("roboto-italic-webfont.ttf") format("truetype"),
    url("roboto-italic-webfont.svg#robotoitalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotolight";
  src: url("roboto-light-webfont.eot");
  src:
    url("roboto-light-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-light-webfont.woff") format("woff"),
    url("roboto-light-webfont.ttf") format("truetype"),
    url("roboto-light-webfont.svg#robotolight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotolight_italic";
  src: url("roboto-lightitalic-webfont.eot");
  src:
    url("roboto-lightitalic-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-lightitalic-webfont.woff") format("woff"),
    url("roboto-lightitalic-webfont.ttf") format("truetype"),
    url("roboto-lightitalic-webfont.svg#robotolight_italic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotomedium";
  src: url("roboto-medium-webfont.eot");
  src:
    url("roboto-medium-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-medium-webfont.woff") format("woff"),
    url("roboto-medium-webfont.ttf") format("truetype"),
    url("roboto-medium-webfont.svg#robotomedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotomedium_italic";
  src: url("roboto-mediumitalic-webfont.eot");
  src:
    url("roboto-mediumitalic-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-mediumitalic-webfont.woff") format("woff"),
    url("roboto-mediumitalic-webfont.ttf") format("truetype"),
    url("roboto-mediumitalic-webfont.svg#robotomedium_italic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoregular";
  src: url("roboto-regular-webfont.eot");
  src:
    url("roboto-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-regular-webfont.woff") format("woff"),
    url("roboto-regular-webfont.ttf") format("truetype"),
    url("roboto-regular-webfont.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotothin";
  src: url("roboto-thin-webfont.eot");
  src:
    url("roboto-thin-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-thin-webfont.woff") format("woff"),
    url("roboto-thin-webfont.ttf") format("truetype"),
    url("roboto-thin-webfont.svg#robotothin") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotothin_italic";
  src: url("roboto-thinitalic-webfont.eot");
  src:
    url("roboto-thinitalic-webfont.eot?#iefix") format("embedded-opentype"),
    url("roboto-thinitalic-webfont.woff") format("woff"),
    url("roboto-thinitalic-webfont.ttf") format("truetype"),
    url("roboto-thinitalic-webfont.svg#robotothin_italic") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*
* Kendo UI v2014.3.1119 (http://www.telerik.com/kendo-ui)
* Copyright 2014 Telerik AD. All rights reserved.
*
* Kendo UI commercial licenses may be obtained at
* http://www.telerik.com/purchase/license-agreement/kendo-ui-complete
* If you do not own a commercial license, this file shall be governed by the trial license terms.
*/
.k-chart,
.k-gauge,
.k-sparkline,
.k-stockchart {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-chart,
.k-stockchart {
  height: 400px;
}
div.k-chart,
div.k-gauge,
span.k-sparkline,
.k-stockchart {
  background-color: transparent;
}
.k-gauge {
  position: relative;

  text-align: left;
}
.k-baseline-marker {
  zoom: 1;
  display: inline;
}
.k-chart-tooltip {
  line-height: normal;
  z-index: 12000;
  padding: 6px;
  white-space: nowrap;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNJREFUeNpi/P//vwMDFQELEP8beQb+HTWQYgP/DHoD/466cAR4edRAyg38P6hLbIAAAwCnWhhVsxvdCAAAAABJRU5ErkJggg==);
  background-repeat: repeat-x;
  background-position: 0 0;
}
.k-chart-tooltip-inverse {
  color: #000;
}
.k-chart-tooltip table {
  border-spacing: 0;
  border-collapse: collapse;
}
.k-chart-tooltip th {
  width: auto;
  padding: 1px;
  text-align: center;
}
.k-chart-tooltip td {
  width: auto;
  padding: 0.1em 0.2em;
  text-align: left;
}
.k-selector {
  position: absolute;
  -webkit-transform: translateZ(0);
}
.k-selection {
  position: absolute;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #d2d2d2;
  border-bottom: 0;
}
.k-selection-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.01);
  filter: alpha(opacity=1);
}
.k-handle {
  position: absolute;
  z-index: 1;
  width: 7px;
  height: 26px;
  cursor: e-resize;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background: #d2d2d2;
}
.k-handle div {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.k-leftHandle {
  left: -4px;
}
.k-rightHandle {
  right: -4px;
}
.k-leftHandle div {
  margin: -20px 0 0 -15px;
  padding: 40px 30px 0 0;
}
.k-rightHandle div {
  margin: -20px 0 0 -15px;
  padding: 40px 0 0 30px;
}
.k-mask {
  position: absolute;
  height: 100%;
  opacity: 0.8;
  background-color: #fff;
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
}
.k-border {
  position: absolute;
  width: 1px;
  height: 100%;

  background: #d2d2d2;
}
.k-navigator-hint div {
  position: absolute;
}
.k-navigator-hint .k-scroll {
  position: absolute;
  height: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #d2d2d2;
}
.k-navigator-hint .k-tooltip {
  min-width: 160px;
  margin-top: 20px;
  text-align: center;
  opacity: 1;
  border: 0;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
.k-sparkline,
.k-sparkline span {
  display: inline-block;
  zoom: 1;
  vertical-align: top;
  display: inline;
}
.k-sparkline span {
  width: 100%;
  height: 100%;
}
.k-map,
.k-diagram {
  height: 600px;
}
.k-map .km-scroll-wrapper,
.k-diagram .km-scroll-wrapper {
  padding-bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.k-map .km-scroll-wrapper,
.k-diagram .km-scroll-wrapper,
.k-map .km-scroll-container,
.k-diagram .km-scroll-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.k-map .k-layer,
.k-diagram .k-layer {
  position: absolute;
  top: 0;
  left: 0;
}
.k-map .km-touch-scrollbar,
.k-diagram .km-touch-scrollbar {
  display: none;
}
.k-map .k-marker {
  position: absolute;
  width: 28px;
  height: 40px;
  margin: -40px 0 0 -14px;
  cursor: pointer;
}
.k-map .k-marker-pin {
  background-position: 0 40px;
}
.k-map .k-marker-pin-target {
  background-position: 0 0;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .k-map .k-marker {
    width: 56px;
    height: 80px;
    margin: -80px 0 0 -28px;
  }
  .k-map .k-marker-pin {
    background-position: 0 80px;
  }
}
.k-map .k-pos-top {
  top: 0;
}
.k-map .k-pos-bottom {
  bottom: 0;
}
.k-map .k-pos-left {
  left: 0;
}
.k-map .k-pos-right {
  right: 0;
}
.k-map-controls {
  position: absolute;
}
.k-map-controls.k-pos-left .k-widget:first-child {
  margin-right: 0;
}
.k-map-controls.k-pos-right .k-widget:first-child {
  margin-left: 0;
}
.k-navigator {
  position: relative;
  display: inline-block;

  width: 50px;
  height: 50px;
  margin: 20px;
  vertical-align: middle;
  border-radius: 80px;
}
.k-ie7 .k-navigator {
  display: inline;
  zoom: 1;
}
.k-navigator > button {
  border-color: transparent;
  background: 0;
}
.k-ie7 .k-navigator > button {
  display: block;

  border-width: 0;
}
.k-ie7 .k-navigator > button > span {
  display: block;

  vertical-align: top;
}
div.k-navigator > .k-button {
  font-size: 1px;
  line-height: 10px;
  line-height: 1px;
  position: absolute;

  margin: 0;
  padding: 0;
  border-radius: 16px;
}
div.k-navigator .k-navigator-n {
  top: 2px;
  left: 50%;
  margin-left: -9px;
}
div.k-navigator .k-navigator-e {
  top: 50%;
  right: 2px;
  margin-top: -9px;
}
div.k-navigator .k-navigator-s {
  bottom: 2px;
  left: 50%;
  margin-left: -9px;
}
div.k-navigator .k-navigator-w {
  top: 50%;
  left: 2px;
  margin-top: -9px;
}
.k-ie7 div.k-navigator .k-navigator-n,
.k-ie7 div.k-navigator .k-navigator-s {
  margin-left: -8px;
}
.k-ie7 div.k-navigator .k-navigator-w,
.k-ie7 div.k-navigator .k-navigator-e {
  margin-top: -8px;
}
.k-map .k-attribution {
  font-size: 10px;
  z-index: 1000;
  padding: 2px 4px;

  background-color: rgba(255, 255, 255, 0.8);
}
.k-zoom-control {
  margin: 14px;
  vertical-align: middle;
}
.k-button-wrap {
  display: inline-block;

  border-radius: 4px;
}
.k-ie7 .k-button-wrap {
  display: inline;
  zoom: 1;
}
.k-button-wrap .k-button {
  font: bold 17px/1.18 monospace;

  position: relative;
}
.k-ie7 .k-button-wrap .k-button {
  margin: 0;
}
.k-buttons-horizontal :first-child {
  border-radius: 4px 0 0 4px;
}
.k-buttons-horizontal :first-child + .k-zoom-in {
  margin-left: -1px;

  border-radius: 0;
}
.k-buttons-horizontal .k-zoom-out {
  margin-left: -1px;

  border-radius: 0 4px 4px 0;
}
.k-button-wrap .k-button:hover {
  z-index: 1;
}
.k-buttons-vertical .k-button {
  display: block;
}
.k-buttons-vertical :first-child {
  border-radius: 4px 4px 0 0;
}
.k-buttons-vertical .k-zoom-out {
  margin-top: -1px;

  border-radius: 0 0 4px 4px;
}
.k-zoom-text {
  width: 4.3em;
  margin: 0;
  vertical-align: top;
}
.k-rtl .k-buttons-horizontal :first-child {
  border-radius: 0 4px 4px 0;
}
.k-rtl .k-buttons-horizontal :first-child + .k-zoom-in {
  margin-right: -1px;
  margin-left: 0;

  border-radius: 0;
}
.k-rtl .k-buttons-horizontal .k-zoom-out {
  margin-right: -1px;
  margin-left: 0;

  border-radius: 4px 0 0 4px;
}
.k-diagram {
  height: 600px;
}
.k-diagram .km-scroll-wrapper {
  position: relative;

  width: 100%;
  height: 100%;
}
.k-diagram .km-scroll-wrapper {
  position: relative;

  width: 100%;
  height: 100%;
}
.k-canvas-container {
  width: 100%;
  height: 100%;
}
.k-diagram img {
  box-sizing: content-box;
}
.k-treemap {
  overflow: hidden;
  height: 400px;
}
.k-treemap-tile {
  position: absolute;
  overflow: hidden;

  box-sizing: border-box;
  margin: -1px 0 0 -1px;
  border-width: 1px;
  border-style: solid;
}
.k-treemap-tile.k-leaf {
  padding: 0.6em;
}
.k-treemap-wrap.k-last > .k-treemap-tile {
  padding: 0.3em;
}
.k-treemap-tile.k-state-hover {
  z-index: 2;
  background-image: none;
}
.k-ie7 .k-treemap .k-treemap-tile {
  padding: 0;

  border-width: 0;
}
.k-ie7 .k-treemap .k-leaf > div {
  padding: 7px;
}
.k-ie7 .k-treemap .k-leaf.k-state-hover {
  border-width: 1px;
}
.k-ie7 .k-treemap .k-leaf.k-state-hover > div {
  padding: 6px;
}
.k-treemap > .k-treemap-tile {
  position: relative;
  height: 100%;
}
.k-treemap-title {
  line-height: 2.42em;
  overflow: hidden;

  box-sizing: border-box;
  height: 2.42em;
  padding: 0 0.6em;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.k-treemap-wrap .k-treemap-title {
  border-width: 0 0 1px;
  border-style: solid;
}
.k-treemap-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.k-treemap-title + .k-treemap-wrap {
  top: 2.42em;
}
.k-treemap-title-vertical {
  line-height: 2.42em;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;

  box-sizing: border-box;
  width: 2.42em;
  padding: 0.6em 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.k-treemap-title-vertical > div {
  position: absolute;
  top: 0;
  right: 1.23em;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: right;
  transform-origin: right;
}
.k-treemap-title-vertical + .k-treemap-wrap {
  left: 2.42em;
}

/*
* Kendo UI v2014.3.1119 (http://www.telerik.com/kendo-ui)
* Copyright 2014 Telerik AD. All rights reserved.
*
* Kendo UI commercial licenses may be obtained at
* http://www.telerik.com/purchase/license-agreement/kendo-ui-complete
* If you do not own a commercial license, this file shall be governed by the trial license terms.
*/
.fake {
  color: red;
}
.k-reset {
  font-size: 100%;

  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  border: 0;
  outline: 0;
}
.k-floatwrap:after,
.k-slider-items:after,
.k-grid-toolbar:after {
  display: block;
  visibility: hidden;
  clear: both;
  overflow: hidden;
  height: 0;

  content: "";
}
.k-floatwrap,
.k-slider-items,
.k-grid-toolbar {
  display: inline-block;
}
.k-floatwrap,
.k-slider-items,
.k-grid-toolbar {
  display: block;
}
.k-block,
.k-button,
.k-header,
.k-grid-header,
.k-toolbar,
.k-grouping-header,
.k-tooltip,
.k-pager-wrap,
.k-tabstrip-items .k-item,
.k-link.k-state-hover,
.k-textbox,
.k-textbox:hover,
.k-autocomplete,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap,
.k-autocomplete.k-state-hover,
.k-dropdown-wrap.k-state-hover,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-draghandle {
  background-repeat: repeat;
  background-position: 0 center;
}
.k-link:hover {
  text-decoration: none;
}
.k-state-highlight > .k-link {
  color: inherit;
}
.k-textbox > input,
.k-input[type="text"],
.k-input[type="number"],
.k-textbox,
.k-picker-wrap .k-input,
.k-button {
  font-family: inherit;
  font-size: 100%;
  border-width: 1px;
  border-style: solid;
  -webkit-appearance: none;
}
.k-widget,
.k-block,
.k-inline-block,
.k-draghandle {
  border-width: 1px;
  border-style: solid;
  -webkit-appearance: none;
}
.k-block,
.k-widget {
  line-height: normal;
  outline: 0;
}
.k-block {
  padding: 2px;
}
.k-button {
  font-family: inherit;
  line-height: 1.72em;

  display: inline-block;
  margin: 0;
  padding: 2px 7px 2px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
.k-button[disabled],
.k-button.k-state-disabled,
.k-state-disabled .k-button,
.k-state-disabled .k-button:hover,
.k-button.k-state-disabled:hover,
.k-state-disabled .k-button:active,
.k-button.k-state-disabled:active {
  cursor: default;
}
.k-ie7 .k-button {
  line-height: normal;
}
a.k-button {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;

  -khtml-user-select: none;
}
.k-ff input.k-button {
  padding-top: 0.37em;
  padding-bottom: 0.37em;
}
.k-ie7 .k-button {
  overflow: visible;
  margin-right: 4px;
}
.k-ie7 a.k-button {
  line-height: 1.6;
  padding-right: 7px;
  padding-left: 7px;
}
.k-ie7 .k-slider a.k-button {
  line-height: 22px;

  height: 22px;
  padding: 0;
}
.k-ie7 .k-button-expand {
  margin-right: 0;
  margin-left: 0;
}
button.k-button::-moz-focus-inner,
input.k-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a.k-button-expand {
  display: block;
}
button.k-button-expand,
input[type="submit"].k-button-expand,
input[type="button"].k-button-expand,
input[type="reset"].k-button-expand {
  width: 100%;
}
body .k-button-icon,
body .k-split-button-arrow {
  padding-right: 0.4em;
  padding-left: 0.4em;
}
.k-ie7 a.k-button-icon {
  padding-right: 5px;
  padding-left: 5px;
}
.k-button-icontext {
  overflow: visible;
}
.k-toolbar .k-button-icontext {
  padding-right: 0.8em;
}
.k-button-icontext .k-icon,
.k-button-icontext .k-image {
  margin-right: 3px;
  margin-right: 0.3rem;
  margin-left: -3px;
  margin-left: -0.3rem;
}
.k-button.k-button-icontext .k-icon,
.k-button.k-button-icontext .k-image {
  vertical-align: text-top;
}
html body .k-button-bare {
  color: inherit;
  border-width: 0;
  background: none !important;
}
html body .k-button-bare.k-upload-button:hover {
  color: inherit;
}
.k-link {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}
.k-grid-header span.k-link {
  cursor: default;
}
.k-state-disabled,
.k-state-disabled .k-link,
.k-state-disabled .k-icon,
.k-state-disabled .k-button,
.k-state-disabled .k-draghandle,
.k-state-disabled .k-upload-button input {
  cursor: default !important;
  outline: 0;
}
@media print {
  .k-state-disabled,
  .k-state-disabled .k-input {
    opacity: 1 !important;
  }
}
.k-state-error {
  border-style: ridge;
}
.k-state-empty {
  font-style: italic;
}
.k-icon,
.k-sprite,
.k-button-group .k-tool-icon {
  font-size: 0;
  line-height: 0;

  display: inline-block;
  overflow: hidden;
  width: 16px;
  height: 16px;
  text-align: center;
  background-repeat: no-repeat;
  -ms-high-contrast-adjust: none;
}
.k-icon.k-i-none {
  background-image: none !important;
}
.k-ie8 .k-icon,
.k-ie8 .k-sprite,
.k-ie8 .k-button-group .k-tool-icon {
  vertical-align: middle;
}
:root * > .k-icon,
:root * > .k-sprite,
:root * > .k-button-group .k-tool-icon {
  vertical-align: middle;
}
.k-icon,
.k-sprite {
  background-color: transparent;
}
.k-ie7 .k-icon,
.k-ie7 .k-sprite {
  text-indent: 0;
}
.k-numerictextbox .k-select .k-link span.k-i-arrow-n {
  background-position: 0 -3px;
}
.k-numerictextbox .k-select .k-link span.k-i-arrow-s {
  background-position: 0 -35px;
}
.k-state-selected .k-i-arrow-n {
  background-position: -16px 0;
}
.k-link:not(.k-state-disabled):hover > .k-state-selected .k-i-arrow-n,
.k-state-hover > .k-state-selected .k-i-arrow-n,
.k-state-hover > * > .k-state-selected .k-i-arrow-n,
.k-button:not(.k-state-disabled):hover .k-state-selected .k-i-arrow-n,
.k-textbox:hover .k-state-selected .k-i-arrow-n,
.k-button:active .k-state-selected .k-i-arrow-n {
  background-position: -32px 0;
}
.k-numerictextbox .k-link.k-state-selected span.k-i-arrow-n,
.k-numerictextbox .k-state-hover .k-link span.k-i-arrow-n {
  background-position: -16px -3px;
}
.k-state-selected .k-i-arrow-s {
  background-position: -16px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-state-selected .k-i-arrow-s,
.k-state-hover > .k-state-selected .k-i-arrow-s,
.k-state-hover > * > .k-state-selected .k-i-arrow-s,
.k-button:not(.k-state-disabled):hover .k-state-selected .k-i-arrow-s,
.k-textbox:hover .k-state-selected .k-i-arrow-s,
.k-button:active .k-state-selected .k-i-arrow-s {
  background-position: -32px -32px;
}
.k-numerictextbox .k-link.k-state-selected span.k-i-arrow-s,
.k-numerictextbox .k-state-hover .k-link span.k-i-arrow-s {
  background-position: -16px -35px;
}
.k-grid-header th > .k-link:hover span.k-i-arrow-n {
  background-position: 0 0;
}
.k-grid-header th > .k-link:hover span.k-i-arrow-s {
  background-position: 0 -32px;
}
.k-group-indicator .k-link:hover span.k-si-arrow-n {
  background-position: 0 -129px;
}
.k-group-indicator .k-link:hover span.k-si-arrow-s {
  background-position: 0 -159px;
}
.k-group-indicator .k-button:hover span.k-group-delete {
  background-position: -32px -16px;
}
.k-scheduler .k-scheduler-toolbar .k-nav-current .k-link .k-i-calendar {
  background-position: -32px -176px;
}
.k-i-arrow-n {
  background-position: 0 0;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrow-n,
.k-state-hover > .k-i-arrow-n,
.k-state-hover > * > .k-i-arrow-n,
.k-button:not(.k-state-disabled):hover .k-i-arrow-n,
.k-textbox:hover .k-i-arrow-n,
.k-button:active .k-i-arrow-n {
  background-position: -16px 0;
}
.k-i-arrow-e {
  background-position: 0 -16px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrow-e,
.k-state-hover > .k-i-arrow-e,
.k-state-hover > * > .k-i-arrow-e,
.k-button:not(.k-state-disabled):hover .k-i-arrow-e,
.k-textbox:hover .k-i-arrow-e,
.k-button:active .k-i-arrow-e {
  background-position: -16px -16px;
}
.k-rtl .k-i-arrow-w {
  background-position: 0 -16px;
}
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-arrow-w,
.k-rtl .k-state-hover > .k-i-arrow-w,
.k-rtl .k-state-hover > * > .k-i-arrow-w,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-arrow-w,
.k-rtl .k-textbox:hover .k-i-arrow-w,
.k-rtl .k-button:active .k-i-arrow-w {
  background-position: -16px -16px;
}
.k-i-arrow-s {
  background-position: 0 -32px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrow-s,
.k-state-hover > .k-i-arrow-s,
.k-state-hover > * > .k-i-arrow-s,
.k-button:not(.k-state-disabled):hover .k-i-arrow-s,
.k-textbox:hover .k-i-arrow-s,
.k-button:active .k-i-arrow-s {
  background-position: -16px -32px;
}
.k-i-arrow-w {
  background-position: 0 -48px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrow-w,
.k-state-hover > .k-i-arrow-w,
.k-state-hover > * > .k-i-arrow-w,
.k-button:not(.k-state-disabled):hover .k-i-arrow-w,
.k-textbox:hover .k-i-arrow-w,
.k-button:active .k-i-arrow-w {
  background-position: -16px -48px;
}
.k-rtl .k-i-arrow-e {
  background-position: 0 -48px;
}
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-arrow-e,
.k-rtl .k-state-hover > .k-i-arrow-e,
.k-rtl .k-state-hover > * > .k-i-arrow-e,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-arrow-e,
.k-rtl .k-textbox:hover .k-i-arrow-e,
.k-rtl .k-button:active .k-i-arrow-e {
  background-position: -16px -48px;
}
.k-i-seek-n {
  background-position: 0 -64px;
}
.k-link:not(.k-state-disabled):hover > .k-i-seek-n,
.k-state-hover > .k-i-seek-n,
.k-state-hover > * > .k-i-seek-n,
.k-button:not(.k-state-disabled):hover .k-i-seek-n,
.k-textbox:hover .k-i-seek-n,
.k-button:active .k-i-seek-n {
  background-position: -16px -64px;
}
.k-i-seek-e {
  background-position: 0 -80px;
}
.k-link:not(.k-state-disabled):hover > .k-i-seek-e,
.k-state-hover > .k-i-seek-e,
.k-state-hover > * > .k-i-seek-e,
.k-button:not(.k-state-disabled):hover .k-i-seek-e,
.k-textbox:hover .k-i-seek-e,
.k-button:active .k-i-seek-e {
  background-position: -16px -80px;
}
.k-rtl .k-i-seek-w {
  background-position: 0 -80px;
}
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-seek-w,
.k-rtl .k-state-hover > .k-i-seek-w,
.k-rtl .k-state-hover > * > .k-i-seek-w,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-seek-w,
.k-rtl .k-textbox:hover .k-i-seek-w,
.k-rtl .k-button:active .k-i-seek-w {
  background-position: -16px -80px;
}
.k-i-seek-s {
  background-position: 0 -96px;
}
.k-link:not(.k-state-disabled):hover > .k-i-seek-s,
.k-state-hover > .k-i-seek-s,
.k-state-hover > * > .k-i-seek-s,
.k-button:not(.k-state-disabled):hover .k-i-seek-s,
.k-textbox:hover .k-i-seek-s,
.k-button:active .k-i-seek-s {
  background-position: -16px -96px;
}
.k-i-seek-w {
  background-position: 0 -112px;
}
.k-link:not(.k-state-disabled):hover > .k-i-seek-w,
.k-state-hover > .k-i-seek-w,
.k-state-hover > * > .k-i-seek-w,
.k-button:not(.k-state-disabled):hover .k-i-seek-w,
.k-textbox:hover .k-i-seek-w,
.k-button:active .k-i-seek-w {
  background-position: -16px -112px;
}
.k-rtl .k-i-seek-e {
  background-position: 0 -112px;
}
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-seek-e,
.k-rtl .k-state-hover > .k-i-seek-e,
.k-rtl .k-state-hover > * > .k-i-seek-e,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-seek-e,
.k-rtl .k-textbox:hover .k-i-seek-e,
.k-rtl .k-button:active .k-i-seek-e {
  background-position: -16px -112px;
}
.k-si-arrow-n {
  background-position: 0 -129px;
}
.k-link:not(.k-state-disabled):hover > .k-si-arrow-n,
.k-state-hover > .k-si-arrow-n,
.k-state-hover > * > .k-si-arrow-n,
.k-button:not(.k-state-disabled):hover .k-si-arrow-n,
.k-textbox:hover .k-si-arrow-n,
.k-button:active .k-si-arrow-n {
  background-position: -16px -129px;
}
.k-si-arrow-e {
  background-position: 0 -144px;
}
.k-link:not(.k-state-disabled):hover > .k-si-arrow-e,
.k-state-hover > .k-si-arrow-e,
.k-state-hover > * > .k-si-arrow-e,
.k-button:not(.k-state-disabled):hover .k-si-arrow-e,
.k-textbox:hover .k-si-arrow-e,
.k-button:active .k-si-arrow-e {
  background-position: -16px -144px;
}
.k-si-arrow-s {
  background-position: 0 -159px;
}
.k-link:not(.k-state-disabled):hover > .k-si-arrow-s,
.k-state-hover > .k-si-arrow-s,
.k-state-hover > * > .k-si-arrow-s,
.k-button:not(.k-state-disabled):hover .k-si-arrow-s,
.k-textbox:hover .k-si-arrow-s,
.k-button:active .k-si-arrow-s {
  background-position: -16px -159px;
}
.k-si-arrow-w {
  background-position: 0 -176px;
}
.k-link:not(.k-state-disabled):hover > .k-si-arrow-w,
.k-state-hover > .k-si-arrow-w,
.k-state-hover > * > .k-si-arrow-w,
.k-button:not(.k-state-disabled):hover .k-si-arrow-w,
.k-textbox:hover .k-si-arrow-w,
.k-button:active .k-si-arrow-w {
  background-position: -16px -176px;
}
.k-i-arrowhead-n {
  background-position: 0 -256px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-n,
.k-state-hover > .k-i-arrowhead-n,
.k-state-hover > * > .k-i-arrowhead-n,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-n,
.k-textbox:hover .k-i-arrowhead-n,
.k-button:active .k-i-arrowhead-n {
  background-position: -16px -256px;
}
.k-i-arrowhead-e {
  background-position: 0 -272px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-e,
.k-state-hover > .k-i-arrowhead-e,
.k-state-hover > * > .k-i-arrowhead-e,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-e,
.k-textbox:hover .k-i-arrowhead-e,
.k-button:active .k-i-arrowhead-e {
  background-position: -16px -272px;
}
.k-i-arrowhead-s {
  background-position: 0 -288px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-s,
.k-state-hover > .k-i-arrowhead-s,
.k-state-hover > * > .k-i-arrowhead-s,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-s,
.k-textbox:hover .k-i-arrowhead-s,
.k-button:active .k-i-arrowhead-s {
  background-position: -16px -288px;
}
.k-i-arrowhead-w {
  background-position: 0 -304px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-w,
.k-state-hover > .k-i-arrowhead-w,
.k-state-hover > * > .k-i-arrowhead-w,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-w,
.k-textbox:hover .k-i-arrowhead-w,
.k-button:active .k-i-arrowhead-w {
  background-position: -16px -304px;
}
.k-i-expand,
.k-plus,
.k-plus-disabled {
  background-position: 0 -192px;
}
.k-link:not(.k-state-disabled):hover > .k-i-expand,
.k-link:not(.k-state-disabled):hover > .k-plus,
.k-link:not(.k-state-disabled):hover > .k-plus-disabled,
.k-state-hover > .k-i-expand,
.k-state-hover > .k-plus,
.k-state-hover > .k-plus-disabled,
.k-state-hover > * > .k-i-expand,
.k-state-hover > * > .k-plus,
.k-state-hover > * > .k-plus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-expand,
.k-button:not(.k-state-disabled):hover .k-plus,
.k-button:not(.k-state-disabled):hover .k-plus-disabled,
.k-textbox:hover .k-i-expand,
.k-textbox:hover .k-plus,
.k-textbox:hover .k-plus-disabled,
.k-button:active .k-i-expand,
.k-button:active .k-plus,
.k-button:active .k-plus-disabled {
  background-position: -16px -192px;
}
.k-i-expand-w,
.k-rtl .k-i-expand,
.k-rtl .k-plus,
.k-rtl .k-plus-disabled {
  background-position: 0 -208px;
}
.k-link:not(.k-state-disabled):hover > .k-i-expand-w,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-i-expand,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-plus,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-plus-disabled,
.k-state-hover > .k-i-expand-w,
.k-state-hover > .k-rtl .k-i-expand,
.k-state-hover > .k-rtl .k-plus,
.k-state-hover > .k-rtl .k-plus-disabled,
.k-state-hover > * > .k-i-expand-w,
.k-state-hover > * > .k-rtl .k-i-expand,
.k-state-hover > * > .k-rtl .k-plus,
.k-state-hover > * > .k-rtl .k-plus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-expand-w,
.k-button:not(.k-state-disabled):hover .k-rtl .k-i-expand,
.k-button:not(.k-state-disabled):hover .k-rtl .k-plus,
.k-button:not(.k-state-disabled):hover .k-rtl .k-plus-disabled,
.k-textbox:hover .k-i-expand-w,
.k-textbox:hover .k-rtl .k-i-expand,
.k-textbox:hover .k-rtl .k-plus,
.k-textbox:hover .k-rtl .k-plus-disabled,
.k-button:active .k-i-expand-w,
.k-button:active .k-rtl .k-i-expand,
.k-button:active .k-rtl .k-plus,
.k-button:active .k-rtl .k-plus-disabled {
  background-position: -16px -208px;
}
.k-i-collapse,
.k-minus,
.k-minus-disabled {
  background-position: 0 -224px;
}
.k-link:not(.k-state-disabled):hover > .k-i-collapse,
.k-link:not(.k-state-disabled):hover > .k-minus,
.k-link:not(.k-state-disabled):hover > .k-minus-disabled,
.k-state-hover > .k-i-collapse,
.k-state-hover > .k-minus,
.k-state-hover > .k-minus-disabled,
.k-state-hover > * > .k-i-collapse,
.k-state-hover > * > .k-minus,
.k-state-hover > * > .k-minus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-collapse,
.k-button:not(.k-state-disabled):hover .k-minus,
.k-button:not(.k-state-disabled):hover .k-minus-disabled,
.k-textbox:hover .k-i-collapse,
.k-textbox:hover .k-minus,
.k-textbox:hover .k-minus-disabled,
.k-button:active .k-i-collapse,
.k-button:active .k-minus,
.k-button:active .k-minus-disabled {
  background-position: -16px -224px;
}
.k-i-collapse-w,
.k-rtl .k-i-collapse,
.k-rtl .k-minus,
.k-rtl .k-minus-disabled {
  background-position: 0 -240px;
}
.k-link:not(.k-state-disabled):hover > .k-i-collapse-w,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-i-collapse,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-minus,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-minus-disabled,
.k-state-hover > .k-i-collapse-w,
.k-state-hover > .k-rtl .k-i-collapse,
.k-state-hover > .k-rtl .k-minus,
.k-state-hover > .k-rtl .k-minus-disabled,
.k-state-hover > * > .k-i-collapse-w,
.k-state-hover > * > .k-rtl .k-i-collapse,
.k-state-hover > * > .k-rtl .k-minus,
.k-state-hover > * > .k-rtl .k-minus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-collapse-w,
.k-button:not(.k-state-disabled):hover .k-rtl .k-i-collapse,
.k-button:not(.k-state-disabled):hover .k-rtl .k-minus,
.k-button:not(.k-state-disabled):hover .k-rtl .k-minus-disabled,
.k-textbox:hover .k-i-collapse-w,
.k-textbox:hover .k-rtl .k-i-collapse,
.k-textbox:hover .k-rtl .k-minus,
.k-textbox:hover .k-rtl .k-minus-disabled,
.k-button:active .k-i-collapse-w,
.k-button:active .k-rtl .k-i-collapse,
.k-button:active .k-rtl .k-minus,
.k-button:active .k-rtl .k-minus-disabled {
  background-position: -16px -240px;
}
.k-i-pencil,
.k-edit {
  background-position: -32px 0;
}
.k-link:not(.k-state-disabled):hover > .k-i-pencil,
.k-link:not(.k-state-disabled):hover > .k-edit,
.k-state-hover > .k-i-pencil,
.k-state-hover > .k-edit,
.k-state-hover > * > .k-i-pencil,
.k-state-hover > * > .k-edit,
.k-button:not(.k-state-disabled):hover .k-i-pencil,
.k-button:not(.k-state-disabled):hover .k-edit,
.k-textbox:hover .k-i-pencil,
.k-textbox:hover .k-edit,
.k-button:active .k-i-pencil,
.k-button:active .k-edit {
  background-position: -48px 0;
}
.k-i-close,
.k-delete,
.k-group-delete {
  background-position: -32px -16px;
}
.k-link:not(.k-state-disabled):hover > .k-i-close,
.k-link:not(.k-state-disabled):hover > .k-delete,
.k-link:not(.k-state-disabled):hover > .k-group-delete,
.k-state-hover > .k-i-close,
.k-state-hover > .k-delete,
.k-state-hover > .k-group-delete,
.k-state-hover > * > .k-i-close,
.k-state-hover > * > .k-delete,
.k-state-hover > * > .k-group-delete,
.k-button:not(.k-state-disabled):hover .k-i-close,
.k-button:not(.k-state-disabled):hover .k-delete,
.k-button:not(.k-state-disabled):hover .k-group-delete,
.k-textbox:hover .k-i-close,
.k-textbox:hover .k-delete,
.k-textbox:hover .k-group-delete,
.k-button:active .k-i-close,
.k-button:active .k-delete,
.k-button:active .k-group-delete {
  background-position: -48px -16px;
}
.k-si-close {
  background-position: -160px -80px;
}
.k-link:not(.k-state-disabled):hover > .k-si-close,
.k-state-hover > .k-si-close,
.k-state-hover > * > .k-si-close,
.k-button:not(.k-state-disabled):hover .k-si-close,
.k-textbox:hover .k-si-close,
.k-button:active .k-si-close {
  background-position: -176px -80px;
}
.k-multiselect .k-delete {
  background-position: -160px -80px;
}
.k-multiselect .k-state-hover .k-delete {
  background-position: -176px -80px;
}
.k-i-tick,
.k-insert,
.k-update {
  background-position: -32px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-i-tick,
.k-link:not(.k-state-disabled):hover > .k-insert,
.k-link:not(.k-state-disabled):hover > .k-update,
.k-state-hover > .k-i-tick,
.k-state-hover > .k-insert,
.k-state-hover > .k-update,
.k-state-hover > * > .k-i-tick,
.k-state-hover > * > .k-insert,
.k-state-hover > * > .k-update,
.k-button:not(.k-state-disabled):hover .k-i-tick,
.k-button:not(.k-state-disabled):hover .k-insert,
.k-button:not(.k-state-disabled):hover .k-update,
.k-textbox:hover .k-i-tick,
.k-textbox:hover .k-insert,
.k-textbox:hover .k-update,
.k-button:active .k-i-tick,
.k-button:active .k-insert,
.k-button:active .k-update {
  background-position: -48px -32px;
}
.k-check:checked,
.k-mobile-list .k-edit-field [type="checkbox"],
.k-mobile-list .k-edit-field [type="radio"] {
  background-position: -32px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-check:checked,
.k-link:not(.k-state-disabled):hover
  > .k-mobile-list
  .k-edit-field
  [type="checkbox"],
.k-link:not(.k-state-disabled):hover
  > .k-mobile-list
  .k-edit-field
  [type="radio"],
.k-state-hover > .k-check:checked,
.k-state-hover > .k-mobile-list .k-edit-field [type="checkbox"],
.k-state-hover > .k-mobile-list .k-edit-field [type="radio"],
.k-state-hover > * > .k-check:checked,
.k-state-hover > * > .k-mobile-list .k-edit-field [type="checkbox"],
.k-state-hover > * > .k-mobile-list .k-edit-field [type="radio"],
.k-button:not(.k-state-disabled):hover .k-check:checked,
.k-button:not(.k-state-disabled):hover
  .k-mobile-list
  .k-edit-field
  [type="checkbox"],
.k-button:not(.k-state-disabled):hover
  .k-mobile-list
  .k-edit-field
  [type="radio"],
.k-textbox:hover .k-check:checked,
.k-textbox:hover .k-mobile-list .k-edit-field [type="checkbox"],
.k-textbox:hover .k-mobile-list .k-edit-field [type="radio"],
.k-button:active .k-check:checked,
.k-button:active .k-mobile-list .k-edit-field [type="checkbox"],
.k-button:active .k-mobile-list .k-edit-field [type="radio"] {
  background-position: -48px -32px;
}
.k-i-cancel,
.k-cancel,
.k-denied {
  background-position: -32px -48px;
}
.k-link:not(.k-state-disabled):hover > .k-i-cancel,
.k-link:not(.k-state-disabled):hover > .k-cancel,
.k-link:not(.k-state-disabled):hover > .k-denied,
.k-state-hover > .k-i-cancel,
.k-state-hover > .k-cancel,
.k-state-hover > .k-denied,
.k-state-hover > * > .k-i-cancel,
.k-state-hover > * > .k-cancel,
.k-state-hover > * > .k-denied,
.k-button:not(.k-state-disabled):hover .k-i-cancel,
.k-button:not(.k-state-disabled):hover .k-cancel,
.k-button:not(.k-state-disabled):hover .k-denied,
.k-textbox:hover .k-i-cancel,
.k-textbox:hover .k-cancel,
.k-textbox:hover .k-denied,
.k-button:active .k-i-cancel,
.k-button:active .k-cancel,
.k-button:active .k-denied {
  background-position: -48px -48px;
}
.k-i-plus,
.k-add {
  background-position: -32px -64px;
}
.k-link:not(.k-state-disabled):hover > .k-i-plus,
.k-link:not(.k-state-disabled):hover > .k-add,
.k-state-hover > .k-i-plus,
.k-state-hover > .k-add,
.k-state-hover > * > .k-i-plus,
.k-state-hover > * > .k-add,
.k-button:not(.k-state-disabled):hover .k-i-plus,
.k-button:not(.k-state-disabled):hover .k-add,
.k-textbox:hover .k-i-plus,
.k-textbox:hover .k-add,
.k-button:active .k-i-plus,
.k-button:active .k-add {
  background-position: -48px -64px;
}
.k-i-funnel,
.k-filter {
  background-position: -32px -80px;
}
.k-link:not(.k-state-disabled):hover > .k-i-funnel,
.k-link:not(.k-state-disabled):hover > .k-filter,
.k-state-hover > .k-i-funnel,
.k-state-hover > .k-filter,
.k-state-hover > * > .k-i-funnel,
.k-state-hover > * > .k-filter,
.k-button:not(.k-state-disabled):hover .k-i-funnel,
.k-button:not(.k-state-disabled):hover .k-filter,
.k-textbox:hover .k-i-funnel,
.k-textbox:hover .k-filter,
.k-button:active .k-i-funnel,
.k-button:active .k-filter {
  background-position: -48px -80px;
}
.k-i-funnel-clear,
.k-clear-filter {
  background-position: -32px -96px;
}
.k-link:not(.k-state-disabled):hover > .k-i-funnel-clear,
.k-link:not(.k-state-disabled):hover > .k-clear-filter,
.k-state-hover > .k-i-funnel-clear,
.k-state-hover > .k-clear-filter,
.k-state-hover > * > .k-i-funnel-clear,
.k-state-hover > * > .k-clear-filter,
.k-button:not(.k-state-disabled):hover .k-i-funnel-clear,
.k-button:not(.k-state-disabled):hover .k-clear-filter,
.k-textbox:hover .k-i-funnel-clear,
.k-textbox:hover .k-clear-filter,
.k-button:active .k-i-funnel-clear,
.k-button:active .k-clear-filter {
  background-position: -48px -96px;
}
.k-i-lock {
  background-position: -64px 0;
}
.k-link:not(.k-state-disabled):hover > .k-i-lock,
.k-state-hover > .k-i-lock,
.k-state-hover > * > .k-i-lock,
.k-button:not(.k-state-disabled):hover .k-i-lock,
.k-textbox:hover .k-i-lock,
.k-button:active .k-i-lock {
  background-position: -80px 0;
}
.k-i-unlock {
  background-position: -64px -16px;
}
.k-link:not(.k-state-disabled):hover > .k-i-unlock,
.k-state-hover > .k-i-unlock,
.k-state-hover > * > .k-i-unlock,
.k-button:not(.k-state-disabled):hover .k-i-unlock,
.k-textbox:hover .k-i-unlock,
.k-button:active .k-i-unlock {
  background-position: -80px -16px;
}
.k-i-refresh {
  background-position: -32px -112px;
}
.k-link:not(.k-state-disabled):hover > .k-i-refresh,
.k-state-hover > .k-i-refresh,
.k-state-hover > * > .k-i-refresh,
.k-button:not(.k-state-disabled):hover .k-i-refresh,
.k-textbox:hover .k-i-refresh,
.k-button:active .k-i-refresh {
  background-position: -48px -112px;
}
.k-i-exception {
  background-position: -160px -304px;
}
.k-link:not(.k-state-disabled):hover > .k-i-exception,
.k-state-hover > .k-i-exception,
.k-state-hover > * > .k-i-exception,
.k-button:not(.k-state-disabled):hover .k-i-exception,
.k-textbox:hover .k-i-exception,
.k-button:active .k-i-exception {
  background-position: -176px -304px;
}
.k-i-restore {
  background-position: -32px -128px;
}
.k-link:not(.k-state-disabled):hover > .k-i-restore,
.k-state-hover > .k-i-restore,
.k-state-hover > * > .k-i-restore,
.k-button:not(.k-state-disabled):hover .k-i-restore,
.k-textbox:hover .k-i-restore,
.k-button:active .k-i-restore {
  background-position: -48px -128px;
}
.k-i-maximize {
  background-position: -32px -144px;
}
.k-link:not(.k-state-disabled):hover > .k-i-maximize,
.k-state-hover > .k-i-maximize,
.k-state-hover > * > .k-i-maximize,
.k-button:not(.k-state-disabled):hover .k-i-maximize,
.k-textbox:hover .k-i-maximize,
.k-button:active .k-i-maximize {
  background-position: -48px -144px;
}
.k-i-minimize {
  background-position: -64px -288px;
}
.k-link:not(.k-state-disabled):hover > .k-i-minimize,
.k-state-hover > .k-i-minimize,
.k-state-hover > * > .k-i-minimize,
.k-button:not(.k-state-disabled):hover .k-i-minimize,
.k-textbox:hover .k-i-minimize,
.k-button:active .k-i-minimize {
  background-position: -80px -288px;
}
.k-i-pin {
  background-position: -160px -256px;
}
.k-link:not(.k-state-disabled):hover > .k-i-pin,
.k-state-hover > .k-i-pin,
.k-state-hover > * > .k-i-pin,
.k-button:not(.k-state-disabled):hover .k-i-pin,
.k-textbox:hover .k-i-pin,
.k-button:active .k-i-pin {
  background-position: -176px -256px;
}
.k-i-unpin {
  background-position: -160px -272px;
}
.k-link:not(.k-state-disabled):hover > .k-i-unpin,
.k-state-hover > .k-i-unpin,
.k-state-hover > * > .k-i-unpin,
.k-button:not(.k-state-disabled):hover .k-i-unpin,
.k-textbox:hover .k-i-unpin,
.k-button:active .k-i-unpin {
  background-position: -176px -272px;
}
.k-resize-se {
  background-position: -32px -160px;
}
.k-link:not(.k-state-disabled):hover > .k-resize-se,
.k-state-hover > .k-resize-se,
.k-state-hover > * > .k-resize-se,
.k-button:not(.k-state-disabled):hover .k-resize-se,
.k-textbox:hover .k-resize-se,
.k-button:active .k-resize-se {
  background-position: -48px -160px;
}
.k-i-calendar {
  background-position: -32px -176px;
}
.k-link:not(.k-state-disabled):hover > .k-i-calendar,
.k-state-hover > .k-i-calendar,
.k-state-hover > * > .k-i-calendar,
.k-button:not(.k-state-disabled):hover .k-i-calendar,
.k-textbox:hover .k-i-calendar,
.k-button:active .k-i-calendar {
  background-position: -48px -176px;
}
.k-i-clock {
  background-position: -32px -192px;
}
.k-link:not(.k-state-disabled):hover > .k-i-clock,
.k-state-hover > .k-i-clock,
.k-state-hover > * > .k-i-clock,
.k-button:not(.k-state-disabled):hover .k-i-clock,
.k-textbox:hover .k-i-clock,
.k-button:active .k-i-clock {
  background-position: -48px -192px;
}
.k-si-plus {
  background-position: -32px -208px;
}
.k-link:not(.k-state-disabled):hover > .k-si-plus,
.k-state-hover > .k-si-plus,
.k-state-hover > * > .k-si-plus,
.k-button:not(.k-state-disabled):hover .k-si-plus,
.k-textbox:hover .k-si-plus,
.k-button:active .k-si-plus {
  background-position: -48px -208px;
}
.k-si-minus {
  background-position: -32px -224px;
}
.k-link:not(.k-state-disabled):hover > .k-si-minus,
.k-state-hover > .k-si-minus,
.k-state-hover > * > .k-si-minus,
.k-button:not(.k-state-disabled):hover .k-si-minus,
.k-textbox:hover .k-si-minus,
.k-button:active .k-si-minus {
  background-position: -48px -224px;
}
.k-i-search {
  background-position: -32px -240px;
}
.k-link:not(.k-state-disabled):hover > .k-i-search,
.k-state-hover > .k-i-search,
.k-state-hover > * > .k-i-search,
.k-button:not(.k-state-disabled):hover .k-i-search,
.k-textbox:hover .k-i-search,
.k-button:active .k-i-search {
  background-position: -48px -240px;
}
.k-i-custom {
  background-position: -115px -113px;
}
.k-link:not(.k-state-disabled):hover > .k-i-custom,
.k-state-hover > .k-i-custom,
.k-state-hover > * > .k-i-custom,
.k-button:not(.k-state-disabled):hover .k-i-custom,
.k-textbox:hover .k-i-custom,
.k-button:active .k-i-custom {
  background-position: -141px -113px;
}
.k-editor .k-i-custom {
  background-position: -111px -109px;
}
.k-viewHtml {
  background-position: -288px -120px;
}
.k-i-insert-n,
.k-insert-top {
  background-position: -160px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-i-insert-n,
.k-link:not(.k-state-disabled):hover > .k-insert-top,
.k-state-hover > .k-i-insert-n,
.k-state-hover > .k-insert-top,
.k-state-hover > * > .k-i-insert-n,
.k-state-hover > * > .k-insert-top,
.k-button:not(.k-state-disabled):hover .k-i-insert-n,
.k-button:not(.k-state-disabled):hover .k-insert-top,
.k-textbox:hover .k-i-insert-n,
.k-textbox:hover .k-insert-top,
.k-button:active .k-i-insert-n,
.k-button:active .k-insert-top {
  background-position: -176px -32px;
}
.k-i-insert-m,
.k-insert-middle {
  background-position: -160px -48px;
}
.k-link:not(.k-state-disabled):hover > .k-i-insert-m,
.k-link:not(.k-state-disabled):hover > .k-insert-middle,
.k-state-hover > .k-i-insert-m,
.k-state-hover > .k-insert-middle,
.k-state-hover > * > .k-i-insert-m,
.k-state-hover > * > .k-insert-middle,
.k-button:not(.k-state-disabled):hover .k-i-insert-m,
.k-button:not(.k-state-disabled):hover .k-insert-middle,
.k-textbox:hover .k-i-insert-m,
.k-textbox:hover .k-insert-middle,
.k-button:active .k-i-insert-m,
.k-button:active .k-insert-middle {
  background-position: -176px -48px;
}
.k-i-insert-s,
.k-insert-bottom {
  background-position: -160px -64px;
}
.k-link:not(.k-state-disabled):hover > .k-i-insert-s,
.k-link:not(.k-state-disabled):hover > .k-insert-bottom,
.k-state-hover > .k-i-insert-s,
.k-state-hover > .k-insert-bottom,
.k-state-hover > * > .k-i-insert-s,
.k-state-hover > * > .k-insert-bottom,
.k-button:not(.k-state-disabled):hover .k-i-insert-s,
.k-button:not(.k-state-disabled):hover .k-insert-bottom,
.k-textbox:hover .k-i-insert-s,
.k-textbox:hover .k-insert-bottom,
.k-button:active .k-i-insert-s,
.k-button:active .k-insert-bottom {
  background-position: -176px -64px;
}
.k-drop-hint {
  background-position: 0 -326px;
}
.k-i-note,
.k-warning {
  background-position: -160px -240px;
}
.k-link:not(.k-state-disabled):hover > .k-i-note,
.k-link:not(.k-state-disabled):hover > .k-warning,
.k-state-hover > .k-i-note,
.k-state-hover > .k-warning,
.k-state-hover > * > .k-i-note,
.k-state-hover > * > .k-warning,
.k-button:not(.k-state-disabled):hover .k-i-note,
.k-button:not(.k-state-disabled):hover .k-warning,
.k-textbox:hover .k-i-note,
.k-textbox:hover .k-warning,
.k-button:active .k-i-note,
.k-button:active .k-warning {
  background-position: -176px -240px;
}
.k-i-sort-asc {
  background-position: -112px -240px;
}
.k-link:not(.k-state-disabled):hover > .k-i-sort-asc,
.k-state-hover > .k-i-sort-asc,
.k-state-hover > * > .k-i-sort-asc,
.k-button:not(.k-state-disabled):hover .k-i-sort-asc,
.k-textbox:hover .k-i-sort-asc,
.k-button:active .k-i-sort-asc {
  background-position: -128px -240px;
}
.k-i-sort-desc {
  background-position: -112px -256px;
}
.k-link:not(.k-state-disabled):hover > .k-i-sort-desc,
.k-state-hover > .k-i-sort-desc,
.k-state-hover > * > .k-i-sort-desc,
.k-button:not(.k-state-disabled):hover .k-i-sort-desc,
.k-textbox:hover .k-i-sort-desc,
.k-button:active .k-i-sort-desc {
  background-position: -128px -256px;
}
.k-i-group {
  background-position: -112px -272px;
}
.k-link:not(.k-state-disabled):hover > .k-i-group,
.k-state-hover > .k-i-group,
.k-state-hover > * > .k-i-group,
.k-button:not(.k-state-disabled):hover .k-i-group,
.k-textbox:hover .k-i-group,
.k-button:active .k-i-group {
  background-position: -128px -272px;
}
.k-i-ungroup {
  background-position: -112px -288px;
}
.k-link:not(.k-state-disabled):hover > .k-i-ungroup,
.k-state-hover > .k-i-ungroup,
.k-state-hover > * > .k-i-ungroup,
.k-button:not(.k-state-disabled):hover .k-i-ungroup,
.k-textbox:hover .k-i-ungroup,
.k-button:active .k-i-ungroup {
  background-position: -128px -288px;
}
.k-i-columns {
  background-position: -112px -304px;
}
.k-link:not(.k-state-disabled):hover > .k-i-columns,
.k-state-hover > .k-i-columns,
.k-state-hover > * > .k-i-columns,
.k-button:not(.k-state-disabled):hover .k-i-columns,
.k-textbox:hover .k-i-columns,
.k-button:active .k-i-columns {
  background-position: -128px -304px;
}
.k-i-hbars {
  background-position: -64px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-i-hbars,
.k-state-hover > .k-i-hbars,
.k-state-hover > * > .k-i-hbars,
.k-button:not(.k-state-disabled):hover .k-i-hbars,
.k-textbox:hover .k-i-hbars,
.k-button:active .k-i-hbars {
  background-position: -80px -32px;
}
.k-i-vbars {
  background-position: -64px -48px;
}
.k-link:not(.k-state-disabled):hover > .k-i-vbars,
.k-state-hover > .k-i-vbars,
.k-state-hover > * > .k-i-vbars,
.k-button:not(.k-state-disabled):hover .k-i-vbars,
.k-textbox:hover .k-i-vbars,
.k-button:active .k-i-vbars {
  background-position: -80px -48px;
}
.k-i-sum {
  background-position: -64px -64px;
}
.k-link:not(.k-state-disabled):hover > .k-i-sum,
.k-state-hover > .k-i-sum,
.k-state-hover > * > .k-i-sum,
.k-button:not(.k-state-disabled):hover .k-i-sum,
.k-textbox:hover .k-i-sum,
.k-button:active .k-i-sum {
  background-position: -80px -64px;
}
.k-i-pdf {
  background-position: -64px -80px;
}
.k-link:not(.k-state-disabled):hover > .k-i-pdf,
.k-state-hover > .k-i-pdf,
.k-state-hover > * > .k-i-pdf,
.k-button:not(.k-state-disabled):hover .k-i-pdf,
.k-textbox:hover .k-i-pdf,
.k-button:active .k-i-pdf {
  background-position: -80px -80px;
}
.k-i-excel {
  background-position: -64px -96px;
}
.k-link:not(.k-state-disabled):hover > .k-i-excel,
.k-state-hover > .k-i-excel,
.k-state-hover > * > .k-i-excel,
.k-button:not(.k-state-disabled):hover .k-i-excel,
.k-textbox:hover .k-i-excel,
.k-button:active .k-i-excel {
  background-position: -80px -96px;
}
.k-i-rotatecw {
  background-position: -64px -112px;
}
.k-link:not(.k-state-disabled):hover > .k-i-rotatecw,
.k-state-hover > .k-i-rotatecw,
.k-state-hover > * > .k-i-rotatecw,
.k-button:not(.k-state-disabled):hover .k-i-rotatecw,
.k-textbox:hover .k-i-rotatecw,
.k-button:active .k-i-rotatecw {
  background-position: -80px -112px;
}
.k-i-rotateccw {
  background-position: -64px -128px;
}
.k-link:not(.k-state-disabled):hover > .k-i-rotateccw,
.k-state-hover > .k-i-rotateccw,
.k-state-hover > * > .k-i-rotateccw,
.k-button:not(.k-state-disabled):hover .k-i-rotateccw,
.k-textbox:hover .k-i-rotateccw,
.k-button:active .k-i-rotateccw {
  background-position: -80px -128px;
}
.k-i-undo {
  background-position: -64px -160px;
}
.k-link:not(.k-state-disabled):hover > .k-i-undo,
.k-state-hover > .k-i-undo,
.k-state-hover > * > .k-i-undo,
.k-button:not(.k-state-disabled):hover .k-i-undo,
.k-textbox:hover .k-i-undo,
.k-button:active .k-i-undo {
  background-position: -80px -160px;
}
.k-i-redo {
  background-position: -64px -144px;
}
.k-link:not(.k-state-disabled):hover > .k-i-redo,
.k-state-hover > .k-i-redo,
.k-state-hover > * > .k-i-redo,
.k-button:not(.k-state-disabled):hover .k-i-redo,
.k-textbox:hover .k-i-redo,
.k-button:active .k-i-redo {
  background-position: -80px -144px;
}
.k-i-shape {
  background-position: -64px -176px;
}
.k-link:not(.k-state-disabled):hover > .k-i-shape,
.k-state-hover > .k-i-shape,
.k-state-hover > * > .k-i-shape,
.k-button:not(.k-state-disabled):hover .k-i-shape,
.k-textbox:hover .k-i-shape,
.k-button:active .k-i-shape {
  background-position: -80px -176px;
}
.k-i-connector {
  background-position: -64px -192px;
}
.k-link:not(.k-state-disabled):hover > .k-i-connector,
.k-state-hover > .k-i-connector,
.k-state-hover > * > .k-i-connector,
.k-button:not(.k-state-disabled):hover .k-i-connector,
.k-textbox:hover .k-i-connector,
.k-button:active .k-i-connector {
  background-position: -80px -192px;
}
.k-i-kpi {
  background-position: -64px -208px;
}
.k-link:not(.k-state-disabled):hover > .k-i-kpi,
.k-state-hover > .k-i-kpi,
.k-state-hover > * > .k-i-kpi,
.k-button:not(.k-state-disabled):hover .k-i-kpi,
.k-textbox:hover .k-i-kpi,
.k-button:active .k-i-kpi {
  background-position: -80px -208px;
}
.k-i-dimension {
  background-position: -64px -224px;
}
.k-link:not(.k-state-disabled):hover > .k-i-dimension,
.k-state-hover > .k-i-dimension,
.k-state-hover > * > .k-i-dimension,
.k-button:not(.k-state-disabled):hover .k-i-dimension,
.k-textbox:hover .k-i-dimension,
.k-button:active .k-i-dimension {
  background-position: -80px -224px;
}
.k-file {
  background-position: 0 0;
}
.k-link:not(.k-state-disabled):hover > .k-file,
.k-state-hover > .k-file,
.k-state-hover > * > .k-file,
.k-button:not(.k-state-disabled):hover .k-file,
.k-textbox:hover .k-file,
.k-button:active .k-file {
  background-position: -16px 0;
}
.k-i-folder-add,
.k-addfolder {
  background-position: -32px -272px;
}
.k-link:not(.k-state-disabled):hover > .k-i-folder-add,
.k-link:not(.k-state-disabled):hover > .k-addfolder,
.k-state-hover > .k-i-folder-add,
.k-state-hover > .k-addfolder,
.k-state-hover > * > .k-i-folder-add,
.k-state-hover > * > .k-addfolder,
.k-button:not(.k-state-disabled):hover .k-i-folder-add,
.k-button:not(.k-state-disabled):hover .k-addfolder,
.k-textbox:hover .k-i-folder-add,
.k-textbox:hover .k-addfolder,
.k-button:active .k-i-folder-add,
.k-button:active .k-addfolder {
  background-position: -48px -272px;
}
.k-i-folder-up,
.k-goup {
  background-position: -32px -288px;
}
.k-link:not(.k-state-disabled):hover > .k-i-folder-up,
.k-link:not(.k-state-disabled):hover > .k-goup,
.k-state-hover > .k-i-folder-up,
.k-state-hover > .k-goup,
.k-state-hover > * > .k-i-folder-up,
.k-state-hover > * > .k-goup,
.k-button:not(.k-state-disabled):hover .k-i-folder-up,
.k-button:not(.k-state-disabled):hover .k-goup,
.k-textbox:hover .k-i-folder-up,
.k-textbox:hover .k-goup,
.k-button:active .k-i-folder-up,
.k-button:active .k-goup {
  background-position: -48px -288px;
}
.k-i-more {
  background-position: -64px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-i-more,
.k-state-hover > .k-i-more,
.k-state-hover > * > .k-i-more,
.k-button:not(.k-state-disabled):hover .k-i-more,
.k-textbox:hover .k-i-more,
.k-button:active .k-i-more {
  background-position: -80px -32px;
}
.k-file > .k-icon {
  background-position: -115px -91px;
}
.k-image {
  border: 0;
}
.k-breadcrumbs:hover .k-i-arrow-n {
  background-position: 0 0;
}
.k-breadcrumbs:hover .k-i-arrow-e {
  background-position: 0 -16px;
}
html .k-success-colored {
  color: #507f50;
  border-color: #d0dfd0;
  background-color: #f0fff0;
}
html .k-info-colored {
  color: #50607f;
  border-color: #d0d9df;
  background-color: #f0f9ff;
}
html .k-error-colored {
  color: #7f5050;
  border-color: #dfd0d0;
  background-color: #fff0f0;
}
.k-inline-block {
  padding: 0 2px;
}
.k-loading,
.k-loading-image {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
.k-loading-mask,
.k-loading-image,
.k-loading-text {
  position: absolute;
}
.k-loading-text {
  text-align: center;
  text-indent: -4000px;
}
.k-loading-image,
.k-loading-color {
  width: 100%;
  height: 100%;
}
.k-loading-image {
  z-index: 2;
  top: 0;
  left: 0;
}
.k-loading-color {
  opacity: 0.3;

  filter: alpha(opacity=30);
}
.k-content-frame {
  width: 100%;
  height: 100%;

  border: 0;
}
.k-pane > .k-splitter-overlay {
  position: absolute;
  opacity: 0;

  filter: alpha(opacity=0);
}
.k-drag-clue {
  font-size: 0.9em;

  position: absolute;
  z-index: 10003;
  padding: 0.2em 0.4em;
  cursor: default;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
}
.k-drag-status {
  margin-top: -3px;
  margin-right: 4px;
  vertical-align: middle;
}
.k-reorder-cue {
  position: absolute;
  overflow: visible;
  width: 1px;
}
.k-reorder-cue .k-icon {
  position: absolute;
  left: -4px;
  width: 8px;
  height: 4px;
}
.k-reorder-cue .k-i-arrow-s {
  top: -4px;
  background-position: -4px -166px;
}
.k-reorder-cue .k-i-arrow-n {
  bottom: -4px;
  background-position: -4px -134px;
}
.k-scrollbar {
  position: absolute;
  overflow: scroll;
}
.k-scrollbar-vertical {
  top: 0;
  right: 0;
  overflow-x: hidden;
  width: 17px;
  height: 100%;
}
.k-touch-scrollbar {
  position: absolute;
  z-index: 200000;

  display: none;
  width: 8px;
  height: 8px;
  border: 1px solid #8a8a8a;
  background-color: #858585;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  body .k-touch-scrollbar {
    width: 12px;
    height: 12px;
    border-radius: 7px;
  }
}
.k-virtual-scrollable-wrap {
  overflow-x: auto;
}
.k-animation-container,
.k-widget,
.k-widget *,
.k-animation-container *,
.k-widget *:before,
.k-animation-container *:after,
.k-block .k-header,
.k-list-container {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.k-button,
.k-textbox,
.k-autocomplete,
div.k-window-content,
.k-tabstrip > .k-content > .km-scroll-container,
.k-block,
.k-edit-cell .k-widget,
.k-grid-edit-row .k-widget,
.k-grid-edit-row .text-box,
.km-actionsheet > li,
.km-shim {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.input-group .form-control {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-control.k-widget {
  padding: 0;
}
a.k-button:hover {
  text-decoration: none;
}
.k-widget,
.k-widget * {
  -webkit-background-clip: border-box;
  -moz-background-clip: border-box;
  background-clip: border-box;
}
.k-checkbox,
.k-radio {
  display: none;
}
.k-checkbox-label {
  line-height: 0.875em;

  position: relative;
  padding-left: 1.5em;
  cursor: pointer;
  vertical-align: middle;
}
.k-checkbox-label:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;

  content: "";
  border-width: 1px;
  border-style: solid;
}
.k-checkbox-label:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;

  content: "";
  border-width: 1px;
  border-style: solid;
}
.k-checkbox:checked + .k-checkbox-label:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;

  content: "\2713";
  text-align: center;
  border-width: 1px;
  border-style: solid;
}
.k-checkbox:disabled + .k-checkbox-label {
  cursor: auto;
}
.k-radio-label {
  line-height: 0.875em;

  position: relative;
  padding-left: 1.5em;
  cursor: pointer;
  vertical-align: middle;
}
.k-radio-label:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;

  content: "";
  border-style: solid;
}
.k-radio:checked + .k-radio-label:after {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;

  content: "";
}
.k-radio:disabled + .k-radio-label {
  cursor: auto;
}
input.k-checkbox + label {
  -webkit-user-select: none;
  user-select: none;
}
.k-edit-form {
  margin: 0;
  padding: 0;
}
.k-window > div.k-popup-edit-form {
  padding: 1em 0;
}
.k-grid-edit-row .k-edit-form td {
  border-bottom-width: 0;
}
.k-edit-form-container {
  position: relative;
  width: 400px;
}
.k-edit-label,
.k-edit-form-container .editor-label {
  float: left;
  clear: both;
  width: 30%;
  margin-left: 2%;
  padding: 0.4em 0 1em;
  text-align: right;
}
.k-edit-field,
.k-edit-form-container .editor-field {
  float: right;
  clear: right;
  width: 60%;
  margin-right: 2%;
  padding: 0 0 0.6em;
}
.k-edit-field > input[type="checkbox"],
.k-edit-field > input[type="radio"] {
  margin-top: 0.4em;
}
.k-edit-form-container .k-button {
  margin: 0 0.16em;
}
.k-edit-field > input[type="checkbox"]:first-child,
.k-edit-field > input[type="radio"]:first-child,
.k-edit-field > label:first-child > input[type="checkbox"],
.k-edit-field > .k-button:first-child {
  margin-left: 0;
}
.k-edit-form-container .k-edit-buttons {
  position: relative;
  bottom: -1em;

  clear: both;
  padding: 0.6em;
  text-align: right;
  border-width: 1px 0 0;
  border-style: solid;
}
div.k-window {
  position: absolute;
  z-index: 10001;

  display: inline-block;
  padding-top: 2em;
  border-width: 1px;
  border-style: solid;
}
.k-block > .k-header,
.k-window-titlebar {
  font-size: 1.2em;

  position: absolute;
  width: 100%;
  height: 1.1em;
  min-height: 16px;
  margin-top: -2em;
  padding: 0.4em 0;
  white-space: nowrap;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.k-block > .k-header {
  position: relative;
  margin: -2px 0 10px -2px;
  padding: 0.3em 2px;
}
.k-window-title {
  position: absolute;
  right: 0.44em;
  left: 0.44em;
  overflow: hidden;
  cursor: default;
  text-overflow: ellipsis;
}
.k-window-title .k-image {
  margin: 0 5px 0 0;
  vertical-align: middle;
}
div.k-window-titleless {
  padding-top: 0;
}
div.k-window-content {
  position: relative;
  overflow: auto;
  height: 100%;
  padding: 0.58em;
  outline: 0;
}
div.k-window-iframecontent {
  overflow: visible;

  padding: 0;
}
.k-window-content > .km-scroll-container {
  height: 100%;
}
.k-ie7 .k-window {
  padding-bottom: 1.16em;
}
.k-ie7 .k-window-titleless {
  padding-bottom: 0;
}
.k-window-titlebar .k-window-actions {
  position: absolute;
  top: 0;
  right: 0.3em;
  padding-top: 0.3em;
  white-space: nowrap;
}
.k-window-titlebar .k-window-action {
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 2px;
  vertical-align: middle;
  text-decoration: none;
  opacity: 0.7;
}
.k-window-titlebar .k-state-hover {
  padding: 1px;
  opacity: 1;
  border-width: 1px;
  border-style: solid;
}
.k-window-action .k-icon {
  margin: 0;
  vertical-align: top;
}
.k-window > .k-resize-handle {
  font-size: 0;
  line-height: 6px;

  position: absolute;
  z-index: 1;
  zoom: 1;
  opacity: 0;
  background-color: #fff;
  filter: alpha(opacity=0);
}
.k-resize-n {
  top: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  cursor: n-resize;
}
.k-resize-e {
  top: 0;
  right: -3px;
  width: 6px;
  height: 100%;
  cursor: e-resize;
}
.k-resize-s {
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  cursor: s-resize;
}
.k-resize-w {
  top: 0;
  left: -3px;
  width: 6px;
  height: 100%;
  cursor: w-resize;
}
.k-resize-se {
  right: -3px;
  bottom: -3px;
  width: 16px;
  height: 16px;
  cursor: se-resize;
}
.k-resize-sw {
  bottom: -3px;
  left: -3px;
  width: 6px;
  height: 6px;
  cursor: sw-resize;
}
.k-resize-ne {
  top: -3px;
  right: -3px;
  width: 6px;
  height: 6px;
  cursor: ne-resize;
}
.k-resize-nw {
  top: -3px;
  left: -3px;
  width: 6px;
  height: 6px;
  cursor: nw-resize;
}
.k-overlay {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000;
  filter: alpha(opacity=50);
}
.k-window .k-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #fff;
  filter: alpha(opacity=0);
}
.k-tabstrip {
  zoom: 1;

  margin: 0;
  padding: 0;
}
.k-tabstrip .k-tabstrip-items {
  padding: 0.3em 0.3em 0;
}
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item {
  position: relative;
  display: inline-block;
  margin: 0 -1px 0 0;
  padding: 0;

  list-style-type: none;
  vertical-align: top;
  border-width: 1px 1px 0;
  border-style: solid;
}
.k-tabstrip-items .k-tab-on-top,
.k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-items .k-state-active {
  margin-bottom: -1px;
  padding-bottom: 1px;
}
.k-tabstrip-items .k-tab-on-top {
  z-index: 1;
}
.k-tabstrip-items .k-link,
.k-panelbar .k-tabstrip-items .k-link {
  display: inline-block;
  padding: 0.5em 0.92em;
  border-bottom-width: 0;
}
.k-tabstrip-items .k-icon,
.k-panelbar .k-tabstrip-items .k-icon {
  margin: -1px 4px 0 -3px;
  vertical-align: top;
}
.k-tabstrip-items .k-item .k-image,
.k-tabstrip-items .k-item .k-sprite,
.k-panelbar .k-tabstrip-items .k-item .k-image,
.k-panelbar .k-tabstrip-items .k-item .k-sprite {
  margin: -3px 3px 0 -6px;
  vertical-align: middle;
}
.k-ie7 .k-tabstrip-items .k-item .k-image,
.k-ie7 .k-tabstrip-items .k-item .k-sprite {
  margin-top: -1px;
  vertical-align: top;
}
.k-tabstrip-items .k-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 0;
  -webkit-transition: width 200ms linear;
  -webkit-transition: "width 200ms linear";
  -moz-transition: width 200ms linear;
  -moz-transition: "width 200ms linear";
  -ms-transition: "width 200ms linear";
  -o-transition: width 200ms linear;
  -o-transition: "width 200ms linear";
  transition: width 200ms linear;
  transition: "width 200ms linear";
  -webkit-animation: k-tab-loader 1s ease-in-out infinite;
  -moz-animation: k-tab-loader 1s ease-in-out infinite;
  animation: k-tab-loader 1s ease-in-out infinite;
  border-color: inherit;
  border-top: 1px solid transparent;
  background: transparent;
}
.k-tabstrip-items .k-progress {
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}
.k-tabstrip-items .k-loading.k-complete {
  width: 100%;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}
.k-tabstrip > .k-content,
.k-panelbar .k-tabstrip > .k-content {
  position: static;
  zoom: 1;
  margin: 0 0.29em 0.3em;
  padding: 0.3em 0.92em;
  border-width: 1px;
  border-style: solid;
}
.k-tabstrip > .k-content {
  display: none;
}
.k-tabstrip > .k-content.km-scroll-wrapper {
  padding: 0;
}
.k-tabstrip > .k-content > .km-scroll-container {
  padding: 0.3em 0.92em;
}
@-webkit-keyframes k-tab-loader {
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes k-tab-loader {
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0;
  }
}
@keyframes k-tab-loader {
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0;
  }
}
.k-panelbar {
  zoom: 1;
}
.k-panelbar > .k-item,
.k-panel > .k-item {
  display: block;
  zoom: 1;
  margin: 0;

  list-style-type: none;
  border-width: 0;
  border-radius: 0;
}
.k-panelbar .k-image,
.k-panelbar .k-sprite {
  float: left;
  margin-top: 4px;
  margin-right: 5px;
  vertical-align: middle;
}
.k-panelbar > .k-item > .k-link,
.k-panel > .k-item > .k-link {
  line-height: 2.34em;
  position: relative;

  display: block;
  zoom: 1;
  padding: 0 1em;
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.k-panelbar-expand,
.k-panelbar-collapse {
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -8px;
}
.k-panelbar .k-panel,
.k-panelbar .k-content {
  position: relative;
  zoom: 1;
  margin: 0;
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.k-panel > .k-item > .k-link {
  font-size: 0.95em;
  line-height: 2.2;

  border-bottom: 0;
}
.k-panel .k-panel > .k-item > .k-link {
  padding-left: 2em;
}
.k-panelbar .k-i-seek-e .k-link {
  border-bottom: 0;
}
.k-panel .k-panel {
  border-bottom: 0;
}
.k-menu {
  cursor: default;
}
.k-menu,
.k-menu .k-menu-group {
  zoom: 1;
  margin: 0;
  padding: 0;

  list-style: none;
}
.k-menu:after {
  display: block;
  float: inherit;
  overflow: hidden;
  width: 99%;
  height: 0;

  content: "";
}
.k-menu .k-item {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  user-select: none;
}
.k-menu .k-item div {
  -webkit-user-select: default;
  -moz-user-select: default;
  user-select: default;
}
.k-menu .k-item .k-item,
ul.k-menu-vertical > .k-item {
  display: block;
  float: none;
  border-width: 0;
}
.k-ie7 .k-menu .k-item .k-item {
  zoom: normal;
}
.k-menu .k-item > .k-link > .k-icon,
.k-menu .k-image,
.k-menu .k-sprite {
  margin: -2px 4px 0 -4px;
  vertical-align: middle;
}
.k-menu .k-item > .k-link > .k-icon {
  margin: -2px 0 0;
}
.k-ie7 .k-menu .k-item > .k-link > .k-i-arrow-s,
.k-ie7 .k-menu .k-image,
.k-ie7 .k-menu .k-sprite {
  margin-top: 0;
}
.k-menu .k-item > .k-link {
  line-height: 1.34em;

  display: block;
  padding: 0.5em 1.1em 0.4em;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}
.k-menu .k-menu-group {
  display: none;
  overflow: visible;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
}
.k-menu .k-menu-group > .k-item {
  display: block;
  border-width: 0;
}
.k-menu .k-item,
.k-widget.k-menu-horizontal > .k-item {
  position: relative;
  float: left;
  zoom: 1;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  vertical-align: top;
  border-width: 0 1px 0 0;
  border-style: solid;
}
.k-context-menu.k-menu-vertical > .k-item > .k-link,
.k-menu .k-menu-group .k-item > .k-link {
  padding: 0.28em 1.8em 0.38em 0.9em;
}
.k-context-menu.k-menu-horizontal > .k-separator {
  display: none;
}
.k-context-menu.k-menu-horizontal > .k-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.k-context-menu.k-menu-horizontal > .k-last {
  border: 0;
}
.k-ie7 .k-menu .k-menu-group .k-link {
  width: 100%;
}
.k-menu .k-item > .k-link > .k-i-arrow-s {
  margin-right: -8px;
}
.k-menu .k-item > .k-link > .k-i-arrow-e {
  position: absolute;
  top: 50%;
  right: 2px;
  right: 0.2rem;
  margin-top: -8px;
}
.k-menu .k-animation-container {
  border: 0;
}
.k-menu .k-animation-container,
.k-menu .k-menu-group {
  position: absolute;
  left: 0;
}
.k-menu .k-animation-container .k-animation-container,
.k-menu .k-menu-group .k-menu-group,
.k-menu-vertical .k-animation-container,
.k-menu-vertical .k-menu-group {
  top: 0;
  left: 0;
}
.k-menu .k-animation-container .k-menu-group {
  top: auto;
  left: auto;
  margin-left: -1px;
}
.k-menu .k-animation-container,
.k-popup .k-animation-container {
  margin-top: -1px;
  padding-left: 1px;
}
.k-ie .k-menu .k-animation-container,
.k-ie .k-popup .k-animation-container {
  margin-top: -2px;
}
.k-popup .k-animation-container .k-popup {
  margin-left: -1px;
}
ul.k-menu .k-separator {
  font-size: 0;
  line-height: 0;
  width: 1px;
  height: 100%;
  padding: 0.25em 0;
  border-width: 0 1px 0 0;
}
ul.k-menu-vertical .k-separator,
.k-menu .k-menu-group .k-separator {
  width: 100%;
  height: 1px;
  padding: 0;
  border-width: 1px 0 0;
}
.k-context-menu {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  user-select: none;

  border: 0;
}
.k-grid,
.k-listview {
  position: relative;
  zoom: 1;
}
.k-grid table {
  width: 100%;
  margin: 0;
  empty-cells: show;
  border-spacing: 0;
  border-collapse: separate;
  border-width: 0;
  outline: 0;
}
.k-header.k-drag-clue {
  overflow: hidden;
}
.k-grid-header th.k-header,
.k-filter-row th {
  font-weight: normal;

  overflow: hidden;
  padding: 0.5em 0.6em 0.4em 0.6em;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-width: 0 0 1px 1px;
  border-style: solid;
}
.k-grid-header th.k-header {
  vertical-align: bottom;
}
.k-filtercell,
.k-filtercell > span,
.k-filtercell .k-widget {
  display: block;
  width: auto;
}
.k-filtercell > span {
  line-height: 2em;
  position: relative;
  min-height: 2em;
  padding-right: 4.8em;
}
.k-filtercell > .k-operator-hidden {
  padding-right: 2.3em;
}
.k-filtercell > span > .k-button,
.k-filter-row .k-dropdown-operator {
  position: absolute;
  top: 0;
  right: 0;
}
.k-filter-row .k-dropdown-operator {
  right: 2.5em;

  width: 2.1em;
}
.k-filtercell > span > label {
  vertical-align: middle;
}
.k-filter-row label > input[type="radio"] {
  position: relative;
  bottom: 2px;

  vertical-align: middle;
}
.k-ie10 .k-grid-header a:active {
  background-color: transparent;
}
.k-grid-header th.k-header > .k-link {
  line-height: 18px;

  display: block;
  overflow: hidden;
  min-height: 18px;
  margin: -0.5em -0.6em -0.4em -0.6em;
  padding: 0.5em 0.6em 0.4em 0.6em;
  text-overflow: ellipsis;
}
.k-grid-header th.k-with-icon .k-link {
  margin-right: 1.3em;
}
.k-grid-header th.k-header .k-icon {
  position: static;
}
.k-grid-header th > .k-link > .k-icon {
  vertical-align: text-top;
}
.k-grid .k-state-hover {
  cursor: pointer;
}
.k-grid td {
  line-height: 1.6em;
  overflow: hidden;
  padding: 0.4em 0.6em;
  vertical-align: middle;
  text-overflow: ellipsis;
  border-width: 0 0 0 1px;
  border-style: solid;
}
.k-grid .k-grouping-row td,
.k-grid .k-hierarchy-cell {
  overflow: visible;
}
.k-grid-edit-row td {
  text-overflow: clip;
}
.k-grid-edit-row .k-textbox,
.k-grid-edit-row .text-box {
  margin-top: 0;
  margin-bottom: 0;
}
.k-grid-header-wrap,
.k-grid-footer-wrap {
  position: relative;
  overflow: hidden;
  zoom: 1;
  width: 100%;
  border-width: 0 1px 0 0;
  border-style: solid;
}
div.k-grid-header,
div.k-grid-footer {
  zoom: 1;

  padding-right: 17px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.k-grid-header-wrap > table,
.k-grid-header-locked > table {
  margin-bottom: -1px;
}
.k-grid-content {
  position: relative;
  overflow: auto;
  overflow-x: auto;
  overflow-y: scroll;
  zoom: 1;
  width: 100%;
}
.k-mobile .k-grid tbody {
  -webkit-backface-visibility: hidden;
}
.k-mobile .k-grid-backface tbody {
  -webkit-backface-visibility: visible;
}
.k-grid-content-expander {
  position: absolute;
  visibility: hidden;
  height: 1px;
}
@media print {
  .k-grid {
    height: auto !important;
  }
  .k-grid-header {
    padding: 0 !important;
  }
  .k-grid-header-wrap,
  .k-grid-content {
    overflow: visible;
    height: auto !important;
  }
}
.k-virtual-scrollable-wrap {
  position: relative;
  overflow-y: hidden;

  height: 100%;
}
.k-grid-header table,
.k-grid-content table,
.k-grid-footer table,
.k-grid-content-locked > table {
  table-layout: fixed;
}
.k-ie7 .k-grid-content table {
  width: auto;
}
.k-grid-lockedcolumns {
  white-space: nowrap;
}
.k-grid-content-locked,
.k-grid-content,
.k-pager-wrap {
  white-space: normal;
}
.k-grid-header-locked,
.k-grid-content-locked,
.k-grid-footer-locked {
  position: relative;

  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  border-width: 0 1px 0 0;
  border-style: solid;
}
.k-grid-header-locked + .k-grid-header-wrap,
.k-grid-content-locked + .k-grid-content,
.k-grid-footer-locked + .k-grid-footer-wrap {
  display: inline-block;
  vertical-align: top;
}
.k-grid-toolbar {
  border-width: 1px 0 0;
  border-style: solid;
}
.k-grid-header th.k-header:first-child,
.k-grid tbody td:first-child,
.k-grid tfoot td:first-child,
.k-filter-row > th:first-child {
  border-left-width: 0;
}
.k-grid-header th.k-header.k-first {
  border-left-width: 1px;
}
.k-grid-toolbar:first-child,
.k-grouping-header + .k-grid-toolbar {
  border-width: 0 0 1px;
}
.k-footer-template td {
  border-width: 1px 0 0 1px;
  border-style: solid;
}
.k-group-footer td {
  border-width: 1px 0;
  border-style: solid;
}
.k-group-footer .k-group-cell + td {
  border-left-width: 1px;
}
.k-grid-footer {
  border-width: 1px 0 0;
  border-style: solid;
}
.k-grid-footer td {
  border-top-width: 0;
}
.k-grid-footer > td {
  border-top-width: 1px;
}
.k-pager-wrap {
  line-height: 2em;

  clear: both;
  overflow: hidden;
  padding: 0.333em 0 0.333em 0.25em;
  border-width: 1px;
  border-style: solid;
}
.k-grid-pager {
  border-width: 1px 0 0;
}
.k-grid .k-pager-numbers,
.k-pager-numbers .k-link,
.k-pager-numbers .k-state-selected {
  display: inline-block;
  margin-right: 1px;
  vertical-align: top;
}
.k-pager-numbers {
  margin: 0 2px;
}
.k-pager-numbers .k-state-selected {
  vertical-align: top;
}
.k-pager-numbers li,
.k-pager-input {
  float: left;
}
.k-grid .k-pager-numbers {
  float: left;
  cursor: default;
}
.k-pager-info {
  float: right;
  padding: 0 1.333em;
}
.k-pager-numbers .k-link {
  text-decoration: none;
}
.k-pager-wrap > .k-link,
.k-pager-numbers .k-link,
.k-pager-numbers .k-state-selected {
  min-width: 2em;
}
.k-pager-wrap > .k-link {
  line-height: 2em;

  float: left;
  height: 2em;
  margin: 0 0.08333em;
  cursor: pointer;
  text-align: center;
  border-radius: 1.0833em;
}
.k-pager-wrap > a.k-state-disabled:hover {
  cursor: default;

  background: 0;
}
.k-pager-numbers .k-link {
  line-height: 2em;

  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 1.0833em;
}
.k-pager-wrap > .k-link {
  border-width: 1px;
  border-style: solid;
}
.k-pager-wrap .k-pager-refresh {
  float: right;
  margin-right: 0.5em;
  border-width: 0;
  border-radius: 0;
}
.k-pager-numbers .k-state-selected {
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 1.0833em;
}
.k-pager-wrap .k-textbox {
  width: 3.333em;
}
.k-ie7 .k-pager-wrap .k-textbox {
  display: inline;

  height: 1.3333em;
  margin-top: 0.16666em;
}
.k-pager-wrap .k-dropdown {
  width: 4.5em;
}
.k-pager-refresh {
  float: right;
}
.k-pager-input,
.k-pager-sizes {
  padding: 0 1.4166em;
}
.k-pager-sizes {
  display: inline-block;
  padding-top: 1px;
}
.k-pager-sizes .k-widget.k-dropdown {
  margin-top: -2px;
}
.k-ie7 .k-pager-sizes {
  float: left;
}
.k-pager-wrap .k-textbox,
.k-pager-wrap .k-widget {
  margin: 0 0.4em 0;
}
.k-header > .k-grid-filter,
.k-header > .k-header-column-menu {
  position: relative;
  z-index: 1;

  float: right;
  margin: -0.5em -0.6em -0.4em;
  padding: 0.5em 0.2em 0.4em;
}
.k-grid .k-animation-container {
  position: absolute;
}
.k-filter-menu {
  padding: 0.5em;
}
form.k-filter-menu .k-widget,
form.k-filter-menu .k-textbox {
  display: block;
}
.k-filter-help-text,
.k-filter-menu .k-widget,
.k-filter-menu .k-textbox {
  margin: 0.19em 0 0;
}
.k-filter-menu span.k-filter-and {
  width: 6em;
  margin: 0.5em 0 0.5em;
}
.k-filter-menu .k-button {
  width: 48%;
  margin: 0.5em 4% 0 0;
}
.k-filter-menu .k-button + .k-button {
  margin-right: 0;
}
.k-grouping-row .k-icon {
  margin: -3px 4px 0 2px;
}
.k-grouping-row p {
  display: inline-block;
  margin-left: -0.6em;
  padding: 0 0.6em;
  vertical-align: middle;
}
.k-grouping-row + tr td {
  border-top-width: 1px;
}
.k-grouping-row .k-group-cell,
.k-grouping-row + tr .k-group-cell {
  text-overflow: none;

  border-top-width: 0;
}
.k-grid .k-hierarchy-cell + td {
  border-left-width: 0;
}
.k-grid .k-group-col,
.k-grid .k-hierarchy-col {
  width: 27px;
}
.k-grouping-header {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.k-grouping-header {
  line-height: 2;
}
.k-grouping-dropclue {
  position: absolute;
  width: 6px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: -165px -148px;
}
.k-grouping-header .k-group-indicator {
  line-height: 1.5em;

  display: inline-block;
  margin: 0 3px;
  padding: 0.15em 0.15em 0.15em 0.4em;
  border-width: 1px;
  border-style: solid;
}
.k-grouping-header .k-link {
  line-height: normal;

  display: inline-block;
  padding: 0;
  text-decoration: none;
  border-width: 0;
}
.k-grouping-header .k-button {
  line-height: 1;
  padding: 0;

  border: 0;
  background: transparent;
}
.k-grouping-header .k-link .k-icon {
  margin: 0 0 0 -3px;
}
.k-grouping-header .k-button .k-icon {
  margin: 0 0 0 3px;
}
.k-grouping-header a,
.k-grouping-header .k-button {
  display: inline-block;
  vertical-align: middle;
}
.k-dirty-cell:before {
  display: inline-block;
  float: left;
  width: 0;

  content: "\a0";
}
.k-ie7 .k-dirty-cell {
  position: relative;
}
.k-ie7 .k-dirty {
  top: 5px;
}
.k-dirty {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  margin: -0.45em 0 0 -0.6em;
  padding: 0;
  vertical-align: top;
  border-width: 3px;
  border-style: solid;
  border-color: #f00 transparent transparent #f00;
}
.k-grouping-header,
.k-grid-toolbar {
  margin: 0;
  padding: 0.22em 0.2em 0.28em;
  cursor: default;
}
.k-grid .k-edit-container {
  padding: 0;
}
.k-grid .field-validation-error {
  display: block;
}
.k-grid .input-validation-error {
  border-style: ridge;
  border-color: #f00;
  background-color: #ffc0cb;
}
.k-grid-toolbar .k-button {
  vertical-align: middle;
}
.k-grid-actions {
  display: inline-block;
}
.k-ie7 .k-grid-actions {
  vertical-align: bottom;
}
.k-grid .k-button {
  margin: 0 0.16em;
}
.k-grid tbody .k-button,
.k-ie8 .k-grid tbody button.k-button {
  min-width: 64px;
}
.k-grid tbody button.k-button {
  min-width: 78px;
}
.k-ie7 .k-grid tbody a.k-button {
  min-width: 62px;
}
html body .k-grid tbody .k-button-icon {
  width: auto;
  min-width: 0;
}
.k-detail-row {
  position: relative;
}
.k-grid .k-detail-cell {
  overflow: visible;
}
.k-grid .k-edit-cell {
  padding: 0 0.3em;
  white-space: nowrap;
}
.k-grid .k-edit-cell .k-tooltip {
  white-space: normal;
}
.k-edit-cell > .k-textbox,
.k-edit-cell > .k-widget,
.k-grid-edit-row > td > .k-textbox,
.k-grid-edit-row > td > .k-widget,
.k-grid-edit-row > td > .text-box {
  width: 100%;
}
.k-ie7 .k-edit-cell > .text-box,
.k-ie7 .k-edit-cell > .k-textbox,
.k-ie7 .k-edit-cell > .k-widget,
.k-ie7 .k-grid-edit-row > td > .k-textbox,
.k-ie7 .k-grid-edit-row > td > .k-widget,
.k-ie7 .k-grid-edit-row > td > .text-box {
  display: block;
  width: 90%;
}
html .k-edit-cell .k-tooltip,
html .k-grid-edit-row .k-tooltip {
  width: auto;
  max-width: 300px;
}
.k-edit-cell input[type="checkbox"] {
  margin-left: 0.6em;
}
.k-grid tbody td > .k-grid-delete {
  margin-top: -0.2em;
  margin-bottom: -0.2em;
}
.k-grid-resize-indicator {
  position: absolute;
  width: 2px;
  background-color: #aaa;
}
.k-grid-header .k-resize-handle,
.k-grid > .k-resize-handle {
  position: absolute;
  z-index: 2;
  height: 25px;
  cursor: col-resize;
}
.k-marquee {
  position: absolute;
  z-index: 100000;
}
.k-marquee-color,
.k-marquee-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.k-marquee-color {
  opacity: 0.6;

  filter: alpha(opacity=60);
}
.k-ie9 .k-column-menu {
  width: 160px;
}
.k-ie8 .k-grid-filter,
.k-ie8 .k-header-column-menu {
  font-size: 100%;
}
.k-column-menu {
  min-width: 160px;
}
.k-column-menu .k-sprite {
  margin-right: 10px;
}
.k-column-menu > .k-menu {
  border-width: 0;
}
.k-columns-item .k-group {
  overflow: auto;

  max-height: 200px;
}
.k-treelist .k-status {
  line-height: 1.6em;

  padding: 0.4em 0.6em;
}
.k-treelist .k-status .k-loading {
  margin-right: 5px;

  vertical-align: baseline;
}
.k-treelist tr.k-hidden {
  display: none;
}
.k-gantt {
  position: relative;

  white-space: nowrap;
}
.k-gantt-layout {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.k-gantt .k-splitbar {
  position: relative;
  width: 5px;
  cursor: e-resize;
  border-width: 0 1px;
  background-repeat: repeat-y;
}
.k-gantt .k-gantt-layout th {
  vertical-align: bottom;
}
.k-gantt td {
  overflow: hidden;
  vertical-align: top;
  white-space: nowrap;
}
.k-gantt .k-grid .k-edit-cell {
  vertical-align: middle;
}
.k-gantt-treelist > .k-treelist,
.k-gantt-timeline > .k-timeline {
  height: 100%;

  border-width: 0;
}
.k-gantt-toolbar {
  line-height: 2.4em;
  padding: 0.5em;
  border-width: 0 0 1px;
  border-style: solid;
}
.k-gantt-layout + .k-gantt-toolbar {
  border-width: 1px 0 0;
}
.k-gantt-actions,
.k-gantt-toolbar > ul {
  float: left;
  margin-right: 0.6em;
}
.k-gantt-toolbar > .k-gantt-views {
  float: right;
  margin-right: 0;
}
.k-gantt-toolbar > ul > li {
  display: inline-block;
  border-width: 1px 1px 1px 0;
  border-style: solid;
}
.k-gantt-toolbar > ul > li:first-child {
  border-left-width: 1px;
}
.k-gantt-toolbar .k-link {
  display: inline-block;
  padding: 0 1.1em;
}
.k-gantt-toolbar li:first-child,
.k-gantt-toolbar li:first-child > .k-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.k-gantt-toolbar li:last-child,
.k-gantt-toolbar li:last-child > .k-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.k-gantt-toolbar li.k-button {
  line-height: inherit;
  padding-top: 0;
  padding-bottom: 0;
}
.k-gantt-treelist .k-grid-header tr {
  height: 5em;
}
.k-gantt .k-treelist .k-grid-header {
  padding: 0 !important;
}
.k-gantt .k-treelist .k-grid-content {
  overflow-x: scroll;
  overflow-y: hidden;
}
.k-treelist-group > tr > span {
  font-weight: bold;
}
.k-treelist-group .k-widget {
  font-weight: normal;
}
.k-gantt-timeline .k-grid-header tr {
  height: 2.5em;
}
.k-gantt-rows tr,
.k-gantt-tasks tr,
.k-gantt .k-grid-content tr {
  height: 2.3em;
}
.k-gantt .k-gantt-tasks td:after {
  content: "\a0";
}
.k-gantt-timeline {
  background: transparent;
}
.k-gantt-rows,
.k-gantt-columns,
.k-gantt-dependencies {
  position: absolute;
  top: 0;
  left: 0;
}
.k-gantt-tables {
  position: relative;
}
.k-gantt .k-gantt-timeline th {
  text-align: center;
}
.k-gantt .k-gantt-timeline tr:first-child th {
  border-bottom-width: 1px;
}
.k-task-summary {
  display: inline-block;

  height: 10px;
  margin-top: 3px;
  vertical-align: top;
}
.k-task-summary-complete {
  position: relative;
  z-index: 2;

  height: 10px;
}
.k-task-summary-progress {
  overflow: hidden;

  height: 15px;
}
.k-task-summary:before,
.k-task-summary-complete:before,
.k-task-summary:after,
.k-task-summary-complete:after {
  position: absolute;
  top: 0;
  width: 0;
  height: 0;

  content: "";
  border-width: 8px;
  border-style: solid;
  border-color: transparent;
}
.k-task-summary:before,
.k-task-summary-complete:before {
  left: 0;
  border-left-color: inherit;
}
.k-task-summary:after,
.k-task-summary-complete:after {
  right: 0;
  border-right-color: inherit;
}
.k-line-h,
.k-line-v {
  position: absolute;
}
.k-line-h {
  height: 2px;
}
.k-line-v {
  width: 2px;
}
.k-arrow-e,
.k-arrow-w {
  position: absolute;
  top: -4px;
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
}
.k-arrow-e {
  right: -6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.k-arrow-w {
  left: -6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.k-task-milestone {
  width: 13px;
  height: 13px;
  margin-top: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-width: 1px;
  border-style: solid;
}
.k-ie8 .k-task-milestone,
.k-ie7 .k-task-milestone {
  margin-left: 1px;
}
.k-gantt .k-gantt-treelist .k-button,
.k-gantt .k-gantt-tasks .k-button-icon {
  padding-top: 0;
  padding-bottom: 0;
}
.k-gantt .k-gantt-tasks .k-button-icon {
  margin-top: 4px;
}
.k-gantt .k-gantt-treelist .k-button {
  margin-top: -4px;
  margin-bottom: -2px;
}
.k-gantt .k-gantt-tasks .k-button-icon {
  padding-right: 2px;
  padding-left: 2px;
}
.k-gantt .k-gantt-treelist .k-button .k-icon,
.k-gantt .k-gantt-tasks .k-button .k-icon {
  vertical-align: text-top;
}
.k-rel .k-button-icon {
  position: absolute;
  left: 200px;
}
.k-rel {
  position: relative;
  top: -0.3em;
  height: 0;
}
.k-task-wrap {
  position: absolute;
  z-index: 2;
  margin: -1px -23px 0;
  padding: 0 23px 5px;
}
.k-task-wrap:hover,
.k-line.k-state-selected {
  z-index: 3;
}
.k-milestone-wrap {
  margin: 0 -13px 0 -27px;
}
.k-task-content {
  position: relative;
  z-index: 2;
}
.k-task-complete {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20%;
}
.k-task-dot {
  line-height: 16px;

  position: absolute;
  top: 0;
  display: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.k-task-dot.k-state-hover {
  background-color: transparent;
}
.k-task-single + .k-task-dot,
.k-task-single + .k-task-dot + .k-task-dot {
  top: 0.2em;
}
.k-task-wrap:hover .k-task-dot,
.k-task-wrap-active .k-task-dot {
  display: block;
}
.k-task-dot:before {
  display: inline-block;
  width: 0;
  height: 16px;

  content: "\a0";
}
.k-task-dot:after {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 4px;

  content: "";
  vertical-align: middle;
  border-radius: 4px;
}
.k-task-dot:hover:after,
.k-task-dot.k-state-hover:after,
.k-task-wrap-active .k-task-dot:after {
  margin-left: 3px;
  border-width: 1px;
  border-style: solid;
}
.k-task-start {
  left: 0;
}
.k-task-end {
  right: 0;
}
.k-task-single {
  overflow: hidden;
  min-height: 1.3em;
  cursor: default;
  text-align: left;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
}
.k-task-template {
  line-height: normal;

  padding: 0.2em 1.4em 0.2em 0.6em;
}
.k-task-actions,
.k-task-content > .k-link {
  position: absolute;
  top: 0;
  right: 4px;
  white-space: nowrap;
}
.k-task-actions {
  z-index: 1;
}
.k-task-actions:first-child {
  position: static;
  float: left;
  margin: 4px 2px 0 4px;
}
.k-webkit .k-task-actions:first-child {
  margin-top: 3px;
}
.k-task-actions:first-child > .k-link {
  display: inline-block;
}
.k-task-delete {
  display: none;
}
.k-task-wrap:hover .k-task-delete,
.k-task-wrap-active .k-task-delete {
  display: inline-block;
}
.k-task-single .k-resize-handle {
  position: absolute;
  z-index: 2;
  visibility: hidden;
  height: auto;
}
.k-task-single:hover .k-resize-handle,
.k-task-wrap-active .k-resize-handle {
  visibility: visible;
}
.k-task-single .k-resize-handle:after {
  position: absolute;

  content: "";
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.k-task-content > .k-resize-e {
  top: 0;
  right: 0;
  bottom: 0;
  width: 0.4em;
}
.k-task-content > .k-resize-w {
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.4em;
}
.k-task-content > .k-resize-e:after,
.k-task-content > .k-resize-w:after {
  top: 50%;
  left: 1px;
  width: 1px;
  height: 1.4em;
  margin-top: -0.7em;
}
.k-task-content > .k-resize-e:after {
  right: 1px;
  left: auto;
}
.k-task-draghandle {
  position: absolute;
  bottom: 0;
  display: none;
  width: 0;
  height: 0;
  margin-left: 16px;
  cursor: e-resize;
  border-width: 5px;
  border-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.k-task-wrap:hover .k-task-draghandle,
.k-task-wrap-active .k-task-draghandle {
  display: block;
}
.k-dependency-hint {
  z-index: 4;
}
.k-resources-wrap {
  position: absolute;
  z-index: 2;
  overflow: hidden;
  zoom: 1;
  margin-top: -2px;
  margin-left: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.k-resources-wrap .k-resource {
  margin: 0 5px;
}
.k-gantt-edit-form > .k-edit-form-container {
  width: 430px;
}
.k-gantt-edit-form > .k-resources-form-container {
  width: 506px;
}
.k-resources-form-container > .k-grid {
  margin: 0 0.9em;
}
.k-gantt-edit-form > .k-edit-form-container .k-textbox,
.k-gantt-edit-form > .k-edit-form-container .k-numerictextbox {
  width: 15em;
}
.k-gantt-edit-form .k-edit-buttons .k-gantt-delete {
  float: left;
}
.k-pivot-toolbar {
  padding: 0.2em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.k-pivot .k-pivot-toolbar {
  padding: 0.6em;
}
.k-pivot-toolbar .k-button {
  font-size: 0.9em;
  line-height: 1.2em;
  position: relative;

  margin-right: 0.4em;
  padding: 0.3em 5em 0.3em 0.3em;
  text-align: left;
}
.k-field-actions {
  position: absolute;
  top: 3px;
  right: 2px;
}
.k-pivot .k-grid td {
  white-space: nowrap;
}
.k-pivot-layout {
  table-layout: auto;
  border-spacing: 0;
}
.k-pivot-layout > tbody > tr > td {
  padding: 0;

  vertical-align: top;
}
.k-pivot td {
  vertical-align: top;
}
.k-pivot-rowheaders > .k-grid,
.k-pivot-table > .k-grid {
  border-width: 0;
}
.k-pivot-rowheaders > .k-grid td:first-child,
.k-pivot-table .k-grid-header .k-header.k-first {
  border-left-width: 1px;
}
.k-pivot-rowheaders > .k-grid td.k-first {
  border-left-width: 0;
}
.k-pivot-rowheaders > .k-grid {
  overflow: hidden;
}
.k-pivot-table {
  border-left-width: 1px;
  border-left-style: solid;
}
.k-pivot-table .k-grid-header-wrap > table {
  height: 100%;
}
.k-pivot .k-grid-header .k-header {
  vertical-align: top;
}
.k-header.k-alt,
td.k-alt {
  font-weight: bold;
}
.k-header.k-alt {
  background-image: none;
}
.k-pivot-layout .k-grid td {
  border-bottom-width: 1px;
}
.k-pivot-layout .k-grid-footer > td {
  border-top-width: 0;
}
.k-pivot-filter-window .k-treeview {
  max-height: 600px;
}
.k-fieldselector .k-edit-buttons {
  bottom: auto;
}
.k-fieldselector .k-edit-label {
  width: 16%;
}
.k-fieldselector .k-edit-field {
  width: 77%;
}
.k-fieldselector .k-edit-field > .k-widget,
.k-fieldselector .k-edit-field > .k-textbox {
  width: 99%;
}
.k-fieldselector .k-edit-buttons > input,
.k-fieldselector .k-edit-buttons > label {
  float: left;
  margin-top: 0.4em;
}
.k-fieldselector p {
  margin: 0 0 0.2em 0.5em;
  text-transform: uppercase;
}
.k-fieldselector p .k-icon {
  margin: 0 5px 0 0;
}
.k-fieldselector .k-columns {
  border-width: 0;
  border-style: solid;
}
.k-fieldselector .k-columns > div {
  float: left;
  overflow: auto;
  width: 45%;
  padding: 0.6em;
  border-width: 0 0 0 1px;
  border-style: solid;
}
.k-fieldselector .k-columns > div:first-child {
  margin-right: -1px;

  border-width: 0;
}
.k-fieldselector .k-columns > div + div {
  float: right;
  border-width: 0;
}
.k-fieldselector div.k-treeview {
  overflow: visible;
  margin-right: -1px;
  padding-left: 4px;

  border-width: 0;
}
.k-fieldselector .k-list-container {
  margin-bottom: 1em;
  margin-left: 0.5em;
  padding: 0.2em 0 0;
  border-width: 1px;
  border-style: solid;
}
.k-fieldselector .k-list {
  padding-bottom: 2em;
}
.k-fieldselector .k-list li.k-item {
  font-size: 0.9em;
  line-height: 1.2em;
  position: relative;
  min-height: 1em;
  margin: 0 0.2em.2em;
  padding: 0.3em 3.3em 0.3em 0.3em;
}
.k-i-kpi-decrease {
  background-position: 0 0;
}
.k-i-kpi-denied {
  background-position: -16px 0;
}
.k-i-kpi-equal {
  background-position: -32px 0;
}
.k-i-kpi-hold {
  background-position: -48px 0;
}
.k-i-kpi-increase {
  background-position: -64px 0;
}
.k-i-kpi-open {
  background-position: -80px 0;
}
.k-calendar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 16.917em;
}
.k-calendar td,
.k-calendar .k-link {
  text-decoration: none;
}
.k-calendar .k-action-link {
  text-decoration: underline;
}
.k-calendar .k-header,
.k-calendar .k-footer {
  position: relative;
  zoom: 1;
  text-align: center;
}
.k-widget.k-calendar .k-nav-prev,
.k-widget.k-calendar .k-nav-next {
  line-height: 1.8333em;

  position: absolute;
  top: 0.16666em;
  height: 1.8333em;
}
.k-widget.k-calendar .k-nav-prev {
  left: 1%;
}
.k-widget.k-calendar .k-nav-next {
  right: 1%;
}
.k-calendar .k-content {
  float: left;
  width: 100%;
  height: 14.167em;
  margin: 0;
  table-layout: fixed;
  border-spacing: 0;
  text-align: right;
  border-width: 0;
  outline: 0;
}
.k-calendar .k-animation-container .k-content {
  height: 100%;
}
.k-widget.k-calendar .k-nav-fast {
  line-height: 1.8333em;

  display: inline-block;
  width: 75%;
  height: 1.8333em;
  margin: 0.16666em -0.08333em 0.3333em 0;
}
.k-calendar .k-header .k-icon {
  vertical-align: middle;
}
.k-calendar .k-header .k-link.k-nav-prev,
.k-calendar .k-header .k-link.k-nav-next {
  width: 1.8333em;
  height: 1.8333em;
}
.k-calendar th {
  font-weight: normal;
  padding: 0.4em 0.45em 0.4em 0.1em;
  cursor: default;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.k-calendar td {
  padding: 0.08333em;
  cursor: pointer;
}
.k-calendar .k-state-focus {
  padding: 0;
  border-width: 0.08333em;
  border-style: dotted;
}
.k-calendar .k-content .k-link {
  line-height: 1.8333em;

  display: block;
  overflow: hidden;
  min-height: 1.8333em;
  padding: 0 0.45em 0 0.1em;
}
.k-calendar .k-meta-view .k-link {
  padding: 0.25em 0 0.3em;
  text-align: center;
}
.k-calendar .k-footer {
  clear: both;
}
.k-calendar .k-footer .k-nav-today,
.k-calendar .k-footer > .k-state-disabled {
  display: block;
  height: 100%;
  padding: 0.5em 0;
}
.k-calendar .k-nav-today:hover {
  text-decoration: underline;
}
div.k-treeview {
  overflow: auto;
  white-space: nowrap;

  border-width: 0;
  background: 0;
}
.k-treeview .k-item {
  display: block;
  margin: 0;
  padding: 0 0 0 16px;
  border-width: 0;
}
.k-treeview > .k-group,
.k-treeview .k-item > .k-group,
.k-treeview .k-content {
  position: relative;

  margin: 0;
  padding: 0;
  list-style-type: none;
  background: 0;
}
.k-treeview .k-icon,
.k-treeview .k-image,
.k-treeview .k-sprite,
.k-treeview .k-checkbox,
.k-treeview .k-in {
  display: inline-block;
  vertical-align: top;
}
.k-treeview .k-checkbox {
  margin-top: 0.2em;
}
.k-treeview .k-icon,
.k-treeview .k-in {
  vertical-align: middle;
}
.k-treeview .k-request-retry {
  vertical-align: baseline;
}
.k-treeview .k-plus,
.k-treeview .k-minus,
.k-treeview .k-plus-disabled,
.k-treeview .k-minus-disabled {
  margin-top: 0.25em;
  margin-left: -16px;
  cursor: pointer;
}
.k-treeview .k-plus-disabled,
.k-treeview .k-minus-disabled {
  cursor: default;
}
.k-treeview .k-sprite,
.k-treeview .k-image {
  margin-right: 3px;
}
.k-treeview .k-in {
  line-height: 1.3333em;

  margin: 1px 0 1px 0.16666em;
  padding: 1px 0.3333em 1px 0.25em;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
}
.k-treeview span.k-in {
  cursor: default;
}
.k-treeview .k-drop-hint {
  position: absolute;
  z-index: 10000;
  visibility: hidden;
  width: 80px;
  height: 5px;
  margin-top: -3px;
  background-color: transparent;
  background-repeat: no-repeat;
}
span.k-datepicker,
span.k-timepicker,
span.k-datetimepicker,
span.k-colorpicker,
span.k-numerictextbox,
span.k-combobox,
span.k-dropdown,
.k-toolbar .k-split-button {
  background-image: none;
}
.k-autocomplete,
.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker,
.k-colorpicker,
.k-numerictextbox,
.k-dropdown,
.k-selectbox,
.k-textbox,
.k-toolbar .k-split-button {
  position: relative;
  display: inline-block;
  overflow: visible;
  width: 12.4em;
  vertical-align: middle;
  border-width: 0;
}
.k-filter-menu .k-combobox,
.k-filter-menu .k-datepicker,
.k-filter-menu .k-timepicker,
.k-filter-menu .k-datetimepicker,
.k-filter-menu .k-numerictextbox,
.k-filter-menu .k-dropdown,
.k-filter-menu .k-textbox {
  width: 13.2em;
}
.k-autocomplete,
.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker,
.k-colorpicker,
.k-numerictextbox,
.k-dropdown,
.k-selectbox,
.k-toolbar .k-split-button {
  white-space: nowrap;
}
.k-colorpicker,
.k-toolbar .k-split-button {
  width: auto;
}
.k-datetimepicker {
  width: 15em;
}
.k-autocomplete,
.k-picker-wrap,
.k-numeric-wrap {
  position: relative;
  cursor: default;
}
.k-dropdown-wrap {
  position: relative;
}
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap {
  display: block;
}
.k-block,
.k-widget,
.k-grid,
.k-slider,
.k-splitter,
.k-treeview,
.k-panelbar,
.k-content,
.k-header-column-menu {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-block,
.k-slider,
.k-splitbar,
.k-calendar,
.k-treeview,
.k-pager-wrap,
.k-grid-header .k-link,
.k-header-column-menu {
  -webkit-touch-callout: none;
}
.k-popup.k-list-container,
.k-popup.k-calendar-container {
  padding: 2px;
  border-width: 1px;
  border-style: solid;

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-list-container.k-state-border-down,
.k-autocomplete.k-state-border-down,
.k-dropdown-wrap.k-state-border-down,
.k-picker-wrap.k-state-border-down,
.k-numeric-wrap.k-state-border-down {
  padding-bottom: 1px;

  border-bottom-width: 0;
}
.k-list-container .km-scroll-container {
  padding-bottom: 6px;
}
.k-textbox,
.k-autocomplete,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap {
  padding: 0 1.9em 0 0;

  border-width: 1px;
  border-style: solid;
}
.k-numeric-wrap.k-expand-padding {
  padding-right: 0;
}
.k-textbox,
.k-autocomplete {
  padding: 0;
}
.k-textbox.k-space-left {
  padding-left: 1.9em;
}
.k-textbox.k-space-right {
  padding-right: 1.9em;
}
.k-textbox .k-icon {
  position: absolute;
  top: 50%;
  margin: -8px 0 0;
}
.k-space-left .k-icon {
  left: 3px;
}
.k-space-right .k-icon {
  right: 3px;
}
.k-autocomplete,
.k-dropdown-wrap.k-state-focused,
.k-dropdown-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-focused,
.k-numeric-wrap.k-state-hover {
  -webkit-transition: box-shadow 0.15s ease-out;
  -webkit-transition: "box-shadow .15s ease-out";
  -moz-transition: box-shadow 0.15s ease-out;
  -moz-transition: "box-shadow .15s ease-out";
  -ms-transition: "box-shadow .15s ease-out";
  -o-transition: box-shadow 0.15s ease-out;
  -o-transition: "box-shadow .15s ease-out";
  transition: box-shadow 0.15s ease-out;
  transition: "box-shadow .15s ease-out";
}
.k-textbox > input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-combobox .k-input {
  width: 100%;
  vertical-align: top;
}
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
  font-family: inherit;
  border-width: 0;
  outline: 0;
}
.k-dropdown .k-input,
.k-selectbox .k-input {
  background: transparent;
}
.k-ie7 .k-picker-wrap .k-input,
.k-ie7 .k-numeric-wrap .k-input,
.k-ie7 .k-combobox .k-input {
  margin: -1px 0;
}
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-dropdown-wrap .k-select {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
}
.k-combobox .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select {
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: inherit;
}
span.k-datetimepicker .k-select,
span.k-datetimepicker .k-select + .k-select {
  right: 0;
}
.k-textbox > input,
.k-autocomplete .k-input {
  display: block;
}
.k-dropdown .k-select,
.k-selectbox .k-select {
  font: inherit;

  overflow: hidden;
  text-decoration: none;
  color: inherit;
  border: 0;
}
.k-dropdown .k-input,
.k-selectbox .k-input {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-textbox > input,
.k-autocomplete .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
  line-height: 1.65em;

  height: 1.65em;
  margin: 0;
  padding: 0.177em 0;
  text-indent: 0.33em;
  border: 0;
}
.k-webkit .k-combobox .k-dropdown-wrap:before,
.k-webkit .k-picker-wrap:before,
.k-webkit .k-numeric-wrap:before {
  display: inline-block;
  width: 0;
  height: 1.65em;
  padding-bottom: 0.4em;

  content: "\a0";
}
.km.root .k-combobox .k-dropdown-wrap:before,
.km.root .k-picker-wrap:before,
.km.root .k-numeric-wrap:before {
  content: none;
}
.k-combobox .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input {
  display: inline;
}
.k-ie7 .k-autocomplete .k-input,
.k-ie7 .k-picker-wrap .k-input,
.k-ie7 .k-numeric-wrap .k-input,
.k-ie7 .k-dropdown-wrap .k-input,
.k-ie7 .k-selectbox .k-input {
  text-indent: 0;
}
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-dropdown-wrap .k-select {
  line-height: 2em;
  -moz-box-sizing: border-box;
  width: 1.9em;
  height: 100%;
  min-height: 1.65em;
  text-align: center;
  vertical-align: middle;
}
.k-numeric-wrap .k-select {
  padding: 0;
}
body .k-datetimepicker .k-select {
  border-radius: 0;
}
.k-ie7 .k-picker-wrap .k-icon,
.k-ie7 .k-dropdown-wrap .k-icon {
  font-size: 1em;
  line-height: 2em;
  height: 0;
  padding-top: 16px;
}
.k-combobox .k-icon,
.k-dropdown,
.k-selectbox .k-icon {
  cursor: pointer;
}
.k-popup {
  border-width: 1px;
  border-style: solid;
}
.k-popup .k-item {
  cursor: default;
}
.k-popup .k-calendar {
  border: 0;
}
.k-list {
  height: 100%;
}
.k-popup .k-list .k-item,
.k-fieldselector .k-list .k-item {
  line-height: 1.8em;
  min-height: 1.8em;
  padding: 1px 5px 1px 5px;
}
.k-overflow-container .k-item {
  padding: 1px;
}
.k-overflow-container .k-button.k-state-disabled,
.k-overflow-container .k-button.k-state-disabled:hover {
  border: 0;
  background: 0;
}
.k-popup .k-list .k-state-hover,
.k-popup .k-list .k-state-focused,
.k-popup .k-list .k-state-selected,
.k-overflow-container .k-state-hover,
.k-overflow-container .k-state-focused,
.k-overflow-container .k-state-selected,
.k-fieldselector .k-list .k-item {
  padding: 0 4px;
  border-width: 1px;
  border-style: solid;
}
.k-list-filter {
  position: relative;
}
.k-list-filter > .k-textbox {
  width: 100%;
  padding-right: 20px;
}
.k-list-filter > .k-icon {
  position: absolute;
  top: 3px;
  right: 4px;
}
.k-multiselect-wrap {
  position: relative;
  min-height: 2.04em;
  border-width: 0;
  border-style: solid;
  border-color: #c5c5c5;
  border-radius: 4px;
  background-color: #fff;
}
.k-multiselect-wrap .k-input {
  line-height: 1.31em;
  float: left;
  height: 1.31em;
  margin: 1px 0 0;
  padding: 0.18em 0;
  text-indent: 0.33em;
  border: 0;
  background-color: transparent;
}
.k-multiselect-wrap li {
  line-height: 1.5em;
  float: left;

  margin: 1px 0 1px 1px;
  padding: 0.1em 0.15em 0.1em 0.4em;
}
.k-autocomplete .k-loading,
.k-multiselect .k-loading {
  position: absolute;
  right: 3px;
  bottom: 4px;
}
.k-multiselect .k-loading-hidden {
  visibility: hidden;
}
.k-datetimepicker .k-picker-wrap {
  padding-right: 3.8em;
}
.k-datetimepicker .k-select {
  width: 3.8em;
}
.k-datetimepicker .k-picker-wrap .k-icon {
  margin: 0 2px;
}
.k-picker-wrap .k-icon {
  cursor: pointer;
}
.k-button,
.k-textbox,
.k-timepicker,
.k-datepicker,
.k-datetimepicker {
  display: inline-block;
  vertical-align: middle;
}
.k-picker-wrap .k-input {
  margin: 0;
}
.k-time-popup .k-item {
  padding: 1px 3px;
}
.k-input {
  padding: 0.25em 0;
}
.k-input,
.k-textbox > input {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-textbox {
  line-height: 1.6em;
  padding: 2px 0.3em;

  outline: 0;
}
input.k-textbox {
  height: 2.13em;
  text-indent: 0.33em;
}
.k-ie input.k-textbox {
  text-indent: 0.165em;
}
.k-ff input.k-textbox {
  height: 2.17em;
}
.k-ie7 input.k-textbox {
  line-height: 1.72em;
  height: 1.72em;
  text-indent: 0.33em;
}
textarea.k-textbox {
  height: auto;
}
.k-ie7 .k-textbox {
  padding: 1px 0;
  text-indent: 0;
}
span.k-numerictextbox {
  background-color: transparent;
}
.k-numerictextbox .k-input {
  margin: 0;
}
.k-numerictextbox .k-link {
  line-height: 1em;

  display: block;
  height: 1em;
  padding: 0;
  vertical-align: middle;
  border-width: 0;
}
.k-numerictextbox .k-icon {
  height: 11px;
}
.k-numeric-wrap .k-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.k-colorpicker .k-picker-wrap {
  line-height: 2em;
}
.k-colorpicker .k-selected-color {
  line-height: 0;
  display: inline-block;
  width: 2em;
  height: 2em;

  vertical-align: top;
}
.k-colorpicker .k-tool-icon {
  font-size: 0;
  line-height: 0;

  position: relative;
  top: -2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 3px;
  margin-bottom: 3px;
  margin-left: 2px;
  padding: 3px 3px 2px;
  vertical-align: middle;
  background-repeat: no-repeat;
  -ms-high-contrast-adjust: none;
}
.k-colorpicker .k-tool-icon .k-selected-color {
  position: absolute;
  bottom: -3px;
  left: 3px;

  display: block;
  width: 16px;
  height: 3px;
  border-radius: 0 !important;
}
.k-colorpicker .k-icon {
  cursor: pointer;
}
.k-disabled-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #fff;
  filter: alpha(opacity=50);
}
.k-colorpalette {
  line-height: 0;

  position: relative;
  display: inline-block;
  border-width: 0;
}
.k-colorpalette .k-palette {
  position: relative;
  width: 100%;
  height: 100%;

  border-collapse: collapse;
}
.k-colorpalette .k-item {
  overflow: hidden;

  width: 14px;
  height: 14px;
  -ms-high-contrast-adjust: none;
}
.k-colorpalette .k-item.k-state-selected {
  position: relative;
  z-index: 100;
  background: transparent;
  box-shadow:
    0 1px 4px #000,
    inset 0 0 3px #fff;
}
.k-flatcolorpicker {
  position: relative;
  display: inline-block;
  width: 250px;
  padding-bottom: 5px;
}
div.k-flatcolorpicker {
  background-color: transparent;
  background-image: none;
}
.k-flatcolorpicker .k-selected-color {
  text-align: right;

  background-image: url("textures/transtexture.png");
  background-position: 50% 50%;
}
.k-flatcolorpicker .k-selected-color input.k-color-value {
  font-family: Consolas, "Ubuntu Mono", "Lucida Console", "Courier New",
    monospace;
  width: 70%;
  margin: 0;
  padding: 0.75em 0.3em 0.65em 1em;
  border: 0;
}
.k-flatcolorpicker .k-hsv-rectangle {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -ms-touch-action: pinch-zoom double-tap-zoom;
}
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin-top: -5px;
  margin-left: -5px;

  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 6px;
  background: transparent;
  -webkit-box-shadow: 0 1px 2px #444;
  box-shadow: 0 1px 2px #444;
}
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle:hover,
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle:focus {
  border-color: #fff;
  background: transparent;
  -webkit-box-shadow: 0 1px 5px #000;
  box-shadow: 0 1px 5px #000;
}
.k-flatcolorpicker .k-hsv-rectangle.k-dragging,
.k-flatcolorpicker .k-hsv-rectangle.k-dragging * {
  cursor: none;
}
.k-flatcolorpicker .k-slider-horizontal {
  width: 90%;
  height: 20px;
  margin: 0 5%;
}
.k-flatcolorpicker .k-slider-horizontal .k-slider-track {
  -webkit-box-shadow:
    0 1px 0 #fff,
    0 -1px 0 #999;
  box-shadow:
    0 1px 0 #fff,
    0 -1px 0 #999;
}
.k-flatcolorpicker .k-hue-slider,
.k-flatcolorpicker .k-transparency-slider {
  display: block;
}
.k-flatcolorpicker .k-hue-slider .k-slider-selection,
.k-flatcolorpicker .k-transparency-slider .k-slider-selection {
  background: transparent;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle,
.k-flatcolorpicker .k-transparency-slider .k-draghandle {
  width: 8px;
  height: 8px;
  margin-top: 1px;
  border: 3px solid #eee;
  background: transparent;
  -webkit-box-shadow: 0 1px 4px #444;
  box-shadow: 0 1px 4px #444;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle:hover,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:hover,
.k-flatcolorpicker .k-hue-slider .k-draghandle:focus,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:focus {
  padding: 1px;
  border-width: 2px;
  border-color: #fff;
  background: transparent;
  -webkit-box-shadow: 0 1px 5px #000;
  box-shadow: 0 1px 5px #000;
}
.k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: -moz-linear-gradient(
    left,
    #f00 0,
    #ff0 16%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 84%,
    #ff0004 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #f00),
    color-stop(16%, #ff0),
    color-stop(33%, #0f0),
    color-stop(50%, #0ff),
    color-stop(67%, #00f),
    color-stop(84%, #f0f),
    color-stop(100%, #ff0004)
  );
  background: -webkit-linear-gradient(
    left,
    #f00 0,
    #ff0 16%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 84%,
    #ff0004 100%
  );
  background: -o-linear-gradient(
    left,
    #f00 0,
    #ff0 16%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 84%,
    #ff0004 100%
  );
  background: -ms-linear-gradient(
    left,
    #f00 0,
    #ff0 16%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 84%,
    #ff0004 100%
  );
  background: -left-linear-gradient(
    left,
    #f00 0,
    #ff0 16%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 84%,
    #ff0004 100%
  );
}
.k-flatcolorpicker .k-transparency-slider .k-slider-track {
  background-image: url("textures/transparency.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 100% auto;
}
.k-flatcolorpicker .k-controls {
  font-size: 90%;

  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
}
.k-flatcolorpicker .k-controls .k-button {
  width: 6em;
}
.k-flatcolorpicker .k-hsv-gradient {
  height: 180px;
  margin-bottom: 5px;

  background:
    -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, #000 100%),
    -moz-linear-gradient(left, #fff 0, rgba(255, 255, 255, 0) 100%);
  background:
    -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(0, 0, 0, 0)),
      color-stop(100%, #000)
    ),
    -webkit-gradient(linear, left top, right top, color-stop(0%, #fff), color-stop(100%, rgba(255, 255, 255, 0)));
  background:
    -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, #000 100%),
    -webkit-linear-gradient(left, #fff 0, rgba(255, 255, 255, 0) 100%);
  background:
    -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, #000 100%),
    -o-linear-gradient(left, #fff 0, rgba(255, 255, 255, 0) 100%);
  background:
    -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, #000 100%),
    -ms-linear-gradient(left, #fff 0, rgba(255, 255, 255, 0) 100%);
  background:
    -top-linear-gradient(top, rgba(0, 0, 0, 0) 0, #000 100%),
    -left-linear-gradient(left, #fff 0, rgba(255, 255, 255, 0) 100%);
}
.k-ie9 .k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjE2JSIgc3RvcC1jb2xvcj0iI2ZmZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjMzJSIgc3RvcC1jb2xvcj0iIzAwZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzAwZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY3JSIgc3RvcC1jb2xvcj0iIzAwMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijg0JSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjAwMDQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
}
.k-ie9 .k-flatcolorpicker .k-hsv-gradient {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+),
    url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
}
.k-ie7 .k-flatcolorpicker .k-hue-slider .k-slider-track,
.k-ie8 .k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: url("textures/hue.png") repeat 0 50%;
}
.k-ie7 .k-flatcolorpicker .k-transparency-slider .k-slider-track,
.k-ie8 .k-flatcolorpicker .k-transparency-slider .k-slider-track {
  background: url("textures/transparency.png") repeat 0 50%;
}
.k-ie7 .k-flatcolorpicker .k-hsv-gradient,
.k-ie8 .k-flatcolorpicker .k-hsv-gradient {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff',endColorstr='#00ffffff',GradientType=1) progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#ff000000',GradientType=0);
}
table.k-editor {
  font-size: 100%;

  width: 100%;
  height: 250px;
  table-layout: fixed;
  border-spacing: 4px;
  border-collapse: separate;
  vertical-align: top;
  border-width: 1px;
  border-style: solid;
}
.k-editor-inline {
  padding: 0.3em 0.5em;
  word-wrap: break-word;

  border-width: 2px;
}
.k-editortoolbar-dragHandle {
  padding-right: 3px;
  padding-left: 0;

  cursor: move;
  box-shadow: none !important;
}
.k-editor .k-editor-toolbar-wrap {
  padding: 0;

  border: 0;
}
.k-editor-toolbar {
  line-height: 1.3em;

  margin: 0;
  padding: 0.1em 0;
  list-style-type: none;
  cursor: default;
}
.k-editor-toolbar li {
  display: inline-block;
  vertical-align: middle;
}
.k-ie7 .k-editor-toolbar li {
  display: inline;
}
.k-webkit .k-editor-toolbar,
.k-ff .k-editor-toolbar,
.k-ie9 .k-editor-toolbar {
  padding: 0;
}
.k-webkit .k-editor-toolbar li,
.k-safari .k-editor-toolbar li,
.k-ff .k-editor-toolbar li,
.k-ie9 .k-editor-toolbar li,
.k-ie10 .k-editor-toolbar li {
  display: inline-block;
  padding: 0.1em 0;
}
.k-editor-toolbar .k-editor-widget,
.k-editor-toolbar > li {
  margin-right: 6px;
}
.k-group-start.k-group-end .k-editor-widget {
  margin-right: 0;
}
.k-editor-toolbar .k-editor-dropdown {
  position: relative;
}
.k-select-overlay {
  position: absolute;
  z-index: 11000;
  top: 0;
  left: 0;
  width: 100%;
  height: 26px;
  margin: -4px 0 0;
  opacity: 0;

  -webkit-appearance: none;
}
.k-editor-toolbar .k-separator {
  font-size: 1.3em;

  position: relative;
  top: 1px;
  margin: 0 0.3em 0 0.1em;
  padding: 0 0 0 1px;
  border-width: 0 1px 0 0;
  border-style: solid;
}
.k-editor-toolbar .k-break {
  font-size: 0;
  line-height: 0;

  display: block;
  height: 1px;
}
.k-editor-toolbar .k-dropdown,
.k-editor-toolbar .k-combobox,
.k-editor-toolbar .k-selectbox,
.k-editor-toolbar .k-colorpicker {
  vertical-align: middle;
}
.k-button-group {
  white-space: nowrap;
}
.k-button-group .k-tool {
  line-height: 2em;

  display: inline-block;
  width: 2em;
  height: 2em;
  margin: 1px 0;
  vertical-align: middle;
}
.k-button-group .k-tool-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  -ms-high-contrast-adjust: none;
}
.k-i-move {
  background-position: -160px -288px;
}
.k-bold {
  background-position: -240px 0;
}
.k-state-hover .k-bold,
.k-state-selected .k-bold {
  background-position: -264px 0;
}
.k-italic {
  background-position: -240px -24px;
}
.k-state-hover .k-italic,
.k-state-selected .k-italic {
  background-position: -264px -24px;
}
.k-underline {
  background-position: -240px -48px;
}
.k-state-hover .k-underline,
.k-state-selected .k-underline {
  background-position: -264px -48px;
}
.k-strikethrough {
  background-position: -240px -72px;
}
.k-state-hover .k-strikethrough,
.k-state-selected .k-strikethrough {
  background-position: -264px -72px;
}
.k-foreColor {
  background-position: -240px -96px;
}
.k-state-hover .k-foreColor,
.k-state-selected .k-foreColor {
  background-position: -264px -96px;
}
.k-backColor {
  background-position: -240px -120px;
}
.k-state-hover .k-backColor,
.k-state-selected .k-backColor {
  background-position: -264px -120px;
}
.k-colorpicker .k-foreColor {
  background-position: -240px -96px;
}
.k-colorpicker .k-backColor {
  background-position: -240px -120px;
}
.k-justifyLeft {
  background-position: -240px -144px;
}
.k-state-hover .k-justifyLeft,
.k-state-selected .k-justifyLeft {
  background-position: -264px -144px;
}
.k-justifyCenter {
  background-position: -240px -168px;
}
.k-state-hover .k-justifyCenter,
.k-state-selected .k-justifyCenter {
  background-position: -264px -168px;
}
.k-justifyRight {
  background-position: -240px -192px;
}
.k-state-hover .k-justifyRight,
.k-state-selected .k-justifyRight {
  background-position: -264px -192px;
}
.k-justifyFull {
  background-position: -240px -216px;
}
.k-state-hover .k-justifyFull,
.k-state-selected .k-justifyFull {
  background-position: -264px -216px;
}
.k-insertUnorderedList {
  background-position: -240px -264px;
}
.k-state-hover .k-insertUnorderedList,
.k-state-selected .k-insertUnorderedList {
  background-position: -264px -264px;
}
.k-insertOrderedList {
  background-position: -240px -288px;
}
.k-state-hover .k-insertOrderedList,
.k-state-selected .k-insertOrderedList {
  background-position: -264px -288px;
}
.k-indent,
.k-rtl .k-outdent {
  background-position: -288px 0;
}
.k-state-hover .k-indent,
.k-state-hover .k-rtl .k-outdent,
.k-state-selected .k-indent,
.k-state-selected .k-rtl .k-outdent {
  background-position: -312px 0;
}
.k-outdent,
.k-rtl .k-indent {
  background-position: -288px -24px;
}
.k-state-hover .k-outdent,
.k-state-hover .k-rtl .k-indent,
.k-state-selected .k-outdent,
.k-state-selected .k-rtl .k-indent {
  background-position: -312px -24px;
}
.k-createLink {
  background-position: -288px -48px;
}
.k-state-hover .k-createLink,
.k-state-selected .k-createLink {
  background-position: -312px -48px;
}
.k-unlink {
  background-position: -288px -72px;
}
.k-state-hover .k-unlink,
.k-state-selected .k-unlink {
  background-position: -312px -72px;
}
.k-insertImage {
  background-position: -288px -96px;
}
.k-state-hover .k-insertImage,
.k-state-selected .k-insertImage {
  background-position: -312px -96px;
}
.k-insertFile {
  background-position: -288px -216px;
}
.k-state-hover .k-insertFile,
.k-state-selected .k-insertFile {
  background-position: -312px -216px;
}
.k-subscript {
  background-position: -288px -144px;
}
.k-state-hover .k-subscript,
.k-state-selected .k-subscript {
  background-position: -312px -144px;
}
.k-superscript {
  background-position: -288px -168px;
}
.k-state-hover .k-superscript,
.k-state-selected .k-superscript {
  background-position: -312px -168px;
}
.k-cleanFormatting {
  background-position: -288px -192px;
}
.k-state-hover .k-cleanFormatting,
.k-state-selected .k-cleanFormatting {
  background-position: -312px -192px;
}
.k-createTable {
  background-position: -192px 0;
}
.k-state-hover .k-createTable,
.k-state-selected .k-createTable {
  background-position: -216px 0;
}
.k-addColumnLeft {
  background-position: -192px -24px;
}
.k-state-hover .k-addColumnLeft,
.k-state-selected .k-addColumnLeft {
  background-position: -216px -24px;
}
.k-addColumnRight {
  background-position: -192px -48px;
}
.k-state-hover .k-addColumnRight,
.k-state-selected .k-addColumnRight {
  background-position: -216px -48px;
}
.k-addRowAbove {
  background-position: -192px -72px;
}
.k-state-hover .k-addRowAbove,
.k-state-selected .k-addRowAbove {
  background-position: -216px -72px;
}
.k-addRowBelow {
  background-position: -192px -96px;
}
.k-state-hover .k-addRowBelow,
.k-state-selected .k-addRowBelow {
  background-position: -216px -96px;
}
.k-deleteRow {
  background-position: -192px -120px;
}
.k-state-hover .k-deleteRow,
.k-state-selected .k-deleteRow {
  background-position: -216px -120px;
}
.k-deleteColumn {
  background-position: -192px -144px;
}
.k-state-hover .k-deleteColumn,
.k-state-selected .k-deleteColumn {
  background-position: -216px -144px;
}
.k-mergeCells {
  background-position: -192px -168px;
}
.k-state-hover .k-mergeCells,
.k-state-selected .k-mergeCells {
  background-position: -216px -168px;
}
.k-fontName {
  width: 110px;
}
.k-fontSize {
  width: 124px;
}
.k-formatBlock {
  width: 147px;
}
.k-editortoolbar-dragHandle {
  float: left;
  margin: 1px 0 0;
}
.k-editor-toolbar .k-button-group {
  padding: 1px;
}
.k-editor .k-editor-toolbar .k-row-break {
  font-size: 0;
  line-height: 0;

  display: block;
  height: 0;
}
.k-button-group .k-tool {
  margin-right: -1px;
  border-width: 1px;
  border-style: solid;
}
.k-button-group .k-tool.k-state-hover,
.k-button-group .k-tool:focus {
  position: relative;
  z-index: 1;
}
.k-rtl .k-button-group .k-tool {
  border-width: 1px;
  border-style: solid;
}
.k-button-group .k-tool.k-group-end {
  border-right-width: 1px;
}
.k-rtl .k-button-group .k-tool.k-group-end {
  border-left-width: 1px;
}
.k-button-group .k-state-disabled {
  display: none;
}
.k-button-group .k-state-hover,
.k-button-group .k-state-active {
  vertical-align: middle;
}
.k-button-group .k-state-disabled {
  opacity: 0.3;

  filter: alpha(opacity=30);
}
.k-editor .k-editable-area {
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  outline: 0;
}
.k-editor .k-content {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: #fff;
}
.k-editor .k-tool {
  outline: 0;
}
.k-editor iframe.k-content {
  display: inline;
  vertical-align: top;
}
.k-editor .k-raw-content {
  margin: 0;
  padding: 0;

  border: 0;
}
.k-editor .k-raw-content,
.k-editor-dialog .k-editor-textarea {
  font-family: consolas, "courier new", monospace;
  font-size: inherit;
}
.k-editor-dialog {
  width: 400px;
  padding: 1em;
}
.k-editor-dialog .k-edit-label {
  width: 25%;
}
.k-editor-dialog .k-edit-field {
  width: 66%;
}
.k-editor-dialog .k-edit-field .k-textbox {
  width: 96%;
}
.k-editor-dialog .k-edit-field .k-checkbox {
  margin: 0 0.4em 0 0;
}
.k-viewhtml-dialog {
  width: auto;
}
.k-filebrowser-dialog {
  width: auto;
  min-width: 350px;
}
.k-filebrowser-dialog .k-filebrowser {
  margin: 0 1em 0;
}
.k-filebrowser-dialog .k-edit-label {
  width: 18%;
}
.k-filebrowser-dialog .k-edit-field {
  width: 75%;
}
.k-filebrowser-dialog .k-edit-field .k-textbox {
  width: 70%;
}
#k-editor-image-width,
#k-editor-image-height {
  width: 5em;
}
.k-editor-dialog .k-button {
  display: inline-block;
}
.k-editor-dialog .k-editor-textarea {
  overflow: auto;

  width: 600px;
  height: 350px;
  padding: 0.2em 0.2em 0.2em 0.4em;
  border-width: 1px;
  border-style: solid;
}
.k-button-wrapper .k-link:hover {
  text-decoration: underline;
}
.k-ct-popup {
  width: 180.39999999999998px;
  padding: 0.65em 0.5em 0.5em;
}
.k-ct-popup .k-status {
  margin: 0.3em 0;
}
.k-ct-cell {
  display: inline-block;
  overflow: hidden;
  width: 18px;
  height: 18px;
  margin: 1px;
  vertical-align: top;

  border-width: 1px;
  border-style: solid;
  -ms-high-contrast-adjust: none;
}
.k-notification-wrap {
  position: relative;

  padding: 0.6em 0.5em;
  cursor: default;
  white-space: nowrap;
}
.k-notification-button .k-notification-wrap {
  padding-right: 20px;
}
.k-notification-wrap > .k-i-note {
  margin-right: 4px;

  vertical-align: text-bottom;
}
.k-notification-wrap > .k-i-close {
  position: absolute;
  top: 7px;
  right: 4px;
  display: none;
}
.k-notification-button .k-notification-wrap > .k-i-close {
  display: block;
}
.k-progressbar {
  position: relative;

  display: inline-block;
  vertical-align: middle;
}
.k-progressbar {
  border-radius: 4px;
}
.k-progressbar-horizontal {
  width: 27em;
  height: 1.9em;
}
.k-progressbar-vertical {
  width: 1.9em;
  height: 27em;
}
.k-progressbar > .k-state-selected {
  position: absolute;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
}
.k-progressbar-horizontal > .k-state-selected,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected {
  top: -1px;
  right: auto;
  left: -1px;
  height: 100%;
  border-radius: 4px 0 0 4px;
}
.k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected,
.k-rtl .k-progressbar-horizontal > .k-state-selected {
  right: -1px;
  left: auto;
  border-radius: 0 4px 4px 0;
}
.k-progressbar-vertical > .k-state-selected {
  bottom: -1px;
  left: -1px;
  width: 100%;
  border-radius: 0 0 4px 4px;
}
.k-progressbar-vertical.k-progressbar-reverse > .k-state-selected {
  top: -1px;
  bottom: auto;
  border-radius: 4px 4px 0 0;
}
.k-progressbar > .k-state-selected.k-complete,
.k-rtl .k-progressbar > .k-state-selected.k-complete {
  border-radius: 4px;
}
.k-progressbar > .k-reset {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  list-style: none;
  white-space: nowrap;
  border-radius: 4px;
}
.k-progressbar-horizontal .k-item {
  display: inline-block;
  height: 100%;
  margin-left: -1px;
  border-style: solid;
}
.k-progressbar-horizontal .k-item.k-first {
  margin-left: 0;
}
.k-progressbar-horizontal .k-item.k-last {
  border-right-width: 0;
}
.k-progressbar-horizontal .k-item,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-item {
  border-width: 1px 1px 1px 0;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-item,
.k-rtl .k-progressbar-horizontal .k-item {
  border-width: 1px 0 1px 1px;
}
.k-progressbar-horizontal .k-first,
.k-rtl .k-progressbar-horizontal .k-last,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-last {
  border-left-width: 1px;
  border-radius: 4px 0 0 4px;
}
.k-progressbar-horizontal .k-last,
.k-rtl .k-progressbar-horizontal .k-first {
  border-radius: 0 4px 4px 0;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-last,
.k-rtl .k-progressbar-horizontal .k-first {
  border-right-width: 1px;
}
.k-progressbar-vertical .k-item {
  width: 100%;
  margin-top: -1px;
  border-width: 1px 1px 0 1px;
  border-style: solid;
}
.k-progressbar-vertical .k-item.k-first {
  margin-top: 0;
}
.k-progressbar-vertical li.k-item.k-last {
  border-bottom-width: 0;
}
.k-progressbar-vertical .k-first {
  border-radius: 4px 4px 0 0;
}
.k-progressbar-vertical .k-last {
  border-bottom-width: 1px;
  border-radius: 0 0 4px 4px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-item {
  border-width: 0 1px 1px 1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-first {
  border-top-width: 1px;
}
.k-progress-status-wrap {
  line-height: 2em;

  position: absolute;
  top: -1px;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
}
.k-progress-status-wrap,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap {
  right: auto;
  left: -1px;
  text-align: right;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap,
.k-rtl .k-progressbar-horizontal .k-progress-status-wrap {
  right: -1px;
  left: auto;
  text-align: left;
}
.k-progressbar-vertical .k-progress-status-wrap {
  top: auto;
  bottom: -1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status-wrap {
  top: -1px;
  bottom: auto;
}
.k-progress-status {
  display: inline-block;
  min-width: 10px;
  padding: 0 0.5em;
  white-space: nowrap;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
  position: absolute;
  bottom: 0;
  left: 0;
}
.k-progressbar-vertical .k-progress-status {
  -webkit-transform: rotate(-90deg) translateX(-100%);
  -moz-transform: rotate(-90deg) translateX(-100%);
  -ms-transform: rotate(-90deg) translateX(-100%);
  -o-transform: rotate(-90deg) translateX(-100%);
  transform: rotate(-90deg) translateX(-100%);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
  -webkit-transform: rotate(90deg) translateX(-100%);
  -moz-transform: rotate(90deg) translateX(-100%);
  -ms-transform: rotate(90deg) translateX(-100%);
  -o-transform: rotate(90deg) translateX(-100%);
  transform: rotate(90deg) translateX(-100%);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.k-ie7 .k-progressbar-vertical .k-progress-status {
  padding: 0.5em 0;

  writing-mode: tb-rl;
}
.k-ie8 .k-progressbar-vertical .k-progress-status {
  padding: 0.5em 0;

  writing-mode: bt-lr;
}
div.k-slider {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  border-width: 0;
  background-color: transparent;
  -khtml-user-select: none;
}
.k-slider-vertical {
  width: 26px;
  height: 200px;
}
.k-slider-horizontal {
  display: inline-block;
  width: 200px;
  height: 26px;
}
.k-slider-wrap {
  width: 100%;
  height: 100%;
}
.k-slider .k-button,
.k-grid .k-slider .k-button {
  position: absolute;
  top: 0;
  width: 24px;
  min-width: 0;
  height: 24px;
  margin: 0;
  padding: 0;
  outline: 0;
}
.k-slider .k-button .k-icon {
  margin-top: 3px;
  vertical-align: top;
}
.k-state-disabled .k-slider-wrap {
  opacity: 0.6;

  filter: alpha(opacity=60);
}
.k-state-disabled .k-slider-wrap .k-slider-items {
  color: #333;
}
.k-slider .k-button-decrease {
  left: 0;
}
.k-slider-vertical .k-button-decrease,
.k-grid .k-slider-vertical .k-button-decrease {
  top: auto;
  bottom: 0;
}
.k-slider .k-button-increase {
  right: 0;
}
.k-slider .k-icon,
.k-slider-track,
.k-slider .k-tick {
  cursor: pointer;
}
.k-ie7 .k-slider .k-icon {
  margin-top: 2px;
}
.k-slider-track,
.k-slider-selection {
  position: absolute;
  margin: 0;
  padding: 0;
}
.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection {
  top: 50%;
  left: 0;
  height: 8px;
  margin-top: -4px;
  background-repeat: repeat-x;
}
.k-slider-horizontal .k-slider-buttons .k-slider-track {
  left: 34px;
}
.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection {
  bottom: 0;
  left: 50%;
  width: 8px;
  margin-left: -4px;
  background-repeat: repeat-y;
}
.k-slider-vertical .k-slider-buttons .k-slider-track {
  bottom: 34px;
}
.k-draghandle {
  position: absolute;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-indent: -3333px;
  outline: 0;
  background-color: transparent;
  background-repeat: no-repeat;
}
.k-slider-horizontal .k-draghandle {
  top: -4px;
  width: 13px;
  height: 14px;
}
.k-slider-vertical .k-draghandle {
  left: -4px;
  width: 14px;
  height: 13px;
}
.k-slider-buttons .k-slider-items {
  margin-left: 34px;
}
.k-slider-horizontal .k-slider-items {
  height: 100%;
}
.k-slider-vertical .k-slider-items {
  padding-top: 1px;
}
.k-slider-vertical .k-slider-buttons .k-slider-items {
  padding-top: 0;
}
.k-slider-vertical .k-slider-buttons .k-slider-items {
  margin: 0;
  padding-top: 35px;
}
.k-slider .k-tick {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
.k-slider-horizontal .k-tick {
  float: left;
  height: 100%;
  text-align: center;
}
.k-ie7 .k-slider-vertical .k-tick {
  float: left;
  clear: left;
  width: 100%;
}
.k-slider-horizontal .k-tick {
  background-position: center -92px;
}
.k-slider-horizontal .k-slider-topleft .k-tick {
  background-position: center -122px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick {
  background-position: center -152px;
}
.k-slider-horizontal .k-tick-large {
  background-position: center -2px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large {
  background-position: center -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large {
  background-position: center -62px;
}
.k-slider-vertical .k-tick {
  background-position: -92px center;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  background-position: -122px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick {
  background-position: -152px center;
}
.k-slider-vertical .k-tick-large {
  background-position: -2px center;
}
.k-slider-vertical .k-slider-topleft .k-tick-large {
  background-position: -32px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large {
  background-position: -62px center;
}
.k-slider-horizontal .k-first {
  background-position: 0 -92px;
}
.k-slider-horizontal .k-tick-large.k-first {
  background-position: 0 -2px;
}
.k-slider-horizontal .k-slider-topleft .k-first {
  background-position: 0 -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first {
  background-position: 0 -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-first {
  background-position: 0 -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first {
  background-position: 0 -62px;
}
.k-slider-horizontal .k-last {
  background-position: 100% -92px;
}
.k-slider-horizontal .k-tick-large.k-last {
  background-position: 100% -2px;
}
.k-slider-horizontal .k-slider-topleft .k-last {
  background-position: 100% -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last {
  background-position: 100% -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-last {
  background-position: 100% -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last {
  background-position: 100% -62px;
}
.k-slider-vertical .k-first {
  background-position: -92px 100%;
}
.k-slider-vertical .k-tick-large.k-first {
  background-position: -2px 100%;
}
.k-slider-vertical .k-slider-topleft .k-first {
  background-position: -122px 100%;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-first {
  background-position: -32px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-first {
  background-position: -152px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first {
  background-position: -62px 100%;
}
.k-slider-vertical .k-last {
  background-position: -92px 0;
}
.k-slider-vertical .k-tick-large.k-last {
  background-position: -2px 0;
}
.k-slider-vertical .k-slider-topleft .k-last {
  background-position: -122px 0;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-last {
  background-position: -32px 0;
}
.k-slider-vertical .k-slider-bottomright .k-last {
  background-position: -152px 0;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last {
  background-position: -62px 0;
}
.k-slider-vertical .k-tick {
  text-align: right;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  text-align: left;
}
.k-slider .k-label {
  font-size: 0.92em;

  position: absolute;
  white-space: nowrap;
}
.k-slider-horizontal .k-label {
  line-height: 1;

  left: 0;
  width: 100%;
}
.k-slider-horizontal .k-first .k-label {
  left: -50%;
}
.k-slider-horizontal .k-last .k-label {
  right: -50%;
  left: auto;
}
.k-slider-horizontal .k-label {
  bottom: -1.2em;
}
.k-slider-horizontal .k-slider-topleft .k-label {
  top: -1.2em;
}
.k-slider-vertical .k-label {
  left: 120%;
  display: block;
  text-align: left;
}
.k-slider-vertical .k-last .k-label {
  top: -0.5em;
}
.k-slider-vertical .k-first .k-label {
  bottom: -0.5em;
}
.k-slider-vertical .k-slider-topleft .k-label {
  right: 120%;
  left: auto;
}
.k-slider-tooltip {
  top: -4444px;
}
.k-scheduler-toolbar,
.k-scheduler-footer {
  border-style: solid;
}
.k-scheduler-toolbar,
.k-scheduler-footer {
  line-height: 28px;
  padding: 6px;
}
.k-scheduler-toolbar {
  border-width: 0 0 1px;
}
.k-edit-field.k-scheduler-toolbar {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;

  border-width: 0;
}
.k-scheduler-header {
  text-align: center;
}
.k-scheduler-footer {
  border-width: 1px 0 0;
}
.k-scheduler-toolbar > ul {
  float: right;
}
.k-scheduler-toolbar > ul:first-child {
  float: left;
}
.k-scheduler-toolbar > .k-scheduler-tools {
  float: left;
  margin-bottom: 0.5em;
}
.k-scheduler-tools + .k-scheduler-navigation {
  float: left;
  clear: left;
}
.k-scheduler-toolbar > ul > li,
.k-scheduler-footer > ul > li {
  display: inline-block;
  border-width: 1px 1px 1px 0;
  border-style: solid;
}
.k-scheduler .k-scheduler-toolbar .k-nav-current,
.k-scheduler .k-scheduler-toolbar .k-scheduler-tools > li {
  border-width: 0;
}
.k-scheduler-toolbar > ul > li:first-child {
  border-left-width: 1px;
}
.k-scheduler div.k-scheduler-footer ul li {
  margin-right: 0.6em;
  border-width: 1px;
}
.k-scheduler-toolbar .k-link,
.k-scheduler-footer .k-link {
  display: inline-block;
  padding: 0 1.1em;
}
.k-scheduler-toolbar .k-nav-prev .k-link,
.k-scheduler-toolbar .k-nav-next .k-link {
  padding-right: 0.6em;
  padding-left: 0.6em;
}
.k-ie7 .k-scheduler-toolbar .k-nav-prev .k-link,
.k-ie7 .k-scheduler-toolbar .k-nav-next .k-link {
  height: 2.3em;
  margin-top: -1px;
  vertical-align: middle;
}
.k-ie7 .k-scheduler-toolbar .k-nav-prev .k-link .k-icon,
.k-ie7 .k-scheduler-toolbar .k-nav-next .k-link .k-icon {
  margin-top: 0.5em;
}
.k-scheduler-toolbar .k-nav-current .k-link {
  padding: 0;
}
.k-scheduler-toolbar .k-nav-current {
  margin: 0 1.1em;
}
.k-scheduler div.k-scheduler-toolbar > ul > li.k-nav-current,
.k-scheduler .k-nav-current > .k-state-active {
  background: 0;
}
.k-scheduler-phone
  .k-scheduler-toolbar
  + .k-scheduler-toolbar
  .k-scheduler-navigation {
  width: 100%;
  text-align: center;
}
.k-scheduler-phone
  .k-scheduler-toolbar
  + .k-scheduler-toolbar
  .k-scheduler-navigation
  > li {
  border: 0;
  background: 0;
}
.k-scheduler-phone .k-toolbar .k-nav-next {
  float: right;
}
.k-scheduler-phone .k-toolbar .k-nav-prev {
  float: left;
}
.k-scheduler-toolbar .k-i-calendar,
.k-scheduler-footer .k-icon {
  margin: -2px 6px 0 0;
}
.k-scheduler-header,
.k-scheduler-header-wrap {
  overflow: hidden;
}
.k-scheduler-header-wrap {
  position: relative;
  border-width: 0;
  border-style: solid;
}
.k-scheduler .k-scrollbar-v .k-scheduler-header-wrap {
  border-right-width: 1px;
}
.k-scheduler-times,
.k-scheduler-content {
  position: relative;
}
.k-scheduler-times {
  overflow: hidden;
  border-width: 0;
  border-style: solid;
}
.k-scheduler-content {
  overflow: auto;
}
.k-scheduler-layout,
.k-scheduler-table {
  width: 100%;
  margin: 0;

  border-spacing: 0;
  border-collapse: separate;
}
.k-ie7 .k-scheduler-content .k-scheduler-table {
  width: auto;
}
.k-scheduler-layout > tbody > tr > td {
  padding: 0;
  vertical-align: top;
}
.k-safari .k-scheduler-layout > tbody > tr > td + td {
  width: 100%;
}
.k-scheduler-table {
  table-layout: fixed;
}
.k-scheduler-times .k-scheduler-table {
  table-layout: auto;
}
.k-scheduler-monthview .k-scheduler-content .k-scheduler-table {
  height: 100%;
}
.k-scheduler-table td,
.k-scheduler-table th {
  font-size: 100%;

  height: 1.5em;
  padding: 0.334em 0.5em;
}
.k-scheduler .k-scheduler-table td,
.k-scheduler .k-scheduler-table th {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.k-scheduler-monthview .k-hidden,
.k-scheduler-monthview .k-hidden > div {
  overflow: hidden !important;

  width: 0 !important;
}
.k-scheduler-monthview .k-hidden {
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-right-width: 0 !important;
}
.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times {
  margin-right: 1px;
}
.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times .k-hidden {
  height: auto;
}
.k-scheduler-monthview .k-scheduler-table td,
.k-scheduler-monthview .k-hidden {
  height: 80px;
  text-align: right;
}
.k-scheduler-phone .k-scheduler-monthview .k-scheduler-table td,
.k-scheduler-phone .k-scheduler-monthview .k-hidden {
  height: 40px;
}
.k-scheduler-table td,
.k-slot-cell {
  vertical-align: top;
}
.k-scheduler-layout tr + tr .k-scheduler-times th:last-child {
  vertical-align: top;
}
.k-scheduler-phone .k-scheduler-monthview .k-scheduler-table td {
  text-align: center;
  vertical-align: middle;
}
.k-scheduler-phone .k-scheduler-monthview .k-scheduler-table td span {
  font-size: 1.5em;
}
.k-scheduler-header th {
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-scheduler-table td,
.k-scheduler-header th {
  border-width: 0 0 1px 1px;
  border-style: solid;
}
.k-scheduler-table td:first-child,
.k-scheduler-header th:first-child {
  border-left-width: 0;
}
.k-scheduler-agendaview .k-scheduler-table td:first-child {
  border-left-width: 1px;
}
.k-scheduler-agendaview .k-scheduler-table td.k-first {
  border-left-width: 0;
}
.k-scheduler-layout tr + tr .k-scheduler-times tr:last-child > th,
.k-scheduler-layout tr + tr .k-scheduler-table > tbody > tr:last-child > td,
.k-scheduler-table > tbody > tr > .k-last {
  border-bottom-width: 0;
}
.k-scrollbar-h tr + tr .k-scheduler-times,
.k-scrollbar-h
  .k-scheduler-content
  .k-scheduler-table
  > tbody
  > tr:last-child
  > td,
.k-scheduler-agendaview.k-scrollbar-h
  .k-scheduler-table
  > tbody
  > tr
  > td.k-last {
  border-bottom-width: 1px;
}
.k-scheduler-times th {
  padding-right: 0.6em;

  text-align: right;
  white-space: nowrap;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: transparent;
}
.k-scheduler-layout tr + tr .k-scheduler-times th {
  border-bottom-color: transparent;
}
.k-scheduler-layout tr + tr .k-scheduler-times th.k-slot-cell,
.k-scheduler-layout tr + tr .k-scheduler-times th.k-scheduler-times-all-day {
  border-bottom-color: inherit;
}
.k-scheduler .k-middle-row td {
  border-bottom-style: dotted;
}
.k-scheduler-now-arrow,
.k-scheduler-now-line {
  position: absolute;
}
.k-scheduler-now-arrow {
  left: 0;

  width: 0;
  height: 0;
  border: solid 5px transparent;
}
.k-scheduler-now-line {
  right: 0;
  left: 5px;
  height: 1px;
}
.k-task {
  position: relative;
}
div.k-more-events {
  font-size: 18px;
  line-height: 1.2;
  padding: 0;

  text-align: center;
}
.k-more-events > span {
  display: block;
  margin-top: -0.6em;
}
.k-event,
.k-more-events {
  position: absolute;
  overflow: hidden;
  text-align: left;
  border-width: 1px;
  border-style: solid;
}
.k-event {
  min-height: 1.3em;

  cursor: default;
}
.k-event-drag-hint {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  opacity: 0.6;

  filter: alpha(opacity=60);
}
.k-scheduler-header .k-event {
  white-space: nowrap;
}
.k-event-template {
  padding: 0.3em 1.4em 0.3em 0.6em;
}
.k-event-time {
  font-size: 0.9em;

  display: none;
  padding-bottom: 0;
}
.k-event-drag-hint .k-event-time {
  display: block;
}
.k-event-actions,
.k-event > .k-link,
.k-task > .k-link {
  position: absolute;
  top: 3px;
  right: 4px;
  white-space: nowrap;
}
.k-event-actions {
  z-index: 1;
}
.k-scheduler-agendaview .k-task > .k-link {
  top: 0;
  right: 0;
}
.k-event-actions:first-child {
  position: static;
  float: left;
  margin: 4px 2px 0 4px;
}
.k-webkit .k-event-actions:first-child {
  margin-top: 3px;
}
.k-event-actions:first-child > .k-link {
  display: inline-block;
}
.k-event-delete {
  display: none;
}
.k-event:hover .k-event-delete,
tr:hover > td > .k-task .k-event-delete {
  display: inline-block;
}
.k-event .k-event-top-actions,
.k-event .k-event-bottom-actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.k-event .k-event-bottom-actions {
  top: auto;
  bottom: 0;
}
.k-event .k-resize-handle,
.k-scheduler-mobile .k-event:hover .k-resize-handle {
  position: absolute;
  z-index: 2;
  visibility: hidden;
}
.k-event:hover .k-resize-handle,
.k-event-active .k-resize-handle,
.k-scheduler-mobile .k-event-active:hover .k-resize-handle {
  visibility: visible;
}
.k-event .k-resize-handle:after {
  position: absolute;

  content: "";
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.k-scheduler-mobile .k-event .k-resize-handle:after {
  opacity: 1;

  filter: none;
}
.k-event > .k-resize-n {
  top: 0;
  right: 0;
  left: 0;
  height: 0.4em;
}
.k-event > .k-resize-s {
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.4em;
}
.k-event > .k-resize-e {
  top: 0;
  right: 0;
  bottom: 0;
  width: 0.4em;
}
.k-event > .k-resize-w {
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.4em;
}
.k-event > .k-resize-n:after,
.k-event > .k-resize-s:after {
  top: 1px;
  left: 50%;
  width: 2em;
  height: 1px;
  margin-left: -1em;
}
.k-event > .k-resize-s:after {
  top: auto;
  bottom: 1px;
}
.k-event > .k-resize-e:after,
.k-event > .k-resize-w:after {
  top: 50%;
  left: 1px;
  width: 1px;
  height: 1.4em;
  margin-top: -0.7em;
}
.k-event > .k-resize-e:after {
  right: 1px;
  left: auto;
}
.k-scheduler-mobile .k-event > .k-resize-n,
.k-scheduler-mobile .k-event > .k-resize-s {
  height: 0.6em;
}
.k-scheduler-mobile .k-event > .k-resize-e,
.k-scheduler-mobile .k-event > .k-resize-w {
  width: 0.6em;
}
.k-scheduler-mobile .k-event > .k-resize-n:after,
.k-scheduler-mobile .k-event > .k-resize-s:after {
  top: 0;
  width: 4em;
  height: 0.6em;
  margin-left: -3em;
}
.k-scheduler-mobile .k-event > .k-resize-s:after {
  bottom: 0;
}
.k-scheduler-mobile .k-event > .k-resize-e:after,
.k-scheduler-mobile .k-event > .k-resize-w:after {
  left: 0;
  width: 0.6em;
  height: 1.4em;
  margin-top: -0.7em;
}
.k-scheduler-mobile .k-event > .k-resize-e:after {
  right: 0;
}
.k-scheduler-mobile .k-event > .k-resize-n:after {
  border-radius: 0 0 4px 4px;
}
.k-scheduler-mobile .k-event > .k-resize-s:after {
  border-radius: 4px 4px 0 0;
}
.k-scheduler-mobile .k-event > .k-resize-w:after {
  border-radius: 0 4px 4px 0;
}
.k-scheduler-mobile .k-event > .k-resize-e:after {
  border-radius: 4px 0 0 4px;
}
.k-scheduler-phone .k-scheduler-monthview .k-events-container {
  line-height: 6px;

  position: absolute;
  height: 6px;
  text-align: center;
}
.k-scheduler-phone .k-scheduler-monthview .k-event {
  position: static;
  display: inline-block;
  width: 4px;
  height: 4px;
  min-height: 0;
  margin: 1px;
}
.k-scheduler-marquee {
  border-width: 0;
  border-style: solid;
}
.k-scheduler-marquee.k-first:before,
.k-scheduler-marquee.k-last:after {
  position: absolute;
  width: 0;
  height: 0;

  content: "";
  border-width: 3px;
  border-style: solid;
}
div.k-scheduler-marquee:before {
  top: 0;
  left: 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
div.k-scheduler-marquee:after {
  right: 0;
  bottom: 0;
  border-top-color: transparent;
  border-left-color: transparent;
}
.k-scheduler-marquee .k-label-top {
  font-size: 0.8em;

  position: absolute;
  top: 0.3em;
  left: 0.8em;
}
.k-scheduler-marquee .k-label-bottom {
  font-size: 0.8em;

  position: absolute;
  right: 0.81em;
  bottom: 0.3em;
}
.k-scheduler-quickedit .k-textbox {
  width: 200px;
}
.k-tooltip-bottom {
  text-align: left;
}
.k-tooltip-bottom .k-button {
  float: left;
  margin-right: 0.3em;
}
.k-tooltip-bottom .k-quickedit-details {
  float: right;
  margin-right: 0;
}
.k-scheduler-agendaview .k-scheduler-table th,
.k-scheduler-agendaview .k-scheduler-table td {
  text-align: left;
}
.k-scheduler-times .k-slot-cell,
.k-scheduler-groupcolumn {
  width: 6em;
}
.k-scheduler-datecolumn {
  width: 12em;
}
.k-scheduler-timecolumn {
  width: 11em;
}
.k-scheduler-timecolumn > div {
  position: relative;
  vertical-align: top;
}
.k-webkit .k-scheduler-timecolumn > div > .k-icon {
  vertical-align: top;
}
.k-scheduler-timecolumn > div > .k-i-arrow-e {
  position: absolute;
  right: -4px;
}
.k-scheduler-timecolumn .k-i-arrow-w {
  margin-left: -4px;
}
.k-scheduler-mark {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  vertical-align: middle;
}
.k-scheduler-agendaday {
  font-size: 3em;
  font-weight: normal;

  float: left;
  margin: 0 0.2em 0 0;
}
.k-scheduler-agendaweek {
  font-size: 1.1em;
  font-style: normal;

  display: block;
  margin: 0.4em 0 0;
}
.k-scheduler-agendadate {
  font-size: 0.8em;
}
.k-scheduler-timecolumn {
  white-space: nowrap;
}
.k-scheduler-edit-form .k-edit-form-container,
.k-scheduler-timezones .k-edit-form-container {
  width: 520px;
}
.k-scheduler-edit-form .k-edit-label {
  width: 17%;
}
.k-scheduler-edit-form .k-edit-field {
  width: 77%;
}
.k-scheduler-edit-form .k-textbox[name="title"],
.k-scheduler-edit-form textarea.k-textbox {
  width: 100%;
}
.k-scheduler-edit-form textarea.k-textbox {
  min-height: 4em;
  resize: vertical;
}
.k-scheduler-edit-form > .k-edit-box:first-child .k-datetimepicker {
  margin-right: 1em;
}
.km-pane-wrapper .k-scheduler-edit-form .k-edit-buttons {
  clear: right;
  margin-right: 2%;
  margin-left: 2%;
  padding: 0 0 0.6em;
}
.k-edit-box {
  float: left;
}
.k-edit-box + .k-edit-box {
  float: right;
}
.k-scheduler-edit-form label + input {
  margin-left: 1em;
}
.k-edit-field > ul.k-reset > li {
  line-height: 2.4;

  margin: 0.2em 0 0.4em;
}
.k-edit-field > ul.k-reset.k-toolbar > li {
  margin: 0;
}
.k-edit-field > ul.k-reset .k-widget {
  margin-left: 0.8em;
}
.k-edit-field > ul.k-reset .k-numerictextbox,
.k-edit-field span.k-recur-interval {
  width: 5em;
}
.k-edit-field > ul.k-reset .k-dropdown,
.k-edit-field > ul.k-reset .k-datepicker,
div[name="recurrenceRule"] > .k-dropdown {
  width: 9em;
}
.k-scheduler-edit-form .k-edit-buttons .k-scheduler-delete {
  float: left;
}
.k-popup-message {
  margin: 0;
  padding: 1em 0 2em;
  text-align: center;
}
.k-scheduler-timezones .k-dropdown:first-child {
  width: 100%;
}
.k-scheduler-timezones .k-dropdown + .k-dropdown {
  margin: 0.5em 0 0.7em;
}
.k-scheduler .k-current-time {
  position: absolute;

  background: #f00;
}
.k-tooltip {
  position: absolute;
  z-index: 12000;
  min-width: 20px;
  padding: 4px 5px 4px 6px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  background-repeat: repeat-x;
}
.k-tooltip-button {
  height: 0;

  text-align: right;
}
.k-tooltip-content {
  height: 100%;
}
.k-tooltip-closable .k-tooltip-content {
  padding-right: 20px;
}
span.k-tooltip {
  position: static;
  display: inline-block;
  padding: 2px 5px 1px 6px;
  border-width: 1px;
}
.k-invalid-msg {
  display: none;
}
.k-callout {
  position: absolute;
  width: 0;
  height: 0;
  border-width: 6px;
  border-style: solid;
  border-color: transparent;
}
.k-callout-n {
  top: -13px;
  left: 50%;
}
.k-callout-w {
  top: 50%;
  left: -13px;
}
.k-callout-s {
  bottom: -13px;
  left: 50%;
}
.k-callout-e {
  top: 50%;
  right: -13px;
}
.k-slider-tooltip .k-callout-n,
.k-slider-tooltip .k-callout-s {
  margin-left: -6px;
}
.k-slider-tooltip .k-callout-w,
.k-slider-tooltip .k-callout-e {
  margin-top: -6px;
}
.k-tooltip-validation .k-warning {
  margin-right: 3px;

  vertical-align: text-top;
}
.k-tooltip-validation {
  z-index: 9999;
}
.k-toolbar {
  line-height: 2.9em;

  position: relative;
  display: block;
  vertical-align: middle;
}
.k-toolbar .k-button .k-icon,
.k-toolbar .k-button .k-sprite,
.k-overflow-container .k-button .k-icon,
.k-overflow-container .k-button .k-sprite {
  margin-top: -7px;
  margin-bottom: -5px;

  vertical-align: middle;
}
.k-toolbar .k-input {
  line-height: inherit;
  height: inherit;
  padding-top: 2px;
  padding-bottom: 2px;
}
.k-toolbar .k-input:before {
  display: inline-block;
  width: 0;

  content: "\a0";
}
.k-ie .k-toolbar .k-input {
  height: 1.65em;
}
.k-toolbar .k-combobox .k-dropdown-wrap:before,
.k-toolbar .k-picker-wrap:before,
.k-toolbar .k-numeric-wrap:before {
  display: none;
}
.k-overflow-container .k-sprite {
  margin-left: -4px;
}
.k-toolbar-resizable {
  overflow: hidden;
  white-space: nowrap;
}
.k-toolbar > .k-align-left {
  float: none;
}
.k-toolbar > .k-align-right {
  float: right;
}
.k-toolbar > *,
.k-toolbar .k-button {
  line-height: 1.72em;

  display: inline-block;
  vertical-align: middle;
}
.k-toolbar .k-separator {
  line-height: inherit;
  width: 1px;

  border-width: 0 0 0 1px;
  border-style: solid;
}
.k-toolbar .k-button-group {
  list-style-type: none;
}
.k-toolbar .k-button-group > li {
  display: inline-block;
}
.k-toolbar .k-button-group .k-button {
  margin: 0 0 0 -1px;
  border-radius: 0;
}
.k-toolbar .k-button,
.k-toolbar .k-split-button,
.k-toolbar .k-button-group,
.k-toolbar .k-widget,
.k-toolbar .k-textbox,
.k-toolbar label,
.k-toolbar .k-separator {
  line-height: 1.72em;

  margin: 0 0.2em;
  vertical-align: middle;
}
.k-toolbar .k-split-button {
  padding-left: 0;
}
.k-toolbar .k-split-button .k-button,
.k-toolbar .k-button-group .k-group-start {
  margin: 0;
}
.k-toolbar .k-split-button .k-split-button-arrow {
  margin: 0 0 0 -1px;
}
.k-toolbar .k-overflow-anchor {
  line-height: inherit;
  position: relative;
  float: right;
  width: 3em;
  height: 3em;
  margin: 0;
  padding: 0 0.5em;

  border-width: 0 0 0 1px;
  border-style: solid;
  border-radius: 0;
}
.k-overflow-container .k-item {
  float: none;
  border: 0;
}
.k-overflow-container .k-separator {
  font-size: 0;
  line-height: 0;
  height: 1px;
  padding: 0;

  border-width: 0 0 1px;
  border-style: solid;
}
.k-overflow-container .k-overflow-button,
.k-split-container .k-button {
  display: block;

  text-align: left;
  white-space: nowrap;
  border-color: transparent;
  background: 0;
}
.k-split-container {
  margin-top: -1px;
}
.k-overflow-container .k-button-group {
  padding: 0;
}
.k-overflow-container .k-button-group > li {
  display: block;
}
.k-overflow-container .k-overflow-group {
  margin: 1px 0;
  padding: 2px 0;

  border-width: 1px 0;
  border-style: solid;
  border-radius: 0;
}
.k-overflow-container .k-overflow-hidden {
  display: none;
}
.k-overflow-container .k-toolbar-first-visible,
.k-overflow-container .k-overflow-group + .k-overflow-group,
.k-overflow-container .k-separator + .k-overflow-group {
  margin-top: 0;
  padding-top: 1px;

  border-top: 0;
}
.k-overflow-container .k-overflow-group + .k-separator {
  display: none;
}
.k-overflow-container .k-toolbar-last-visible {
  margin-bottom: 0;
  padding-bottom: 1px;

  border-bottom: 0;
}
.k-splitter {
  position: relative;
  height: 300px;
}
.k-pane > .k-splitter {
  overflow: hidden;

  border-width: 0;
}
.k-splitter .k-pane {
  overflow: hidden;
}
.k-splitter .k-scrollable {
  overflow: auto;
}
.k-splitter .k-pane-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
}
.k-ghost-splitbar,
.k-splitbar {
  font-size: 0;

  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  border-style: solid;
  outline: 0;
  -khtml-user-select: none;
}
.k-splitter .k-ghost-splitbar-horizontal,
.k-splitter .k-splitbar-horizontal {
  top: 0;
  width: 5px;
  border-width: 0 1px;
  background-repeat: repeat-y;
}
.k-ghost-splitbar-vertical,
.k-splitbar-vertical {
  left: 0;
  height: 5px;
  border-width: 1px 0;
  background-repeat: repeat-x;
}
.k-splitbar-draggable-horizontal {
  cursor: w-resize;
}
.k-splitbar-draggable-vertical {
  cursor: n-resize;
}
.k-splitbar .k-resize-handle {
  display: none;
}
.k-restricted-size-horizontal,
.k-restricted-size-vertical {
  background-color: #f00;
}
.k-splitbar-horizontal .k-icon {
  position: absolute;
  top: 50%;
  width: 5px;
  height: 20px;
  margin-top: -10px;
}
.k-collapse-prev,
.k-collapse-next,
.k-expand-prev,
.k-expand-next {
  cursor: pointer;
}
.k-splitbar-horizontal .k-collapse-prev {
  margin-top: -31px;
}
.k-splitbar-horizontal .k-collapse-next {
  margin-top: 11px;
}
.k-splitbar-static-horizontal {
  width: 1px;
}
.k-splitbar-static-vertical {
  height: 1px;
}
.k-splitbar-vertical .k-icon {
  position: absolute;
  left: 50%;
  width: 20px;
  height: 5px;
  margin-left: -10px;
}
.k-splitbar-vertical .k-collapse-prev {
  margin-left: -31px;
}
.k-splitbar-vertical .k-collapse-next {
  margin-left: 11px;
}
.k-splitbar-draggable-vertical .k-resize-handle,
.k-splitbar-draggable-horizontal .k-resize-handle {
  display: inline-block;
}
.k-splitbar-horizontal .k-resize-handle {
  background-position: -165px -6px;
}
.k-splitbar-horizontal-hover > .k-resize-handle {
  background-position: -181px -6px;
}
.k-splitbar-horizontal .k-collapse-prev,
.k-splitbar-horizontal .k-expand-next {
  background-position: -6px -174px;
}
.k-splitbar-horizontal-hover > .k-collapse-prev,
.k-splitbar-horizontal-hover > .k-expand-next {
  background-position: -22px -174px;
}
.k-splitbar-horizontal .k-collapse-next,
.k-splitbar-horizontal .k-expand-prev {
  background-position: -5px -142px;
}
.k-splitbar-horizontal-hover > .k-collapse-next,
.k-splitbar-horizontal-hover > .k-expand-prev {
  background-position: -21px -142px;
}
.k-splitbar-vertical .k-resize-handle {
  background-position: -38px -309px;
}
.k-splitbar-vertical-hover > .k-resize-handle {
  background-position: -70px -309px;
}
.k-splitbar-vertical .k-collapse-prev,
.k-splitbar-vertical .k-expand-next {
  background-position: 2px -134px;
}
.k-splitbar-vertical-hover > .k-collapse-prev,
.k-splitbar-vertical-hover > .k-expand-next {
  background-position: -14px -134px;
}
.k-splitbar-vertical .k-collapse-next,
.k-splitbar-vertical .k-expand-prev {
  background-position: 2px -165px;
}
.k-splitbar-vertical-hover > .k-collapse-next,
.k-splitbar-vertical-hover > .k-expand-prev {
  background-position: -14px -165px;
}
html .k-upload {
  position: relative;
}
html .k-upload-empty {
  border-width: 0;
  background: 0;
}
.k-dropzone em,
.k-upload-button {
  vertical-align: middle;
}
.k-ie7 .k-dropzone em,
.k-ie7 .k-upload-button {
  vertical-align: baseline;
}
.k-dropzone,
.k-file {
  position: relative;
}
.k-dropzone {
  padding: 0.8em;
  border-width: 0;
  border-style: solid;
  background-color: transparent;
}
.k-dropzone em {
  visibility: hidden;
  margin-left: 0.6em;
}
.k-dropzone-active em {
  visibility: visible;
}
.k-upload-button {
  position: relative;
  overflow: hidden !important;
  min-width: 7.167em;
  direction: ltr;
}
.k-upload-sync .k-upload-button,
.k-ie7 .k-upload-button,
.k-ie8 .k-upload-button,
.k-ie9 .k-upload-button {
  margin: 0.8em;
}
.k-upload-button input {
  font: 200px monospace !important;

  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.k-upload-files {
  line-height: 2.66;

  margin: 0 0 0.6em;
  border-width: 1px 0 0;
  border-style: solid;
}
.k-upload-files .k-button {
  padding: 0;
}
.k-upload-files .k-button,
.k-upload-status-total .k-icon {
  margin-left: 8px;
}
.k-ie7 .k-upload-files .k-button {
  line-height: 1;
}
.k-upload .k-fail {
  background-position: -161px -111px;
}
.k-si-refresh {
  background-position: -160px -128px;
}
.k-link:not(.k-state-disabled):hover > .k-si-refresh,
.k-state-hover > .k-si-refresh,
.k-state-hover > * > .k-si-refresh,
.k-button:not(.k-state-disabled):hover .k-si-refresh,
.k-textbox:hover .k-si-refresh,
.k-button:active .k-si-refresh {
  background-position: -176px -128px;
}
.k-si-tick,
.k-success {
  background-position: -160px -96px;
}
.k-link:not(.k-state-disabled):hover > .k-si-tick,
.k-link:not(.k-state-disabled):hover > .k-success,
.k-state-hover > .k-si-tick,
.k-state-hover > .k-success,
.k-state-hover > * > .k-si-tick,
.k-state-hover > * > .k-success,
.k-button:not(.k-state-disabled):hover .k-si-tick,
.k-button:not(.k-state-disabled):hover .k-success,
.k-textbox:hover .k-si-tick,
.k-textbox:hover .k-success,
.k-button:active .k-si-tick,
.k-button:active .k-success {
  background-position: -176px -96px;
}
.k-si-cancel {
  background-position: -160px -112px;
}
.k-link:not(.k-state-disabled):hover > .k-si-cancel,
.k-state-hover > .k-si-cancel,
.k-state-hover > * > .k-si-cancel,
.k-button:not(.k-state-disabled):hover .k-si-cancel,
.k-textbox:hover .k-si-cancel,
.k-button:active .k-si-cancel {
  background-position: -176px -112px;
}
.k-file {
  padding: 0.167em 0.167em 0.167em 0.8em;
  border-width: 0 0 1px;
  border-style: solid;
}
.k-file .k-icon {
  position: relative;
}
.k-file > .k-icon {
  background-position: -112px -288px;
}
.k-link:not(.k-state-disabled):hover > .k-file > .k-icon,
.k-state-hover > .k-file > .k-icon,
.k-state-hover > * > .k-file > .k-icon,
.k-button:not(.k-state-disabled):hover .k-file > .k-icon,
.k-textbox:hover .k-file > .k-icon,
.k-button:active .k-file > .k-icon {
  background-position: -128px -288px;
}
.k-filename {
  position: relative;
  display: inline-block;
  overflow: hidden;
  min-width: 10em;
  max-width: 16.667em;
  margin-left: 1em;
  padding-bottom: 0.167em;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.k-upload-status {
  line-height: 0.7em;

  position: absolute;
  top: 0.6em;
  right: 12px;
}
.k-upload-status .k-button,
.k-upload-status .k-warning {
  vertical-align: text-bottom;
}
.k-dropzone .k-upload-status {
  line-height: 2.4;
}
.k-upload-pct {
  line-height: 20px;
}
.k-ie8 .k-upload-status-total {
  line-height: 29px;
}
.k-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.k-upload-selected {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  min-width: 7.167em;
  margin: 0.25em 0 0;
}
.k-ie7 .k-upload-selected {
  min-width: 100px;
}
.k-upload-selected,
.k-upload-cancel {
  margin-bottom: 0.8em;
}
.k-upload-selected {
  margin-right: 0.2em;
  margin-left: 0.8em;
}
.k-toolbar-wrap .k-dropzone em,
.k-toolbar-wrap .k-upload-files {
  display: none;
}
.k-toolbar-wrap .k-dropzone {
  padding: 0;

  border: 0;
}
.k-toolbar-wrap .k-dropzone-active {
  text-align: center;
}
.k-toolbar-wrap .k-dropzone-active em {
  font-size: 5em;
  font-style: normal;

  display: inline;
  margin: 0;
}
.k-toolbar-wrap .k-dropzone-active .k-upload-button {
  display: none;
}
.k-filebrowser-dropzone {
  position: fixed;
  z-index: 10010;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.k-search-wrap {
  position: relative;
  float: right;
  width: 20%;
  padding: 0;
}
.k-search-wrap label {
  font-style: italic;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 4px;
}
.k-search-wrap input.k-input {
  padding-right: 0;
  padding-left: 0;
}
.k-search-wrap .k-search {
  position: absolute;
  top: 4px;
  right: 2px;
  margin: 0;
}
.k-breadcrumbs {
  position: relative;
  float: left;
  width: 79%;
}
.k-breadcrumbs-wrap {
  line-height: 18px;

  position: absolute;
  z-index: 1;
  top: 3px;
  left: 0;
  padding-left: 5px;
}
.k-breadcrumbs > .k-input {
  font-family: inherit;
  font-size: inherit;

  width: 100%;
  border: 0;
}
.k-breadcrumbs .k-link,
.k-breadcrumbs-wrap .k-icon {
  position: static;

  margin-top: 0;
  vertical-align: middle;
  text-decoration: none;
}
.k-breadcrumbs .k-link:hover {
  text-decoration: underline;
}
.k-filebrowser .k-breadcrumbs .k-i-seek-w {
  cursor: default;
  text-decoration: none;
}
.k-filebrowser .k-filebrowser-toolbar {
  line-height: 23px;
  margin: 8px 0 0;
  padding: 0.25em;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
}
.k-filebrowser .k-filebrowser-toolbar .k-button.k-state-disabled {
  display: none;
}
.k-filebrowser .k-toolbar-wrap {
  float: left;
}
.k-filebrowser .k-tiles-arrange {
  float: right;
}
.k-filebrowser .k-tiles-arrange .k-dropdown {
  width: 75px;
}
.k-filebrowser .k-upload {
  z-index: 10010;

  float: left;
  border-width: 0;
  background-color: transparent;
}
.k-filebrowser .k-upload .k-upload-status {
  display: none;
}
.k-filebrowser .k-upload .k-upload-button {
  width: auto;
  margin-left: 0;
  vertical-align: top;
}
.k-filebrowser .k-upload .k-icon {
  vertical-align: bottom;
}
.k-ie7 .k-filebrowser .k-upload-button,
.k-ie7 .k-filebrowser .k-upload .k-icon {
  position: relative;
  top: 1px;

  vertical-align: baseline;
}
.k-ie7 .k-filebrowser .k-upload .k-icon {
  top: 2px;
}
.k-ie7 .k-filebrowser .k-filebrowser-toolbar .k-button-icon {
  vertical-align: middle;
}
.k-tiles {
  line-height: 1.2;

  clear: both;
  overflow: auto;
  height: 390px;
  margin: 0 0 1.4em;
  padding: 9px;
  border-width: 1px;
  border-style: solid;
  border-top-width: 0;
}
.k-tile {
  float: left;
  overflow: hidden;
  width: 223px;
  height: 88px;
  margin: 1px;
  padding: 0 0 4px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  background-repeat: repeat-x;
  background-position: 0 100px;
}
.k-tiles li.k-state-hover,
.k-tiles li.k-state-selected {
  background-position: 0 center;
}
.k-filebrowser .k-thumb {
  display: inline;
  float: left;
  width: 80px;
  height: 80px;
  margin: 4px 10px 0 4px;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}
.k-filebrowser .k-file {
  width: 80px;
  height: 80px;
}
.k-filebrowser .k-image {
  margin: 2px 0 0 2px;
}
.k-filebrowser .k-folder {
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: 0 -200px;
}
.k-filebrowser .k-loading {
  margin: 35px 0 0 33px;
}
.k-tile strong,
.k-tile input {
  font-weight: normal;

  margin: 10px 0 4px;
}
.k-tile strong {
  float: left;
  overflow: hidden;
  width: 120px;
  text-overflow: ellipsis;
}
.k-tile input {
  width: 100px;
}
.k-tile strong,
.k-tile input,
.k-tile .k-filesize {
  display: block;
}
.k-filebrowser .k-form-text-row {
  text-align: right;
}
.k-filebrowser .k-form-text-row label {
  width: 14%;
}
.k-filebrowser .k-form-text-row input {
  width: 80%;
}
.k-tile-empty {
  margin: 160px 0 0;
}
.k-tile-empty .k-dialog-upload {
  font-size: 120%;
  font-weight: bold;
}
.k-tile-empty strong {
  font-size: 3em;
  font-weight: normal;

  display: block;
  margin: 0 0 0.2em;
}
.k-tile-empty,
.k-tile-empty .k-button-wrapper {
  text-align: center;
}
.k-ie7 .k-button,
.k-ie7 .k-grid-filter,
.k-ie7 .k-header-column-menu,
.k-ie7 .k-grid .k-pager-numbers,
.k-ie7 .k-grid .k-status-text,
.k-ie7 .k-pager-numbers .k-link,
.k-ie7 .k-pager-numbers span,
.k-ie7 .k-pager-numbers input,
.k-ie7 .k-grouping-row p,
.k-ie7 .k-grouping-header a,
.k-ie7 .k-grouping-header .k-group-indicator,
.k-ie7 .k-grouping-header .k-link,
.k-ie7 .k-grouping-header .k-button,
.k-ie7 .k-grid-actions,
.k-ie7 .k-edit-label,
.k-ie7 .k-edit-field,
.k-ie7 .k-edit-form-container .editor-label,
.k-ie7 .k-edit-form-container .editor-field,
.k-ie7 .k-combobox,
.k-ie7 .k-dropdown,
.k-ie7 .k-selectbox,
.k-ie7 .k-picker-wrap .k-select,
.k-ie7 .k-dropdown-wrap .k-select,
.k-ie7 .k-numerictextbox,
.k-ie7 .k-timepicker,
.k-ie7 .k-datepicker,
.k-ie7 .k-datetimepicker,
.k-ie7 .k-colorpicker,
.k-ie7 .k-calendar,
.k-ie7 .k-calendar .k-nav-fast,
.k-ie7 .k-treeview .k-icon,
.k-ie7 .k-treeview .k-image,
.k-ie7 .k-treeview .k-sprite,
.k-ie7 .k-treeview .k-in,
.k-ie7 .k-colorpicker,
.k-ie7 .k-colorpicker .k-tool-icon,
.k-ie7 .k-palette.k-reset,
.k-ie7 .k-editor-dialog .k-button,
.k-ie7 .k-form-text-row label,
.k-ie7 .k-tabstrip-items .k-item,
.k-ie7 .k-tabstrip-items .k-link,
.k-ie7 .k-slider-horizontal,
.k-ie7 .k-splitbar-draggable-vertical .k-resize-handle,
.k-ie7 .k-splitbar-draggable-horizontal .k-resize-handle,
.k-ie7 .t-filename,
.k-ie7 div.k-window,
.k-ie7 .k-window-titlebar .k-window-action,
.k-ie7 .k-scheduler-toolbar > ul > li,
.k-ie7 .k-scheduler-footer > ul > li,
.k-ie7 .k-scheduler-toolbar > ul > li,
.k-ie7 .k-scheduler-footer > ul > li,
.k-ie7 .k-event:hover .k-event-delete,
.k-ie7 tr:hover > td > .k-task .k-event-delete,
.k-ie7 .k-progressbar,
.k-ie7 .k-progressbar-horizontal .k-item,
.k-ie7 .k-progress-status,
.k-ie7 .k-grid-header-locked,
.k-ie7 .k-grid-content-locked,
.k-ie7 .k-grid-header-locked + .k-grid-header-wrap,
.k-ie7 .k-grid-content-locked + .k-grid-content,
.k-ie7 .k-grid-footer-locked,
.k-ie7 .k-gantt-layout,
.k-ie7 .k-gantt-toolbar > ul > li,
.k-ie7 .k-gantt-toolbar .k-link,
.k-ie7 .k-task-summary,
.k-ie7 .k-task-actions:first-child > .k-link,
.k-ie7 .k-task-wrap:hover .k-task-delete,
.k-ie7 .k-task-wrap-active .k-task-delete {
  display: inline;
  zoom: 1;
}
.k-ie7 .k-treeview .k-item,
.k-ie7 .k-treeview .k-group {
  zoom: 1;
}
.k-ie7 .k-edit-field > .k-textbox {
  text-indent: 0;
}
.km-root,
.km-pane,
.km-pane-wrapper {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  -ms-touch-action: none;
  -ms-content-zooming: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}
.km-pane-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.km-pane,
.km-shim {
  font-family: sans-serif;
}
.km-pane {
  overflow-x: hidden;
}
.km-view {
  position: absolute;
  top: 0;
  left: 0;
  display: -moz-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  vertical-align: top;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  align-content: stretch;
}
.k-ff .km-view,
.k-ff .km-pane {
  overflow: hidden;
}
.k-ff18 .km-view,
.k-ff18 .km-pane,
.k-ff19 .km-view,
.k-ff19 .km-pane,
.k-ff20 .km-view,
.k-ff20 .km-pane,
.k-ff21 .km-view,
.k-ff21 .km-pane {
  position: relative;
}
.k-ff .km-view {
  display: -moz-inline-box;
  display: flex;
}
.km-content {
  position: relative;
  display: block;
  overflow: hidden;
  flex-align: stretch;
  width: auto;
  min-height: 1px;
  -moz-box-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -moz-box-align: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
}
.km-actionsheet > li {
  line-height: 2em;
  padding: inherit 1em;

  list-style-type: none;
}
.km-actionsheet {
  margin: 0;
  padding: 0;
}
.km-shim {
  position: fixed;
  z-index: 10001;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.km-shim .k-animation-container,
.km-actionsheet-wrapper {
  width: 100%;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.km-shim .k-animation-container {
  width: auto;
}
.km-pane-wrapper .k-grid-edit-form > .km-header,
.km-pane-wrapper .k-grid-column-menu > .km-header,
.km-pane-wrapper .k-grid-filter-menu > .km-header,
.km-pane-wrapper .k-scheduler-edit-form > .km-header {
  line-height: 2em;
  width: auto;
  padding: 0.3em 0.6em;
  text-align: center;
  border-width: 1px;
  border-style: solid;
}
.k-ie .km-pane-wrapper .k-scheduler > .k-scheduler-toolbar,
.k-ie .km-pane-wrapper .k-scheduler > .k-scheduler-footer {
  line-height: 2em;
}
.km-pane-wrapper .k-grid-edit-form .k-multiselect,
.km-pane-wrapper .k-scheduler-edit-form .k-multiselect {
  width: 15em;
}
.km-pane-wrapper .k-grid-edit-form .k-dropdown-wrap,
.km-pane-wrapper .k-scheduler-edit-form .k-dropdown-wrap {
  display: block;
}
.km-pane-wrapper .k-grid-column-menu .k-done,
.km-pane-wrapper .k-grid-filter-menu .k-submit,
.km-pane-wrapper .k-grid-edit-form .k-grid-update,
.km-pane-wrapper .k-scheduler-edit-form .k-scheduler-update {
  float: right;
}
.km-pane-wrapper .k-grid-filter-menu .k-cancel,
.km-pane-wrapper .k-grid-edit-form .k-grid-cancel,
.km-pane-wrapper .k-scheduler-edit-form .k-scheduler-cancel {
  float: left;
}
.km-pane-wrapper .k-scheduler-edit-form .k-scheduler-delete,
*:not(.km-pane) > .km-shim .km-actionsheet .k-button {
  display: block;
  text-align: center;
}
*:not(.km-pane) > .km-shim .km-actionsheet .k-button {
  font-size: 1.4em;
  margin: 0.3em 1em;
}
*:not(.km-pane) > .km-shim .km-actionsheet-title {
  line-height: 3em;
  margin-bottom: -0.3em;

  text-align: center;
}
*:not(.km-pane) > .km-shim > .k-animation-container {
  left: 0 !important;

  margin: 0 !important;
  padding: 0 !important;
}
.km-pane-wrapper > div.km-pane {
  font-weight: normal;

  -webkit-box-shadow: none;
  box-shadow: none;
}
.km-pane-wrapper .k-popup-edit-form .km-content > .km-scroll-container,
.km-pane-wrapper .k-grid-edit-form .km-content > .km-scroll-container,
.km-pane-wrapper .k-grid-column-menu .km-content > .km-scroll-container,
.km-pane-wrapper .k-grid-filter-menu .km-content > .km-scroll-container {
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.km-pane-wrapper .k-mobile-list .k-edit-field {
  width: 74%;
}
.km-pane-wrapper .k-grid-edit-form .k-popup-edit-form,
.km-pane-wrapper .k-grid-edit-form .k-edit-form-container {
  width: auto;
}
.km-pane-wrapper .k-filter-menu .k-button {
  width: 100%;
  margin: 0;
}
.k-grid-mobile {
  border-width: 0;
}
.k-grid-mobile .k-resize-handle-inner {
  position: absolute;
  top: 50%;
  left: -7px;
  width: 17px;
  height: 17px;
  margin-top: -10px;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
}
.k-grid-mobile .k-resize-handle-inner:before {
  position: absolute;
  top: 50%;
  left: 1px;
  width: 6px;
  height: 6px;
  margin-top: -3px;

  content: "";
  background-position: -5px -53px;
}
.k-grid-mobile .k-resize-handle-inner:after {
  position: absolute;
  top: 50%;
  right: 1px;
  width: 6px;
  height: 6px;
  margin-top: -3px;

  content: "";
  background-position: -5px -21px;
}
.km-pane-wrapper .km-pane * {
  -webkit-background-clip: border-box;
  background-clip: border-box;
}
.km-pane-wrapper .km-pane .k-mobile-list,
.km-pane-wrapper .k-mobile-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: 0;
  background: 0;
}
.km-pane-wrapper .km-switch {
  position: absolute;
  top: 50%;
  right: 0.8rem;
  margin-top: -1.1rem;
}
.km-pane-wrapper .k-mobile-list .k-state-disabled {
  opacity: 1;
}
.km-pane-wrapper .k-mobile-list .k-state-disabled > * {
  opacity: 0.7;
}
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-item > .k-link,
.km-pane-wrapper .k-mobile-list .k-item > .k-label,
.km-pane-wrapper .k-mobile-list .k-edit-label {
  font-size: 1em;
  position: relative;

  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5em 0 0.5em 1em;
  list-style-type: none;
  vertical-align: middle;
}
.km-pane-wrapper .k-edit-form-container,
.km-pane-wrapper .k-scheduler-edit-form .km-scroll-container {
  width: 100%;
  padding-top: 1em;
}
.km-pane-wrapper .k-mobile-list .k-edit-label {
  position: absolute;
  float: none;
  clear: none;
  width: 100%;
  margin: 0;
}
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-edit-label label {
  display: block;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0.1em 0;
  text-align: left;
  text-overflow: ellipsis;
}
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-edit-label {
  font-size: 1em;
  line-height: 1.6em;
  overflow: hidden;
}
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-edit-label {
  float: none;
  clear: none;

  width: 100%;
  min-height: 2.7em;
}
.km-pane-wrapper .km-header .k-icon,
.km-pane-wrapper .k-grid-toolbar .k-icon,
.km-pane-wrapper .k-grid-edit .k-icon,
.km-pane-wrapper .k-grid-delete .k-icon {
  display: none;
}
.km-pane-wrapper .k-mobile-list .k-edit-field {
  padding: 0.5em 0;
}
.km-pane-wrapper .k-mobile-list .k-scheduler-toolbar {
  padding: 0.3em 0;
}
.km-pane-wrapper .k-mobile-list .k-scheduler-toolbar ul li {
  line-height: 2em;
}
.km-pane-wrapper .k-mobile-list .k-item > * {
  line-height: normal;
}
.km-pane-wrapper .k-mobile-list .k-edit-buttons,
.km-pane-wrapper .k-mobile-list .k-button-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0.5em 1em;
}
.km-pane-wrapper .k-mobile-list > ul > li > .k-link,
.km-pane-wrapper .k-mobile-list .k-filter-help-text > li > .k-link,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3),
.km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child {
  font-size: 0.95em;
  font-weight: normal;
  line-height: 2em;
  position: -webkit-sticky;

  display: block;
  margin: 0;
  padding: 0.2em 1em;
  border-top: 1em solid transparent;
  background: transparent;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3),
.km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child {
  position: relative;
}
.km-pane-wrapper .k-mobile-list .k-item:first-child {
  border-top: 0;
}
.km-pane-wrapper .k-mobile-list .k-item:last-child {
  border-bottom: 0;
}
.km-pane-wrapper .k-mobile-list .k-item > .k-link,
.km-pane-wrapper .k-mobile-list .k-item > .k-label {
  line-height: inherit;
  margin: -0.5em 0 -0.5em -1em;
  text-decoration: none;
}
.k-check[type="checkbox"],
.k-check[type="radio"],
.k-mobile-list .k-edit-field [type="checkbox"],
.k-mobile-list .k-edit-field [type="radio"] {
  background-color: transparent;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.km-pane-wrapper .k-mobile-list .k-link .k-check,
.km-pane-wrapper .k-mobile-list .k-label .k-check,
.k-mobile-list .k-edit-field [type="checkbox"],
.k-mobile-list .k-edit-field [type="radio"] {
  font-size: inherit;
  width: 13px;
  height: 13px;
  margin: 0.26em 1em 0.26em 0;

  border: 0;
}
.k-ie .km-pane-wrapper .k-icon,
.k-ie .km-pane-wrapper .k-mobile-list .k-link .k-check,
.k-ie .km-pane-wrapper .k-mobile-list .k-label .k-check,
.k-ie .k-mobile-list .k-edit-field [type="checkbox"],
.k-ie .k-mobile-list .k-edit-field [type="radio"] {
  font-size: inherit;
  width: 1.01em;
  height: 1em;
  text-indent: -9999px;
}
@media screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  .km-pane-wrapper .k-numerictextbox .k-select .k-link span.k-i-arrow-n {
    background-position: 0 0;
  }
  .k-link:not(.k-state-disabled):hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-n,
  .k-state-hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-n,
  .k-state-hover
    > *
    > .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-n,
  .k-button:not(.k-state-disabled):hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-n,
  .k-textbox:hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-n,
  .k-button:active
    .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-n {
    background-position: -1em 0;
  }
  .km-pane-wrapper .k-numerictextbox .k-select .k-link span.k-i-arrow-s {
    background-position: 0 -2em;
  }
  .k-link:not(.k-state-disabled):hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-s,
  .k-state-hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-s,
  .k-state-hover
    > *
    > .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-s,
  .k-button:not(.k-state-disabled):hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-s,
  .k-textbox:hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-s,
  .k-button:active
    .km-pane-wrapper
    .k-numerictextbox
    .k-select
    .k-link
    span.k-i-arrow-s {
    background-position: -1em -2em;
  }
  .km-pane-wrapper .k-state-selected .k-i-arrow-n {
    background-position: -1em 0;
  }
  .k-link:not(.k-state-disabled):hover
    > .km-pane-wrapper
    .k-state-selected
    .k-i-arrow-n,
  .k-state-hover > .km-pane-wrapper .k-state-selected .k-i-arrow-n,
  .k-state-hover > * > .km-pane-wrapper .k-state-selected .k-i-arrow-n,
  .k-button:not(.k-state-disabled):hover
    .km-pane-wrapper
    .k-state-selected
    .k-i-arrow-n,
  .k-textbox:hover .km-pane-wrapper .k-state-selected .k-i-arrow-n,
  .k-button:active .km-pane-wrapper .k-state-selected .k-i-arrow-n {
    background-position: -2em 0;
  }
  .km-pane-wrapper .k-numerictextbox .k-link.k-state-selected span.k-i-arrow-n,
  .km-pane-wrapper .k-numerictextbox .k-state-hover .k-link span.k-i-arrow-n {
    background-position: -1em 0;
  }
  .k-link:not(.k-state-disabled):hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-n,
  .k-link:not(.k-state-disabled):hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-n,
  .k-state-hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-n,
  .k-state-hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-n,
  .k-state-hover
    > *
    > .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-n,
  .k-state-hover
    > *
    > .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-n,
  .k-button:not(.k-state-disabled):hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-n,
  .k-button:not(.k-state-disabled):hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-n,
  .k-textbox:hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-n,
  .k-textbox:hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-n,
  .k-button:active
    .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-n,
  .k-button:active
    .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-n {
    background-position: -2em 0;
  }
  .km-pane-wrapper .k-state-selected .k-i-arrow-s {
    background-position: -1em -2em;
  }
  .k-link:not(.k-state-disabled):hover
    > .km-pane-wrapper
    .k-state-selected
    .k-i-arrow-s,
  .k-state-hover > .km-pane-wrapper .k-state-selected .k-i-arrow-s,
  .k-state-hover > * > .km-pane-wrapper .k-state-selected .k-i-arrow-s,
  .k-button:not(.k-state-disabled):hover
    .km-pane-wrapper
    .k-state-selected
    .k-i-arrow-s,
  .k-textbox:hover .km-pane-wrapper .k-state-selected .k-i-arrow-s,
  .k-button:active .km-pane-wrapper .k-state-selected .k-i-arrow-s {
    background-position: -2em -2em;
  }
  .km-pane-wrapper .k-numerictextbox .k-link.k-state-selected span.k-i-arrow-s,
  .km-pane-wrapper .k-numerictextbox .k-state-hover .k-link span.k-i-arrow-s {
    background-position: -1em -2em;
  }
  .k-link:not(.k-state-disabled):hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-s,
  .k-link:not(.k-state-disabled):hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-s,
  .k-state-hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-s,
  .k-state-hover
    > .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-s,
  .k-state-hover
    > *
    > .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-s,
  .k-state-hover
    > *
    > .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-s,
  .k-button:not(.k-state-disabled):hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-s,
  .k-button:not(.k-state-disabled):hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-s,
  .k-textbox:hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-s,
  .k-textbox:hover
    .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-s,
  .k-button:active
    .km-pane-wrapper
    .k-numerictextbox
    .k-link.k-state-selected
    span.k-i-arrow-s,
  .k-button:active
    .km-pane-wrapper
    .k-numerictextbox
    .k-state-hover
    .k-link
    span.k-i-arrow-s {
    background-position: -2em -2em;
  }
  .km-pane-wrapper .k-i-arrow-n {
    background-position: 0 0;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-arrow-n,
  .k-state-hover > .km-pane-wrapper .k-i-arrow-n,
  .k-state-hover > * > .km-pane-wrapper .k-i-arrow-n,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-arrow-n,
  .k-textbox:hover .km-pane-wrapper .k-i-arrow-n,
  .k-button:active .km-pane-wrapper .k-i-arrow-n {
    background-position: -1em 0;
  }
  .km-pane-wrapper .k-i-arrow-e {
    background-position: 0 -1em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-arrow-e,
  .k-state-hover > .km-pane-wrapper .k-i-arrow-e,
  .k-state-hover > * > .km-pane-wrapper .k-i-arrow-e,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-arrow-e,
  .k-textbox:hover .km-pane-wrapper .k-i-arrow-e,
  .k-button:active .km-pane-wrapper .k-i-arrow-e {
    background-position: -1em -1em;
  }
  .k-rtl .km-pane-wrapper .k-i-arrow-w {
    background-position: 0 -1em;
  }
  .k-rtl .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-arrow-w,
  .k-rtl .k-state-hover > .km-pane-wrapper .k-i-arrow-w,
  .k-rtl .k-state-hover > * > .km-pane-wrapper .k-i-arrow-w,
  .k-rtl .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-arrow-w,
  .k-rtl .k-textbox:hover .km-pane-wrapper .k-i-arrow-w,
  .k-rtl .k-button:active .km-pane-wrapper .k-i-arrow-w {
    background-position: -1em -1em;
  }
  .km-pane-wrapper .k-i-arrow-s {
    background-position: 0 -2em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-arrow-s,
  .k-state-hover > .km-pane-wrapper .k-i-arrow-s,
  .k-state-hover > * > .km-pane-wrapper .k-i-arrow-s,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-arrow-s,
  .k-textbox:hover .km-pane-wrapper .k-i-arrow-s,
  .k-button:active .km-pane-wrapper .k-i-arrow-s {
    background-position: -1em -2em;
  }
  .km-pane-wrapper .k-i-arrow-w {
    background-position: 0 -3em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-arrow-w,
  .k-state-hover > .km-pane-wrapper .k-i-arrow-w,
  .k-state-hover > * > .km-pane-wrapper .k-i-arrow-w,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-arrow-w,
  .k-textbox:hover .km-pane-wrapper .k-i-arrow-w,
  .k-button:active .km-pane-wrapper .k-i-arrow-w {
    background-position: -1em -3em;
  }
  .k-rtl .km-pane-wrapper .k-i-arrow-e {
    background-position: 0 -3em;
  }
  .k-rtl .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-arrow-e,
  .k-rtl .k-state-hover > .km-pane-wrapper .k-i-arrow-e,
  .k-rtl .k-state-hover > * > .km-pane-wrapper .k-i-arrow-e,
  .k-rtl .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-arrow-e,
  .k-rtl .k-textbox:hover .km-pane-wrapper .k-i-arrow-e,
  .k-rtl .k-button:active .km-pane-wrapper .k-i-arrow-e {
    background-position: -1em -3em;
  }
  .km-pane-wrapper .k-i-seek-n {
    background-position: 0 -4em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-seek-n,
  .k-state-hover > .km-pane-wrapper .k-i-seek-n,
  .k-state-hover > * > .km-pane-wrapper .k-i-seek-n,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-seek-n,
  .k-textbox:hover .km-pane-wrapper .k-i-seek-n,
  .k-button:active .km-pane-wrapper .k-i-seek-n {
    background-position: -1em -4em;
  }
  .km-pane-wrapper .k-i-seek-e {
    background-position: 0 -5em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-seek-e,
  .k-state-hover > .km-pane-wrapper .k-i-seek-e,
  .k-state-hover > * > .km-pane-wrapper .k-i-seek-e,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-seek-e,
  .k-textbox:hover .km-pane-wrapper .k-i-seek-e,
  .k-button:active .km-pane-wrapper .k-i-seek-e {
    background-position: -1em -5em;
  }
  .k-rtl .km-pane-wrapper .k-i-seek-w {
    background-position: 0 -5em;
  }
  .k-rtl .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-seek-w,
  .k-rtl .k-state-hover > .km-pane-wrapper .k-i-seek-w,
  .k-rtl .k-state-hover > * > .km-pane-wrapper .k-i-seek-w,
  .k-rtl .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-seek-w,
  .k-rtl .k-textbox:hover .km-pane-wrapper .k-i-seek-w,
  .k-rtl .k-button:active .km-pane-wrapper .k-i-seek-w {
    background-position: -1em -5em;
  }
  .km-pane-wrapper .k-i-seek-s {
    background-position: 0 -6em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-seek-s,
  .k-state-hover > .km-pane-wrapper .k-i-seek-s,
  .k-state-hover > * > .km-pane-wrapper .k-i-seek-s,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-seek-s,
  .k-textbox:hover .km-pane-wrapper .k-i-seek-s,
  .k-button:active .km-pane-wrapper .k-i-seek-s {
    background-position: -1em -6em;
  }
  .km-pane-wrapper .k-i-seek-w {
    background-position: 0 -7em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-seek-w,
  .k-state-hover > .km-pane-wrapper .k-i-seek-w,
  .k-state-hover > * > .km-pane-wrapper .k-i-seek-w,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-seek-w,
  .k-textbox:hover .km-pane-wrapper .k-i-seek-w,
  .k-button:active .km-pane-wrapper .k-i-seek-w {
    background-position: -1em -7em;
  }
  .k-rtl .km-pane-wrapper .k-i-seek-e {
    background-position: 0 -7em;
  }
  .k-rtl .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-seek-e,
  .k-rtl .k-state-hover > .km-pane-wrapper .k-i-seek-e,
  .k-rtl .k-state-hover > * > .km-pane-wrapper .k-i-seek-e,
  .k-rtl .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-seek-e,
  .k-rtl .k-textbox:hover .km-pane-wrapper .k-i-seek-e,
  .k-rtl .k-button:active .km-pane-wrapper .k-i-seek-e {
    background-position: -1em -7em;
  }
  .km-pane-wrapper .k-i-arrowhead-n {
    background-position: 0 -16em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-arrowhead-n,
  .k-state-hover > .km-pane-wrapper .k-i-arrowhead-n,
  .k-state-hover > * > .km-pane-wrapper .k-i-arrowhead-n,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-arrowhead-n,
  .k-textbox:hover .km-pane-wrapper .k-i-arrowhead-n,
  .k-button:active .km-pane-wrapper .k-i-arrowhead-n {
    background-position: -1em -16em;
  }
  .km-pane-wrapper .k-i-arrowhead-e {
    background-position: 0 -17em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-arrowhead-e,
  .k-state-hover > .km-pane-wrapper .k-i-arrowhead-e,
  .k-state-hover > * > .km-pane-wrapper .k-i-arrowhead-e,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-arrowhead-e,
  .k-textbox:hover .km-pane-wrapper .k-i-arrowhead-e,
  .k-button:active .km-pane-wrapper .k-i-arrowhead-e {
    background-position: -1em -17em;
  }
  .km-pane-wrapper .k-i-arrowhead-s {
    background-position: 0 -18em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-arrowhead-s,
  .k-state-hover > .km-pane-wrapper .k-i-arrowhead-s,
  .k-state-hover > * > .km-pane-wrapper .k-i-arrowhead-s,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-arrowhead-s,
  .k-textbox:hover .km-pane-wrapper .k-i-arrowhead-s,
  .k-button:active .km-pane-wrapper .k-i-arrowhead-s {
    background-position: -1em -18em;
  }
  .km-pane-wrapper .k-i-arrowhead-w {
    background-position: 0 -19em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-arrowhead-w,
  .k-state-hover > .km-pane-wrapper .k-i-arrowhead-w,
  .k-state-hover > * > .km-pane-wrapper .k-i-arrowhead-w,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-arrowhead-w,
  .k-textbox:hover .km-pane-wrapper .k-i-arrowhead-w,
  .k-button:active .km-pane-wrapper .k-i-arrowhead-w {
    background-position: -1em -19em;
  }
  .km-pane-wrapper .k-i-expand,
  .km-pane-wrapper .k-plus,
  .km-pane-wrapper .k-plus-disabled {
    background-position: 0 -12em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-expand,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-plus,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-plus-disabled,
  .k-state-hover > .km-pane-wrapper .k-i-expand,
  .k-state-hover > .km-pane-wrapper .k-plus,
  .k-state-hover > .km-pane-wrapper .k-plus-disabled,
  .k-state-hover > * > .km-pane-wrapper .k-i-expand,
  .k-state-hover > * > .km-pane-wrapper .k-plus,
  .k-state-hover > * > .km-pane-wrapper .k-plus-disabled,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-expand,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-plus,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-plus-disabled,
  .k-textbox:hover .km-pane-wrapper .k-i-expand,
  .k-textbox:hover .km-pane-wrapper .k-plus,
  .k-textbox:hover .km-pane-wrapper .k-plus-disabled,
  .k-button:active .km-pane-wrapper .k-i-expand,
  .k-button:active .km-pane-wrapper .k-plus,
  .k-button:active .km-pane-wrapper .k-plus-disabled {
    background-position: -1em -12em;
  }
  .km-pane-wrapper .k-i-expand-w,
  .k-rtl .km-pane-wrapper .k-i-expand,
  .k-rtl .km-pane-wrapper .k-plus,
  .k-rtl .km-pane-wrapper .k-plus-disabled {
    background-position: 0 -13em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-expand-w,
  .k-link:not(.k-state-disabled):hover > .k-rtl .km-pane-wrapper .k-i-expand,
  .k-link:not(.k-state-disabled):hover > .k-rtl .km-pane-wrapper .k-plus,
  .k-link:not(.k-state-disabled):hover
    > .k-rtl
    .km-pane-wrapper
    .k-plus-disabled,
  .k-state-hover > .km-pane-wrapper .k-i-expand-w,
  .k-state-hover > .k-rtl .km-pane-wrapper .k-i-expand,
  .k-state-hover > .k-rtl .km-pane-wrapper .k-plus,
  .k-state-hover > .k-rtl .km-pane-wrapper .k-plus-disabled,
  .k-state-hover > * > .km-pane-wrapper .k-i-expand-w,
  .k-state-hover > * > .k-rtl .km-pane-wrapper .k-i-expand,
  .k-state-hover > * > .k-rtl .km-pane-wrapper .k-plus,
  .k-state-hover > * > .k-rtl .km-pane-wrapper .k-plus-disabled,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-expand-w,
  .k-button:not(.k-state-disabled):hover .k-rtl .km-pane-wrapper .k-i-expand,
  .k-button:not(.k-state-disabled):hover .k-rtl .km-pane-wrapper .k-plus,
  .k-button:not(.k-state-disabled):hover
    .k-rtl
    .km-pane-wrapper
    .k-plus-disabled,
  .k-textbox:hover .km-pane-wrapper .k-i-expand-w,
  .k-textbox:hover .k-rtl .km-pane-wrapper .k-i-expand,
  .k-textbox:hover .k-rtl .km-pane-wrapper .k-plus,
  .k-textbox:hover .k-rtl .km-pane-wrapper .k-plus-disabled,
  .k-button:active .km-pane-wrapper .k-i-expand-w,
  .k-button:active .k-rtl .km-pane-wrapper .k-i-expand,
  .k-button:active .k-rtl .km-pane-wrapper .k-plus,
  .k-button:active .k-rtl .km-pane-wrapper .k-plus-disabled {
    background-position: -1em -13em;
  }
  .km-pane-wrapper .k-i-collapse,
  .km-pane-wrapper .k-minus,
  .km-pane-wrapper .k-minus-disabled {
    background-position: 0 -14em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-collapse,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-minus,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-minus-disabled,
  .k-state-hover > .km-pane-wrapper .k-i-collapse,
  .k-state-hover > .km-pane-wrapper .k-minus,
  .k-state-hover > .km-pane-wrapper .k-minus-disabled,
  .k-state-hover > * > .km-pane-wrapper .k-i-collapse,
  .k-state-hover > * > .km-pane-wrapper .k-minus,
  .k-state-hover > * > .km-pane-wrapper .k-minus-disabled,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-collapse,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-minus,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-minus-disabled,
  .k-textbox:hover .km-pane-wrapper .k-i-collapse,
  .k-textbox:hover .km-pane-wrapper .k-minus,
  .k-textbox:hover .km-pane-wrapper .k-minus-disabled,
  .k-button:active .km-pane-wrapper .k-i-collapse,
  .k-button:active .km-pane-wrapper .k-minus,
  .k-button:active .km-pane-wrapper .k-minus-disabled {
    background-position: -1em -14em;
  }
  .km-pane-wrapper .k-i-collapse-w,
  .k-rtl .km-pane-wrapper .k-i-collapse,
  .k-rtl .km-pane-wrapper .k-minus,
  .k-rtl .km-pane-wrapper .k-minus-disabled {
    background-position: 0 -15em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-collapse-w,
  .k-link:not(.k-state-disabled):hover > .k-rtl .km-pane-wrapper .k-i-collapse,
  .k-link:not(.k-state-disabled):hover > .k-rtl .km-pane-wrapper .k-minus,
  .k-link:not(.k-state-disabled):hover
    > .k-rtl
    .km-pane-wrapper
    .k-minus-disabled,
  .k-state-hover > .km-pane-wrapper .k-i-collapse-w,
  .k-state-hover > .k-rtl .km-pane-wrapper .k-i-collapse,
  .k-state-hover > .k-rtl .km-pane-wrapper .k-minus,
  .k-state-hover > .k-rtl .km-pane-wrapper .k-minus-disabled,
  .k-state-hover > * > .km-pane-wrapper .k-i-collapse-w,
  .k-state-hover > * > .k-rtl .km-pane-wrapper .k-i-collapse,
  .k-state-hover > * > .k-rtl .km-pane-wrapper .k-minus,
  .k-state-hover > * > .k-rtl .km-pane-wrapper .k-minus-disabled,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-collapse-w,
  .k-button:not(.k-state-disabled):hover .k-rtl .km-pane-wrapper .k-i-collapse,
  .k-button:not(.k-state-disabled):hover .k-rtl .km-pane-wrapper .k-minus,
  .k-button:not(.k-state-disabled):hover
    .k-rtl
    .km-pane-wrapper
    .k-minus-disabled,
  .k-textbox:hover .km-pane-wrapper .k-i-collapse-w,
  .k-textbox:hover .k-rtl .km-pane-wrapper .k-i-collapse,
  .k-textbox:hover .k-rtl .km-pane-wrapper .k-minus,
  .k-textbox:hover .k-rtl .km-pane-wrapper .k-minus-disabled,
  .k-button:active .km-pane-wrapper .k-i-collapse-w,
  .k-button:active .k-rtl .km-pane-wrapper .k-i-collapse,
  .k-button:active .k-rtl .km-pane-wrapper .k-minus,
  .k-button:active .k-rtl .km-pane-wrapper .k-minus-disabled {
    background-position: -1em -15em;
  }
  .km-pane-wrapper .k-i-pencil,
  .km-pane-wrapper .k-edit {
    background-position: -2em 0;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-pencil,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-edit,
  .k-state-hover > .km-pane-wrapper .k-i-pencil,
  .k-state-hover > .km-pane-wrapper .k-edit,
  .k-state-hover > * > .km-pane-wrapper .k-i-pencil,
  .k-state-hover > * > .km-pane-wrapper .k-edit,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-pencil,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-edit,
  .k-textbox:hover .km-pane-wrapper .k-i-pencil,
  .k-textbox:hover .km-pane-wrapper .k-edit,
  .k-button:active .km-pane-wrapper .k-i-pencil,
  .k-button:active .km-pane-wrapper .k-edit {
    background-position: -3em 0;
  }
  .km-pane-wrapper .k-i-close,
  .km-pane-wrapper .k-delete,
  .km-pane-wrapper .k-group-delete {
    background-position: -2em -1em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-close,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-delete,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-group-delete,
  .k-state-hover > .km-pane-wrapper .k-i-close,
  .k-state-hover > .km-pane-wrapper .k-delete,
  .k-state-hover > .km-pane-wrapper .k-group-delete,
  .k-state-hover > * > .km-pane-wrapper .k-i-close,
  .k-state-hover > * > .km-pane-wrapper .k-delete,
  .k-state-hover > * > .km-pane-wrapper .k-group-delete,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-close,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-delete,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-group-delete,
  .k-textbox:hover .km-pane-wrapper .k-i-close,
  .k-textbox:hover .km-pane-wrapper .k-delete,
  .k-textbox:hover .km-pane-wrapper .k-group-delete,
  .k-button:active .km-pane-wrapper .k-i-close,
  .k-button:active .km-pane-wrapper .k-delete,
  .k-button:active .km-pane-wrapper .k-group-delete {
    background-position: -3em -1em;
  }
  .km-pane-wrapper .k-si-close {
    background-position: -10em -5em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-si-close,
  .k-state-hover > .km-pane-wrapper .k-si-close,
  .k-state-hover > * > .km-pane-wrapper .k-si-close,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-si-close,
  .k-textbox:hover .km-pane-wrapper .k-si-close,
  .k-button:active .km-pane-wrapper .k-si-close {
    background-position: -11em -5em;
  }
  .km-pane-wrapper .k-multiselect .k-delete {
    background-position: -10em -5em;
  }
  .km-pane-wrapper .k-multiselect .k-state-hover .k-delete {
    background-position: -11em -5em;
  }
  .km-pane-wrapper .k-i-tick,
  .km-pane-wrapper .k-insert,
  .km-pane-wrapper .k-update {
    background-position: -2em -2em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-tick,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-insert,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-update,
  .k-state-hover > .km-pane-wrapper .k-i-tick,
  .k-state-hover > .km-pane-wrapper .k-insert,
  .k-state-hover > .km-pane-wrapper .k-update,
  .k-state-hover > * > .km-pane-wrapper .k-i-tick,
  .k-state-hover > * > .km-pane-wrapper .k-insert,
  .k-state-hover > * > .km-pane-wrapper .k-update,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-tick,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-insert,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-update,
  .k-textbox:hover .km-pane-wrapper .k-i-tick,
  .k-textbox:hover .km-pane-wrapper .k-insert,
  .k-textbox:hover .km-pane-wrapper .k-update,
  .k-button:active .km-pane-wrapper .k-i-tick,
  .k-button:active .km-pane-wrapper .k-insert,
  .k-button:active .km-pane-wrapper .k-update {
    background-position: -3em -2em;
  }
  .km-pane-wrapper .k-check:checked,
  .km-pane-wrapper .k-mobile-list .k-edit-field [type="checkbox"],
  .km-pane-wrapper .k-mobile-list .k-edit-field [type="radio"] {
    background-position: -2em -2em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-check:checked,
  .k-link:not(.k-state-disabled):hover
    > .km-pane-wrapper
    .k-mobile-list
    .k-edit-field
    [type="checkbox"],
  .k-link:not(.k-state-disabled):hover
    > .km-pane-wrapper
    .k-mobile-list
    .k-edit-field
    [type="radio"],
  .k-state-hover > .km-pane-wrapper .k-check:checked,
  .k-state-hover
    > .km-pane-wrapper
    .k-mobile-list
    .k-edit-field
    [type="checkbox"],
  .k-state-hover > .km-pane-wrapper .k-mobile-list .k-edit-field [type="radio"],
  .k-state-hover > * > .km-pane-wrapper .k-check:checked,
  .k-state-hover
    > *
    > .km-pane-wrapper
    .k-mobile-list
    .k-edit-field
    [type="checkbox"],
  .k-state-hover
    > *
    > .km-pane-wrapper
    .k-mobile-list
    .k-edit-field
    [type="radio"],
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-check:checked,
  .k-button:not(.k-state-disabled):hover
    .km-pane-wrapper
    .k-mobile-list
    .k-edit-field
    [type="checkbox"],
  .k-button:not(.k-state-disabled):hover
    .km-pane-wrapper
    .k-mobile-list
    .k-edit-field
    [type="radio"],
  .k-textbox:hover .km-pane-wrapper .k-check:checked,
  .k-textbox:hover
    .km-pane-wrapper
    .k-mobile-list
    .k-edit-field
    [type="checkbox"],
  .k-textbox:hover .km-pane-wrapper .k-mobile-list .k-edit-field [type="radio"],
  .k-button:active .km-pane-wrapper .k-check:checked,
  .k-button:active
    .km-pane-wrapper
    .k-mobile-list
    .k-edit-field
    [type="checkbox"],
  .k-button:active
    .km-pane-wrapper
    .k-mobile-list
    .k-edit-field
    [type="radio"] {
    background-position: -3em -2em;
  }
  .km-pane-wrapper .k-i-cancel,
  .km-pane-wrapper .k-cancel,
  .km-pane-wrapper .k-denied {
    background-position: -2em -3em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-cancel,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-cancel,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-denied,
  .k-state-hover > .km-pane-wrapper .k-i-cancel,
  .k-state-hover > .km-pane-wrapper .k-cancel,
  .k-state-hover > .km-pane-wrapper .k-denied,
  .k-state-hover > * > .km-pane-wrapper .k-i-cancel,
  .k-state-hover > * > .km-pane-wrapper .k-cancel,
  .k-state-hover > * > .km-pane-wrapper .k-denied,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-cancel,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-cancel,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-denied,
  .k-textbox:hover .km-pane-wrapper .k-i-cancel,
  .k-textbox:hover .km-pane-wrapper .k-cancel,
  .k-textbox:hover .km-pane-wrapper .k-denied,
  .k-button:active .km-pane-wrapper .k-i-cancel,
  .k-button:active .km-pane-wrapper .k-cancel,
  .k-button:active .km-pane-wrapper .k-denied {
    background-position: -3em -3em;
  }
  .km-pane-wrapper .k-i-plus,
  .km-pane-wrapper .k-add {
    background-position: -2em -4em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-plus,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-add,
  .k-state-hover > .km-pane-wrapper .k-i-plus,
  .k-state-hover > .km-pane-wrapper .k-add,
  .k-state-hover > * > .km-pane-wrapper .k-i-plus,
  .k-state-hover > * > .km-pane-wrapper .k-add,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-plus,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-add,
  .k-textbox:hover .km-pane-wrapper .k-i-plus,
  .k-textbox:hover .km-pane-wrapper .k-add,
  .k-button:active .km-pane-wrapper .k-i-plus,
  .k-button:active .km-pane-wrapper .k-add {
    background-position: -3em -4em;
  }
  .km-pane-wrapper .k-i-funnel,
  .km-pane-wrapper .k-filter {
    background-position: -2em -5em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-funnel,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-filter,
  .k-state-hover > .km-pane-wrapper .k-i-funnel,
  .k-state-hover > .km-pane-wrapper .k-filter,
  .k-state-hover > * > .km-pane-wrapper .k-i-funnel,
  .k-state-hover > * > .km-pane-wrapper .k-filter,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-funnel,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-filter,
  .k-textbox:hover .km-pane-wrapper .k-i-funnel,
  .k-textbox:hover .km-pane-wrapper .k-filter,
  .k-button:active .km-pane-wrapper .k-i-funnel,
  .k-button:active .km-pane-wrapper .k-filter {
    background-position: -3em -5em;
  }
  .km-pane-wrapper .k-i-funnel-clear,
  .km-pane-wrapper .k-clear-filter {
    background-position: -2em -6em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-funnel-clear,
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-clear-filter,
  .k-state-hover > .km-pane-wrapper .k-i-funnel-clear,
  .k-state-hover > .km-pane-wrapper .k-clear-filter,
  .k-state-hover > * > .km-pane-wrapper .k-i-funnel-clear,
  .k-state-hover > * > .km-pane-wrapper .k-clear-filter,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-funnel-clear,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-clear-filter,
  .k-textbox:hover .km-pane-wrapper .k-i-funnel-clear,
  .k-textbox:hover .km-pane-wrapper .k-clear-filter,
  .k-button:active .km-pane-wrapper .k-i-funnel-clear,
  .k-button:active .km-pane-wrapper .k-clear-filter {
    background-position: -3em -6em;
  }
  .km-pane-wrapper .k-i-refresh {
    background-position: -2em -7em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-refresh,
  .k-state-hover > .km-pane-wrapper .k-i-refresh,
  .k-state-hover > * > .km-pane-wrapper .k-i-refresh,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-refresh,
  .k-textbox:hover .km-pane-wrapper .k-i-refresh,
  .k-button:active .km-pane-wrapper .k-i-refresh {
    background-position: -3em -7em;
  }
  .km-pane-wrapper .k-i-exception {
    background-position: -10em -19em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-exception,
  .k-state-hover > .km-pane-wrapper .k-i-exception,
  .k-state-hover > * > .km-pane-wrapper .k-i-exception,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-exception,
  .k-textbox:hover .km-pane-wrapper .k-i-exception,
  .k-button:active .km-pane-wrapper .k-i-exception {
    background-position: -11em -19em;
  }
  .km-pane-wrapper .k-i-restore {
    background-position: -2em -8em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-restore,
  .k-state-hover > .km-pane-wrapper .k-i-restore,
  .k-state-hover > * > .km-pane-wrapper .k-i-restore,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-restore,
  .k-textbox:hover .km-pane-wrapper .k-i-restore,
  .k-button:active .km-pane-wrapper .k-i-restore {
    background-position: -3em -8em;
  }
  .km-pane-wrapper .k-i-maximize {
    background-position: -2em -9em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-maximize,
  .k-state-hover > .km-pane-wrapper .k-i-maximize,
  .k-state-hover > * > .km-pane-wrapper .k-i-maximize,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-maximize,
  .k-textbox:hover .km-pane-wrapper .k-i-maximize,
  .k-button:active .km-pane-wrapper .k-i-maximize {
    background-position: -3em -9em;
  }
  .km-pane-wrapper .k-i-minimize {
    background-position: -4em -18em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-minimize,
  .k-state-hover > .km-pane-wrapper .k-i-minimize,
  .k-state-hover > * > .km-pane-wrapper .k-i-minimize,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-minimize,
  .k-textbox:hover .km-pane-wrapper .k-i-minimize,
  .k-button:active .km-pane-wrapper .k-i-minimize {
    background-position: -5em -18em;
  }
  .km-pane-wrapper .k-i-pin {
    background-position: -10em -16em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-pin,
  .k-state-hover > .km-pane-wrapper .k-i-pin,
  .k-state-hover > * > .km-pane-wrapper .k-i-pin,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-pin,
  .k-textbox:hover .km-pane-wrapper .k-i-pin,
  .k-button:active .km-pane-wrapper .k-i-pin {
    background-position: -11em -16em;
  }
  .km-pane-wrapper .k-i-unpin {
    background-position: -10em -17em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-unpin,
  .k-state-hover > .km-pane-wrapper .k-i-unpin,
  .k-state-hover > * > .km-pane-wrapper .k-i-unpin,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-unpin,
  .k-textbox:hover .km-pane-wrapper .k-i-unpin,
  .k-button:active .km-pane-wrapper .k-i-unpin {
    background-position: -11em -17em;
  }
  .km-pane-wrapper .k-resize-se {
    background-position: -2em -10em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-resize-se,
  .k-state-hover > .km-pane-wrapper .k-resize-se,
  .k-state-hover > * > .km-pane-wrapper .k-resize-se,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-resize-se,
  .k-textbox:hover .km-pane-wrapper .k-resize-se,
  .k-button:active .km-pane-wrapper .k-resize-se {
    background-position: -3em -10em;
  }
  .km-pane-wrapper .k-i-calendar {
    background-position: -2em -11em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-calendar,
  .k-state-hover > .km-pane-wrapper .k-i-calendar,
  .k-state-hover > * > .km-pane-wrapper .k-i-calendar,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-calendar,
  .k-textbox:hover .km-pane-wrapper .k-i-calendar,
  .k-button:active .km-pane-wrapper .k-i-calendar {
    background-position: -3em -11em;
  }
  .km-pane-wrapper .k-i-clock {
    background-position: -2em -12em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-i-clock,
  .k-state-hover > .km-pane-wrapper .k-i-clock,
  .k-state-hover > * > .km-pane-wrapper .k-i-clock,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-i-clock,
  .k-textbox:hover .km-pane-wrapper .k-i-clock,
  .k-button:active .km-pane-wrapper .k-i-clock {
    background-position: -3em -12em;
  }
  .km-pane-wrapper .k-si-plus {
    background-position: -2em -13em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-si-plus,
  .k-state-hover > .km-pane-wrapper .k-si-plus,
  .k-state-hover > * > .km-pane-wrapper .k-si-plus,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-si-plus,
  .k-textbox:hover .km-pane-wrapper .k-si-plus,
  .k-button:active .km-pane-wrapper .k-si-plus {
    background-position: -3em -13em;
  }
  .km-pane-wrapper .k-si-minus {
    background-position: -2em -14em;
  }
  .k-link:not(.k-state-disabled):hover > .km-pane-wrapper .k-si-minus,
  .k-state-hover > .km-pane-wrapper .k-si-minus,
  .k-state-hover > * > .km-pane-wrapper .k-si-minus,
  .k-button:not(.k-state-disabled):hover .km-pane-wrapper .k-si-minus,
  .k-textbox:hover .km-pane-wrapper .k-si-minus,
  .k-button:active .km-pane-wrapper .k-si-minus {
    background-position: -3em -14em;
  }
}
.km-pane-wrapper
  .km-pane
  .k-mobile-list
  input:not([type="checkbox"]):not([type="radio"]),
.km-pane-wrapper .km-pane .k-mobile-list select:not([multiple]),
.km-pane-wrapper .km-pane .k-mobile-list textarea,
.km-pane-wrapper .k-mobile-list .k-widget,
.km-pane-wrapper
  .k-edit-field
  > *:not([type="checkbox"]):not([type="radio"]):not(.k-button) {
  font-size: 1em;
  line-height: 1.6em;
  height: auto;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  text-indent: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.km-pane-wrapper .k-mobile-list .k-widget {
  border: 0;
  border-radius: 0;
}
.k-ie .km-pane-wrapper .k-mobile-list .k-widget {
  height: initial;
}
.km-pane-wrapper .k-mobile-list .k-widget .k-input,
.km-pane-wrapper .k-mobile-list .k-widget .k-state-default {
  border: 0;
  background: transparent;
}
.km-pane-wrapper
  *:not(.k-state-default)
  > input:not([type="checkbox"]):not([type="radio"]),
.km-pane-wrapper .k-mobile-list select:not([multiple]),
.km-pane-wrapper .k-mobile-list textarea,
.km-pane-wrapper .k-mobile-list .k-widget,
.km-pane-wrapper
  .k-edit-field
  > *:not([type="checkbox"]):not([type="radio"]):not(.k-button) {
  width: 80%;
  margin: -0.5em 0;
  padding: 0.6em 0;
}
.km-pane-wrapper .km-pane .k-mobile-list input,
.km-pane-wrapper .km-pane .k-mobile-list select:not([multiple]),
.km-pane-wrapper .km-pane .k-mobile-list textarea,
.km-pane-wrapper .k-mobile-list .k-widget,
.km-pane-wrapper .k-mobile-list .k-edit-field > * {
  position: relative;
  z-index: 1;
  float: right;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.km-pane-wrapper .k-scheduler-views {
  width: 18em;
}
.km-pane-wrapper .k-mobile-list .k-edit-field.k-scheduler-toolbar {
  padding: 0.5em 1em;
  border: 0;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.km-pane-wrapper #recurrence .k-scheduler-navigation {
  width: 100%;
}
.km-pane-wrapper .k-scheduler-views,
.km-pane-wrapper .k-mobile-list .k-scheduler-navigation {
  display: table;
  table-layout: fixed;
}
.km-pane-wrapper .k-scheduler-views li,
.km-pane-wrapper .k-mobile-list .k-scheduler-navigation li {
  display: table-cell;
  text-align: center;
}
.km-pane-wrapper .k-scheduler-views li a,
.km-pane-wrapper .k-mobile-list .k-scheduler-navigation li a {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding-left: 1em;
}
.km-pane-wrapper
  .k-mobile-list
  .k-recur-view
  > .k-edit-field
  .k-check:first-child {
  margin-top: -0.5em;
}
.km-pane-wrapper
  .k-mobile-list
  .k-recur-view
  > .k-edit-field
  .k-check:last-child {
  margin-bottom: -0.5em;
}
.km-pane-wrapper
  .k-mobile-list
  .k-scheduler-timezones
  .k-edit-field
  label.k-check {
  text-indent: 1em;
}
.km-pane-wrapper .k-mobile-list .k-edit-field > .k-button {
  float: left;

  margin-left: 20%;
}
.km-pane-wrapper .k-mobile-list .k-picker-wrap,
.km-pane-wrapper .k-mobile-list .k-numeric-wrap,
.km-pane-wrapper .k-mobile-list .k-dropdown-wrap {
  position: static;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.km-pane-wrapper .k-mobile-list .k-datepicker .k-select,
.km-pane-wrapper .k-mobile-list .k-datetimepicker .k-select,
.km-pane-wrapper .k-mobile-list .k-numerictextbox .k-select {
  line-height: auto;

  position: absolute;
  top: 0;
  right: 0;
}
.km-pane-wrapper .k-mobile-list .k-datepicker .k-select:before,
.km-pane-wrapper .k-mobile-list .k-datetimepicker .k-select:before {
  display: inline-block;
  width: 0;
  height: 100%;

  content: "\a0";
  vertical-align: middle;
}
.km-pane-wrapper .k-mobile-list .k-numerictextbox .k-link {
  height: 50%;
}
.km-pane-wrapper .k-grid .k-button,
.km-pane-wrapper .k-edit-form-container .k-button {
  margin: 0;
}
.km-pane-wrapper .k-grid .k-button + .k-button,
.km-pane-wrapper .k-edit-form-container .k-button + .k-button {
  margin: 0 0 0 0.18em;
}
.km-pane-wrapper .k-pager-numbers .k-link,
.km-pane-wrapper .k-pager-numbers .k-state-selected,
.km-pane-wrapper .k-pager-wrap > .k-link {
  line-height: 2.1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 2.4em;
  height: 2.4em;
  border-radius: 2em;
}
.km-pane-wrapper .k-pager-numbers .k-link,
.km-pane-wrapper .k-pager-numbers .k-state-selected {
  line-height: 2.2em;

  width: auto;
  min-width: 0.7em;
  padding: 0 0.86em;
}
.km-pane-wrapper .k-pager-wrap {
  line-height: 2.4em;
}
@media all and (max-width: 699px),
  (-ms-high-contrast: active) and (-ms-high-contrast: none) and (max-width: 800px) {
  .km-pane-wrapper
    *:not(.k-state-default)
    > input:not([type="checkbox"]):not([type="radio"]),
  .km-pane-wrapper .k-mobile-list select:not([multiple]),
  .km-pane-wrapper .k-mobile-list textarea,
  .km-pane-wrapper .k-mobile-list .k-widget,
  .km-pane-wrapper
    .k-edit-field
    > *:not([type="checkbox"]):not([type="radio"]):not(.k-button) {
    width: 50%;
  }
  .km-pane-wrapper .k-mobile-list .k-edit-field > .k-button {
    margin-left: 50%;
  }
  .km-pane-wrapper .k-mobile-list .k-edit-field > .k-timezone-button {
    margin-left: 1em;
  }
  .km-pane-wrapper .k-scheduler-views {
    width: 15em;
  }
  .km-pane-wrapper .k-nav-today a {
    padding-right: 0.6em;
    padding-left: 0.6em;
  }
  .km-pane-wrapper li.k-nav-current {
    margin-right: 0;
    margin-left: 0;
  }
  .km-pane-wrapper .k-pager-wrap {
    position: relative;
  }
  .km-pane-wrapper .k-pager-numbers {
    display: block;
    float: none;
    overflow: hidden;

    width: auto;
    height: 2.4em;
    margin-right: 5.5em;
    text-align: center;
    text-overflow: ellipsis;
  }
  .km-pane-wrapper .k-pager-numbers li {
    display: inline-block;
    float: none;
  }
  .km-pane-wrapper .k-pager-nav {
    float: left;
  }
  .km-pane-wrapper .k-pager-nav + .k-pager-nav ~ .k-pager-nav {
    position: absolute;
    top: 0.3em;
    right: 0.3em;
  }
  .km-pane-wrapper .k-pager-wrap .k-pager-numbers + .k-pager-nav,
  .km-pane-wrapper .k-pager-nav:first-child + .k-pager-nav + .k-pager-nav {
    right: 3em;
  }
  .km-pane-wrapper .k-pager-info {
    display: none;
  }
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check,
.km-pane-wrapper .k-mobile-list .k-edit-field > * > select:not([multiple]),
.km-pane-wrapper
  .k-mobile-list
  .k-scheduler-timezones
  .k-edit-field
  label.k-check {
  width: 100%;
}
.km-scroll-container {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: translatez(0);

  -khtml-user-select: none;
  -webkit-margin-collapse: separate;
}
.k-widget .km-scroll-wrapper {
  position: relative;
  padding-bottom: 0;
}
.km-touch-scrollbar {
  position: absolute;
  z-index: 200000;
  visibility: hidden;
  width: 0.3em;
  height: 0.3em;
  -webkit-transition: opacity 0.3s linear;
  -webkit-transition: "opacity 0.3s linear";
  -moz-transition: opacity 0.3s linear;
  -moz-transition: "opacity 0.3s linear";
  -ms-transition: "opacity 0.3s linear";
  -o-transition: opacity 0.3s linear;
  -o-transition: "opacity 0.3s linear";
  transition: opacity 0.3s linear;
  transition: "opacity 0.3s linear";
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.km-vertical-scrollbar {
  top: 2px;
  right: 2px;

  height: 100%;
}
.km-horizontal-scrollbar {
  bottom: 2px;
  left: 2px;

  width: 100%;
}
.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
  -webkit-transition: all 350ms ease-out;
  -moz-transition: all 350ms ease-out;
  -ms-transition: all 350ms ease-out;
  -o-transition: all 350ms ease-out;
  transition: all 350ms ease-out;
}
.k-fx {
  position: relative;
}
.k-fx .k-fx-current {
  z-index: 0;
}
.k-fx .k-fx-next {
  z-index: 1;
}
.k-fx-hidden,
.k-fx-hidden * {
  visibility: hidden !important;
}
.k-fx-hidden .km-view-title {
  visibility: visible !important;
  opacity: 0;
}
.k-fx-reverse .k-fx-current {
  z-index: 1;
}
.k-fx-reverse .k-fx-next {
  z-index: 0;
}
.k-fx-zoom.k-fx-start .k-fx-next {
  -webkit-transform: scale(
    0
  ) !important                                                                                                                                     ;
  -moz-transform: scale(0) !important;
  -ms-transform: scale(0) !important;
  -o-transform: scale(0) !important;
  transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
  -webkit-transform: scale(
    1
  ) !important                                                                                                                                     ;
  -moz-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  -o-transform: scale(1) !important;
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next,
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: scale(
    1
  ) !important                                                                                                                                     ;
  -moz-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  -o-transform: scale(1) !important;
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: scale(
    1
  ) !important                                                                                                                                     ;
  -moz-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  -o-transform: scale(1) !important;
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: scale(
    0
  ) !important                                                                                                                                     ;
  -moz-transform: scale(0) !important;
  -ms-transform: scale(0) !important;
  -o-transform: scale(0) !important;
  transform: scale(0) !important;
}
.k-fx-fade.k-fx-start .k-fx-next {
  opacity: 0;

  will-change: opacity;
}
.k-fx-fade.k-fx-end .k-fx-next {
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
  opacity: 1;

  will-change: opacity;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
  opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-next .km-content,
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer,
.k-fx-slide.k-fx-end .k-fx-current .km-content,
.k-fx-slide.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-end .k-fx-current .km-footer {
  -webkit-transition: all 350ms ease-out;
  -moz-transition: all 350ms ease-out;
  -ms-transition: all 350ms ease-out;
  -o-transition: all 350ms ease-out;
  transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);

  will-change: transform;
}
.k-fx-slide.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-start .k-fx-next .km-footer {
  opacity: 0;

  will-change: opacity;
}
.k-fx-slide.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  -webkit-transform: translatex(0);
  -moz-transform: translatex(0);
  -ms-transform: translatex(0);
  -o-transform: translatex(0);
  transform: translatex(0);

  will-change: transform;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  -webkit-transform: translatex(0);
  -moz-transform: translatex(0);
  -ms-transform: translatex(0);
  -o-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
  opacity: 1;

  will-change: opacity;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
  opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  -webkit-transform: translatex(0);
  -moz-transform: translatex(0);
  -ms-transform: translatex(0);
  -o-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  -webkit-transform: translatex(0%);
  -moz-transform: translatex(0%);
  -ms-transform: translatex(0%);
  -o-transform: translatex(0%);
  transform: translatex(0%);
}
.k-fx-tile.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);

  will-change: transform;
}
.k-fx-tile.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
  -moz-transform: translatex(0);
  -ms-transform: translatex(0);
  -o-transform: translatex(0);
  transform: translatex(0);

  will-change: transform;
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
  -moz-transform: translatex(0);
  -ms-transform: translatex(0);
  -o-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
  -moz-transform: translatex(0);
  -ms-transform: translatex(0);
  -o-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0%);
  -moz-transform: translatex(0%);
  -ms-transform: translatex(0%);
  -o-transform: translatex(0%);
  transform: translatex(0%);
}
.k-fx-tile.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);

  will-change: transform;
}
.k-fx-tile.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
  -moz-transform: translatex(0);
  -ms-transform: translatex(0);
  -o-transform: translatex(0);
  transform: translatex(0);

  will-change: transform;
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
  -moz-transform: translatex(0);
  -ms-transform: translatex(0);
  -o-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
  -moz-transform: translatex(0);
  -ms-transform: translatex(0);
  -o-transform: translatex(0);
  transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0%);
  -moz-transform: translatex(0%);
  -ms-transform: translatex(0%);
  -o-transform: translatex(0%);
  transform: translatex(0%);
}
.k-fx.k-fx-overlay.k-fx-start .k-fx-next,
.k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);

  will-change: transform;
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
  -webkit-transform: translatey(100%);
  -moz-transform: translatey(100%);
  -ms-transform: translatey(100%);
  -o-transform: translatey(100%);
  transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
  -webkit-transform: translatey(-100%);
  -moz-transform: translatey(-100%);
  -ms-transform: translatey(-100%);
  -o-transform: translatey(-100%);
  transform: translatey(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;

  will-change: transform;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current,
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
  -moz-transform: translatex(100%);
  -ms-transform: translatex(100%);
  -o-transform: translatex(100%);
  transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
  -moz-transform: translatex(-100%);
  -ms-transform: translatex(-100%);
  -o-transform: translatex(-100%);
  transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
  -webkit-transform: translatey(100%);
  -moz-transform: translatey(100%);
  -ms-transform: translatey(100%);
  -o-transform: translatey(100%);
  transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
  -webkit-transform: translatey(-100%);
  -moz-transform: translatey(-100%);
  -ms-transform: translatey(-100%);
  -o-transform: translatey(-100%);
  transform: translatey(-100%);
}

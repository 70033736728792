/* You can add global styles to this file, and also import other style files */
@import "@angular/cdk/overlay-prebuilt.css";
@import "@ng-select/ng-select/themes/default.theme.css";

@import "./assets/css/bootstrapValidator.css";
@import "./assets/css/bootstrap.css";
@import "./assets/css/bootstrap-modal-bs3patch.css";
@import "./assets/css/bootstrap-modal.css";
@import "./assets/css/beachstrap-styles.css";
@import "./assets/css/magicsuggest-1.3.1-min.css";
@import "./assets/css/libs/normalize.css";

/* This library causes problems with page height */
/*@import "./assets/css/libs/codrops.css";*/

@import "./assets/css/libs/telerik/kendo.common.min.css";
@import "./assets/css/libs/telerik/kendo.default.min.css";
@import "./assets/css/libs/telerik/kendo.dataviz.default.min.css";
@import "./assets/css/libs/telerik/kendo.dataviz.min.css";

@import "./assets/font-awesome/css/font-awesome.css";

@import "./assets/css/towline.css";
@import "./assets/css/override.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

/* allow full screen scrolling for dialog modal */
.register-dialog,
.ftp-hosts-dialog {
  overflow-y: scroll;
}

/* use cdk-dialog-container to set width if using full screen scrolling */
/* and set width at 100% when creating the dialog */
.ftp-hosts-dialog .cdk-dialog-container {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

/* reposition dialog content */
.register-dialog .cdk-dialog-container {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.sent-register-dialog .cdk-dialog-container {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.register-dialog form {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.settings-select {
  width: 100%;
  height: 34px;
}

.settings-select-river {
  width: 100px;
  height: 34px;
}

.settings-select-output {
  width: 300px;
  height: 34px;
}

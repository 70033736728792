/*
* Kendo UI v2014.3.1119 (http://www.telerik.com/kendo-ui)
* Copyright 2014 Telerik AD. All rights reserved.
*
* Kendo UI commercial licenses may be obtained at
* http://www.telerik.com/purchase/license-agreement/kendo-ui-complete
* If you do not own a commercial license, this file shall be governed by the trial license terms.
*/
.k-in,
.k-item,
.k-window-action {
  border-color: transparent;
}
.k-block,
.k-widget {
  background-color: #fff;
}
.k-block,
.k-widget,
.k-input,
.k-textbox,
.k-group,
.k-content,
.k-header,
.k-filter-row > th,
.k-editable-area,
.k-separator,
.k-colorpicker .k-i-arrow-s,
.k-textbox > input,
.k-autocomplete,
.k-dropdown-wrap,
.k-toolbar,
.k-group-footer td,
.k-grid-footer,
.k-footer-template td,
.k-state-default,
.k-state-default .k-select,
.k-state-disabled,
.k-grid-header,
.k-grid-header-wrap,
.k-grid-header-locked,
.k-grid-footer-locked,
.k-grid-content-locked,
.k-grid td,
.k-grid td.k-state-selected,
.k-grid-footer-wrap,
.k-pager-wrap,
.k-pager-wrap .k-link,
.k-pager-refresh,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-panelbar > .k-item > .k-link,
.k-panel > .k-item > .k-link,
.k-panelbar .k-panel,
.k-panelbar .k-content,
.k-treemap-tile,
.k-calendar th,
.k-slider-track,
.k-splitbar,
.k-dropzone-active,
.k-tiles,
.k-toolbar,
.k-tooltip,
.k-button-group .k-tool,
.k-upload-files {
  border-color: #c5c5c5;
}
.k-group,
.k-toolbar,
.k-grouping-header,
.k-pager-wrap,
.k-group-footer td,
.k-grid-footer,
.k-footer-template td,
.k-widget .k-status,
.k-calendar th,
.k-dropzone-hovered,
.k-widget.k-popup {
  background-color: #ebebeb;
}
.k-grouping-row td,
td.k-group-cell,
.k-resize-handle-inner {
  background-color: #e3e3e3;
}
.k-list-container {
  border-color: #c5c5c5;
  background-color: #e9e9e9;
}
.k-content,
.k-editable-area,
.k-panelbar > li.k-item,
.k-panel > li.k-item,
.k-tiles {
  background-color: #fff;
}
.k-alt,
.k-separator,
.k-resource.k-alt,
.k-pivot-layout > tbody > tr:first-child > td:first-child {
  background-color: #f5f5f5;
}
.k-pivot-rowheaders .k-alt .k-alt,
.k-header.k-alt {
  background-color: #e1e1e1;
}
.k-textbox,
.k-autocomplete.k-header,
.k-dropdown-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active {
  border-color: #c5c5c5;
  background-color: #e9e9e9;
}
.k-textbox > input,
.k-autocomplete .k-input,
.k-dropdown-wrap .k-input,
.k-autocomplete.k-state-focused .k-input,
.k-dropdown-wrap.k-state-focused .k-input,
.k-picker-wrap.k-state-focused .k-input,
.k-numeric-wrap.k-state-focused .k-input {
  border-color: #c5c5c5;
}
input.k-textbox,
textarea.k-textbox,
input.k-textbox:hover,
textarea.k-textbox:hover,
.k-textbox > input {
  background: 0;
}
.k-input,
input.k-textbox,
textarea.k-textbox,
input.k-textbox:hover,
textarea.k-textbox:hover,
.k-textbox > input,
.k-multiselect-wrap {
  color: #2e2e2e;
  background-color: #fff;
}
.k-input[readonly] {
  color: #2e2e2e;
  background-color: #fff;
}
.k-block,
.k-widget,
.k-popup,
.k-content,
.k-toolbar,
.k-dropdown .k-input {
  color: #2e2e2e;
}
.k-inverse {
  color: #fff;
}
.k-block {
  color: #2e2e2e;
}
.k-link:link,
.k-link:visited,
.k-nav-current.k-state-hover .k-link {
  color: #2e2e2e;
}
.k-tabstrip-items .k-link,
.k-panelbar > li > .k-link {
  color: #2e2e2e;
}
.k-header,
.k-treemap-title,
.k-grid-header .k-header > .k-link {
  color: #2e2e2e;
}
.k-header,
.k-grid-header,
.k-toolbar,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap,
.k-grouping-header,
.k-pager-wrap,
.k-textbox,
.k-button,
.k-progressbar,
.k-draghandle,
.k-autocomplete,
.k-state-highlight,
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item,
.km-pane-wrapper > .km-pane > .km-view > .km-content {
  background-color: #e3e3e3;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.6) 0, rgba(
            255,
            255,
            255,
            0
          )
          100%);
  background-image:
    none,
    -moz-linear-gradient(top, rgba(255, 255, 255, 0.6) 0, rgba(255, 255, 255, 0)
          100%);
  background-image:
    none,
    -o-linear-gradient(top, rgba(255, 255, 255, 0.6) 0, rgba(255, 255, 255, 0)
          100%);
  background-image: none,
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.6) 0,
      rgba(255, 255, 255, 0) 100%
    );
  background-position: 50% 50%;
}
.k-widget.k-tooltip {
  background-image: url("textures/highlight.png");
}
.k-block,
.k-header,
.k-grid-header,
.k-toolbar,
.k-grouping-header,
.k-pager-wrap,
.k-button,
.k-draghandle,
.k-treemap-tile,
html .km-pane-wrapper .k-header {
  background-color: #e3e3e3;
}
.k-icon:hover,
.k-state-hover .k-icon,
.k-state-selected .k-icon,
.k-state-focused .k-icon,
.k-column-menu .k-state-hover .k-sprite,
.k-column-menu .k-state-active .k-sprite {
  opacity: 1;
}
.k-icon,
.k-state-disabled .k-icon,
.k-column-menu .k-sprite {
  opacity: 0.8;
}
.k-mobile-list .k-check:checked,
.k-mobile-list .k-edit-field [type="checkbox"]:checked,
.k-mobile-list .k-edit-field [type="radio"]:checked {
  opacity: 0.8;
}
.k-tool {
  border-color: transparent;
}
.k-icon,
.k-tool-icon,
.k-grouping-dropclue,
.k-drop-hint,
.k-column-menu .k-sprite,
.k-grid-mobile .k-resize-handle-inner:before,
.k-grid-mobile .k-resize-handle-inner:after {
  border-color: transparent;
  background-image: url("Default/sprite.png");
}
.k-mobile-list .k-check:checked,
.k-mobile-list .k-edit-field [type="checkbox"]:checked,
.k-mobile-list .k-edit-field [type="radio"]:checked {
  border-color: transparent;
  background-image: url("Default/sprite.png");
}
.k-loading,
.k-state-hover .k-loading {
  background-image: url("Default/loading.gif");
  background-position: 50% 50%;
}
.k-loading-image {
  background-image: url("Default/loading-image.gif");
}
.k-loading-color {
  background-color: #fff;
}
.k-button {
  color: #2e2e2e;
  border-color: #c5c5c5;
  background-color: #e3e3e3;
}
.k-draghandle {
  border-color: #c5c5c5;
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-draghandle:hover {
  border-color: #bcb4b0;
  background-color: #bcb4b0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-scheduler {
  color: #2e2e2e;
  background-color: #fff;
}
.k-scheduler-layout {
  color: #2e2e2e;
}
.k-scheduler-datecolumn,
.k-scheduler-groupcolumn {
  color: #2e2e2e;
  background-color: #fff;
}
.k-scheduler-times tr,
.k-scheduler-times th,
.k-scheduler-table td,
.k-scheduler-header th,
.k-scheduler-header-wrap,
.k-scheduler-times {
  border-color: #c5c5c5;
}
.k-nonwork-hour {
  background-color: #f2f2f2;
}
.k-gantt .k-nonwork-hour {
  background-color: rgba(0, 0, 0, 0.02);
}
.k-gantt .k-header.k-nonwork-hour {
  background-color: rgba(0, 0, 0, 0.2);
}
.k-scheduler-table .k-today,
.k-today > .k-scheduler-datecolumn,
.k-today > .k-scheduler-groupcolumn {
  background-color: #f8f8f8;
}
.k-scheduler-now-arrow {
  border-left-color: #e20000;
}
.k-scheduler-now-line {
  background-color: #e20000;
}
.k-event,
.k-task-complete {
  color: #2e2e2e;
  border-color: #ffdac1;
  background: #ffdac1 0 -257px url("textures/highlight.png") repeat-x;
}
.k-event-inverse {
  color: #fff;
}
.k-event.k-state-selected {
  background-position: 0 0;
}
.k-ie7 .k-event .k-resize-handle,
.k-event .k-resize-handle:after,
.k-ie7 .k-task-single .k-resize-handle,
.k-task-single .k-resize-handle:after {
  background-color: #bababa;
}
.k-scheduler-marquee:before,
.k-scheduler-marquee:after {
  border-color: #f35800;
}
.k-panelbar .k-content,
.k-panelbar .k-panel,
.k-panelbar .k-item {
  color: #2e2e2e;
  border-color: #c5c5c5;
  background-color: #fff;
}
.k-panelbar > li > .k-link {
  color: #2e2e2e;
}
.k-panelbar > .k-item > .k-link {
  border-color: #c5c5c5;
}
.k-panel > li.k-item {
  background-color: #fff;
}
.k-state-active,
.k-state-active:hover,
.k-active-filter,
.k-tabstrip .k-state-active {
  color: #2e2e2e;
  border-color: #f35800;
  background-color: #fff;
}
.k-fieldselector .k-list-container {
  background-color: #fff;
}
.k-button:focus,
.k-button.k-state-focused {
  border-color: #c5c5c5;
}
.k-button:hover,
.k-button.k-state-hover {
  color: #2e2e2e;
  border-color: #bcb4b0;
  background-color: #bcb4b0;
}
.k-button:active,
.k-button.k-state-active {
  color: #fff;
  border-color: #f35800;
  background-color: #f35800;
}
.k-button:active:hover,
.k-button.k-state-active:hover {
  color: #fff;
  border-color: #ef671a;
  background-color: #ed4300;
}
.k-button[disabled],
.k-button.k-state-disabled,
.k-state-disabled .k-button,
.k-state-disabled .k-button:hover,
.k-button.k-state-disabled:hover,
.k-state-disabled .k-button:active,
.k-button.k-state-disabled:active {
  color: #9f9e9e;
  border-color: #c5c5c5;
  background-color: #e3e3e3;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.6) 0, rgba(
            255,
            255,
            255,
            0
          )
          100%);
  background-image:
    none,
    -moz-linear-gradient(top, rgba(255, 255, 255, 0.6) 0, rgba(255, 255, 255, 0)
          100%);
  background-image:
    none,
    -o-linear-gradient(top, rgba(255, 255, 255, 0.6) 0, rgba(255, 255, 255, 0)
          100%);
  background-image: none,
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.6) 0,
      rgba(255, 255, 255, 0) 100%
    );
}
.k-button:focus:not(.k-state-disabled):not([disabled]) {
  -webkit-box-shadow: inset 0 0 5px 1px #bcb4b0;
  box-shadow: inset 0 0 5px 1px #bcb4b0;
}
.k-button:focus:active:not(.k-state-disabled):not([disabled]) {
  -webkit-box-shadow: inset 0 0 3px 4px #df5100;
  box-shadow: inset 0 0 3px 4px #df5100;
}
.k-menu .k-state-hover > .k-state-active {
  background-color: transparent;
}
.k-state-highlight {
  color: #2e2e2e;
  background: #fff;
}
.k-state-focused,
.k-grouping-row .k-state-focused {
  border-color: #bcb4b0;
}
.k-calendar .k-link {
  color: #2e2e2e;
}
.k-calendar .k-footer {
  padding: 0;
}
.k-calendar .k-footer .k-nav-today {
  text-decoration: none;

  color: #2e2e2e;
  background-color: #fff;
}
.k-calendar .k-footer .k-nav-today:hover,
.k-calendar .k-footer .k-nav-today.k-state-hover {
  text-decoration: underline;

  background-color: #fff;
}
.k-calendar .k-footer .k-nav-today:active {
  background-color: #fff;
}
.k-calendar .k-link.k-nav-fast {
  color: #2e2e2e;
}
.k-calendar .k-nav-fast.k-state-hover {
  text-decoration: none;
  color: #2e2e2e;
  background-color: #bcb4b0;
}
.k-calendar .k-link.k-state-hover,
.k-window-titlebar .k-link {
  border-radius: 4px;
}
.k-calendar .k-footer .k-link {
  border-radius: 0;
}
.k-calendar th {
  background-color: #ebebeb;
}
.k-calendar-container.k-group {
  border-color: #c5c5c5;
}
.k-state-selected,
.k-state-selected:link,
.k-state-selected:visited,
.k-list > .k-state-selected,
.k-list > .k-state-highlight,
.k-panel > .k-state-selected,
.k-ghost-splitbar-vertical,
.k-ghost-splitbar-horizontal,
.k-draghandle.k-state-selected:hover,
.k-scheduler .k-scheduler-toolbar .k-state-selected,
.k-scheduler .k-today.k-state-selected,
.k-marquee-color {
  color: #fff;
  border-color: #f35800;
  background-color: #f35800;
}
.k-marquee-text {
  color: #fff;
}
.k-state-focused,
.k-list > .k-state-focused,
.k-listview > .k-state-focused,
.k-grid-header th.k-state-focused,
td.k-state-focused,
.k-button.k-state-focused {
  -webkit-box-shadow: inset 0 0 5px 1px #bcb4b0;
  box-shadow: inset 0 0 5px 1px #bcb4b0;
}
.k-state-focused.k-state-selected,
.k-list > .k-state-focused.k-state-selected,
.k-listview > .k-state-focused.k-state-selected,
td.k-state-focused.k-state-selected {
  -webkit-box-shadow: inset 0 0 3px 4px #df5100;
  box-shadow: inset 0 0 3px 4px #df5100;
}
.k-ie8 .k-panelbar span.k-state-focused,
.k-ie8 .k-menu li.k-state-focused,
.k-ie8 .k-listview > .k-state-focused,
.k-ie8 .k-grid-header th.k-state-focused,
.k-ie8 td.k-state-focused,
.k-ie8 .k-tool.k-state-hover,
.k-ie8 .k-button:focus,
.k-ie8 .k-button.k-state-focused,
.k-ie7 .k-panelbar span.k-state-focused,
.k-ie7 .k-menu li.k-state-focused,
.k-ie7 .k-listview > .k-state-focused,
.k-ie7 .k-grid-header th.k-state-focused,
.k-ie7 td.k-state-focused,
.k-ie7 .k-tool.k-state-hover,
.k-ie7 .k-button:focus,
.k-ie7 .k-button.k-state-focused {
  background-color: #bcb4b0;
}
.k-list > .k-state-selected.k-state-focused {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-state-selected > .k-link,
.k-panelbar > li > .k-state-selected,
.k-panelbar > li.k-state-default > .k-link.k-state-selected {
  color: #fff;
}
.k-state-hover,
.k-state-hover:hover,
.k-splitbar-horizontal-hover:hover,
.k-splitbar-vertical-hover:hover,
.k-list > .k-state-hover,
.k-scheduler .k-scheduler-toolbar ul li.k-state-hover,
.k-pager-wrap .k-link:hover,
.k-dropdown .k-state-focused,
.k-filebrowser-dropzone,
.k-mobile-list .k-item > .k-link:active,
.k-mobile-list .k-item > .k-label:active,
.k-mobile-list .k-edit-label.k-check:active,
.k-mobile-list .k-recur-view .k-check:active {
  color: #2e2e2e;
  border-color: #bcb4b0;
  background-color: #bcb4b0;
}
.k-mobile-list .k-scheduler-timezones .k-edit-field:nth-child(2):active {
  color: #2e2e2e;
  border-color: #bcb4b0;
  background-color: #bcb4b0;
}
.k-ie7 .k-window-titlebar .k-state-hover,
.k-ie8 .k-window-titlebar .k-state-hover {
  border-color: #bcb4b0;
}
.k-state-hover > .k-select,
.k-state-focused > .k-select {
  border-color: #bcb4b0;
}
.k-button:hover,
.k-button.k-state-hover,
.k-button:focus,
.k-button.k-state-focused,
.k-textbox:hover,
.k-state-hover,
.k-state-hover:hover,
.k-pager-wrap .k-link:hover,
.k-other-month.k-state-hover .k-link,
div.k-filebrowser-dropzone em,
.k-draghandle:hover {
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(
            255,
            255,
            255,
            0
          )
          100%);
  background-image:
    none,
    -moz-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0)
          100%);
  background-image:
    none,
    -o-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0)
          100%);
  background-image: none,
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.4) 0,
      rgba(255, 255, 255, 0) 100%
    );
}
.k-pager-wrap {
  color: #2e2e2e;
  background-color: #e3e3e3;
}
.k-autocomplete.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active,
.k-dropdown-wrap.k-state-active,
.k-state-active,
.k-state-active:hover,
.k-state-active > .k-link,
.k-button:active,
.k-panelbar > .k-item > .k-state-focused {
  background-image: none;
}
.k-state-selected,
.k-button:active,
.k-button.k-state-active,
.k-draghandle.k-state-selected:hover {
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.2) 0, rgba(
            255,
            255,
            255,
            0
          )
          100%);
  background-image:
    none,
    -moz-linear-gradient(top, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0)
          100%);
  background-image:
    none,
    -o-linear-gradient(top, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0)
          100%);
  background-image: none,
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2) 0,
      rgba(255, 255, 255, 0) 100%
    );
}
.k-button:active,
.k-button.k-state-active,
.k-draghandle.k-state-selected:hover {
  background-position: 50% 50%;
}
.k-tool-icon {
  background-image: url("Default/sprite.png");
}
.k-state-hover > .k-link,
.k-other-month.k-state-hover .k-link,
div.k-filebrowser-dropzone em {
  color: #2e2e2e;
}
.k-autocomplete.k-state-hover,
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-hover,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-hover,
.k-dropdown-wrap.k-state-focused {
  border-color: #bcb4b0;
  background-color: #bcb4b0;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(
            255,
            255,
            255,
            0
          )
          100%);
  background-image:
    none,
    -moz-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0)
          100%);
  background-image:
    none,
    -o-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0)
          100%);
  background-image: none,
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.4) 0,
      rgba(255, 255, 255, 0) 100%
    );
  background-position: 50% 50%;
}
.km-pane-wrapper
  .k-mobile-list
  input:not([type="checkbox"]):not([type="radio"]),
.km-pane-wrapper .km-pane .k-mobile-list select:not([multiple]),
.km-pane-wrapper .k-mobile-list textarea,
.k-dropdown .k-state-focused .k-input {
  color: #2e2e2e;
}
.k-dropdown .k-state-hover .k-input {
  color: #2e2e2e;
}
.k-state-error {
  color: #fff;
  border-color: #e20000;
  background-color: #ff5252;
}
.k-state-disabled {
  opacity: 0.7;
}
.k-ie7 .k-state-disabled,
.k-ie8 .k-state-disabled {
  filter: alpha(opacity=70);
}
.k-tile-empty.k-state-selected,
.k-loading-mask.k-state-selected {
  border-width: 0;
  background-color: transparent;
  background-image: none;
}
.k-state-disabled,
.k-state-disabled .k-link,
.k-state-disabled .k-button,
.k-other-month,
.k-other-month .k-link,
.k-dropzone em,
.k-dropzone .k-upload-status,
.k-tile-empty strong,
.k-slider .k-draghandle {
  color: #9f9e9e;
}
.k-progressbar-indeterminate {
  background: url("Default/indeterminate.gif");
}
.k-progressbar-indeterminate .k-progress-status-wrap,
.k-progressbar-indeterminate .k-state-selected {
  display: none;
}
.k-slider-track {
  background-color: #c5c5c5;
}
.k-slider-selection {
  background-color: #f35800;
}
.k-slider-horizontal .k-tick {
  background-image: url("Default/slider-h.gif");
}
.k-slider-vertical .k-tick {
  background-image: url("Default/slider-v.gif");
}
.k-widget.k-tooltip {
  color: #282828;
  border-color: #c5c5c5;
  background-color: #c6c6c6;
}
.k-widget.k-tooltip-validation {
  color: #635145;
  border-color: #ffe79e;
  background-color: #fff4c9;
}
.input-prepend .k-tooltip-validation,
.input-append .k-tooltip-validation {
  font-size: 12px;
  position: relative;
  top: 3px;
}
.k-callout-n {
  border-bottom-color: #c5c5c5;
}
.k-callout-w {
  border-right-color: #c5c5c5;
}
.k-callout-s {
  border-top-color: #c5c5c5;
}
.k-callout-e {
  border-left-color: #c5c5c5;
}
.k-tooltip-validation .k-callout-n {
  border-bottom-color: #ffe79e;
}
.k-tooltip-validation .k-callout-w {
  border-right-color: #ffe79e;
}
.k-tooltip-validation .k-callout-s {
  border-top-color: #ffe79e;
}
.k-tooltip-validation .k-callout-e {
  border-left-color: #ffe79e;
}
.k-splitbar {
  background-color: #e3e3e3;
}
.k-restricted-size-vertical,
.k-restricted-size-horizontal {
  background-color: #fff;
}
.k-file {
  border-color: #c5c5c5;
  background-color: #fff;
}
.k-file-progress {
  color: #06c;
}
.k-file-progress .k-progress {
  background-color: #d0f8ff;
}
.k-file-success {
  color: #28760b;
}
.k-file-success .k-progress {
  background-color: #ddffd0;
}
.k-file-error {
  color: #c00;
}
.k-file-error .k-progress {
  background-color: #ffded7;
}
.k-tile {
  border-color: #fff;
}
.k-textbox:hover,
.k-tiles li.k-state-hover {
  border-color: #bcb4b0;
}
.k-tiles li.k-state-selected {
  border-color: #f35800;
}
.k-tile .k-folder,
.k-tile .k-file {
  background-image: url("Default/imagebrowser.png");
}
.k-leaf,
.k-leaf.k-state-hover:hover {
  color: #fff;
}
.k-leaf.k-inverse,
.k-leaf.k-inverse.k-state-hover:hover {
  color: #000;
}
.k-widget,
.k-button {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-slider,
.k-treeview,
.k-upload {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-state-hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-autocomplete.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused {
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
}
.k-state-selected {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-state-active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-grid td.k-state-selected.k-state-focused {
  background-color: #ff5e03;
}
.k-popup,
.k-menu .k-menu-group,
.k-grid .k-filter-options,
.k-time-popup,
.k-datepicker-calendar,
.k-autocomplete.k-state-border-down,
.k-autocomplete.k-state-border-up,
.k-dropdown-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-multiselect.k-state-focused,
.k-filebrowser .k-image,
.k-tooltip {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
}
.k-treemap-tile.k-state-hover {
  -webkit-box-shadow: inset 0 0 0 3px #c5c5c5;
  box-shadow: inset 0 0 0 3px #c5c5c5;
}
.k-window {
  border-color: rgba(0, 0, 0, 0.3);
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 7px 1px rgba(128, 128, 128, 0.3);
  box-shadow: 1px 1px 7px 1px rgba(128, 128, 128, 0.3);
}
.k-window.k-state-focused {
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.3);
}
.k-window.k-window-maximized,
.k-window-maximized .k-window-titlebar,
.k-window-maximized .k-window-content {
  border-radius: 0;
}
.k-shadow {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
.k-inset {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
}
.k-editor-inline ::selection {
  color: #fff;
  background-color: #f35800;
  text-shadow: none;
}
.k-editor-inline ::-moz-selection {
  color: #fff;
  background-color: #f35800;
  text-shadow: none;
}
.k-notification.k-notification-info {
  color: #0487c4;
  border-color: #b7e1f0;
  background-color: #e5f5fa;
}
.k-notification.k-notification-success {
  color: #349946;
  border-color: #c3e8ca;
  background-color: #eaf7ec;
}
.k-notification.k-notification-warning {
  color: #0487c4;
  border-color: #fff0c8;
  background-color: #fff4d9;
}
.k-notification.k-notification-error {
  color: #ef470a;
  border-color: #ffb7a8;
  background-color: #ffe0d9;
}
.k-gantt .k-treelist {
  background: #f5f5f5;
}
.k-gantt .k-treelist .k-alt {
  background-color: #dcdcdc;
}
.k-gantt .k-treelist .k-state-selected,
.k-gantt .k-treelist .k-state-selected td,
.k-gantt .k-treelist .k-alt.k-state-selected,
.k-gantt .k-treelist .k-alt.k-state-selected > td {
  background-color: #f35800;
}
.k-task-dot:after {
  border-color: #2e2e2e;
  background-color: #2e2e2e;
}
.k-task-dot:hover:after {
  background-color: #fff;
}
.k-task-summary {
  border-color: #6e6e6e;
  background: #6e6e6e;
}
.k-task-milestone,
.k-task-summary-complete {
  border-color: #2e2e2e;
  background: #2e2e2e;
}
.k-state-selected.k-task-summary {
  border-color: #ffa673;
  background: #ffa673;
}
.k-state-selected.k-task-milestone,
.k-state-selected .k-task-summary-complete {
  border-color: #f35800;
  background: #f35800;
}
.k-task-single {
  color: #2e2e2e;
  border-color: #ffdac1;
  background-color: #ffefe5;
}
.k-state-selected.k-task-single {
  border-color: #f35800;
}
.k-line {
  color: #2e2e2e;
  background-color: #2e2e2e;
}
.k-state-selected.k-line {
  color: #f35800;
  background-color: #f35800;
}
.k-resource {
  background-color: #fff;
}
.k-i-kpi-decrease,
.k-i-kpi-denied,
.k-i-kpi-equal,
.k-i-kpi-hold,
.k-i-kpi-increase,
.k-i-kpi-open {
  background-image: url("Default/sprite_kpi.png");
}
.k-block,
.k-button,
.k-textbox,
.k-drag-clue,
.k-touch-scrollbar,
.k-window,
.k-window-titleless .k-window-content,
.k-window-action,
.k-inline-block,
.k-grid .k-filter-options,
.k-grouping-header .k-group-indicator,
.k-autocomplete,
.k-multiselect,
.k-combobox,
.k-dropdown,
.k-dropdown-wrap,
.k-datepicker,
.k-timepicker,
.k-colorpicker,
.k-datetimepicker,
.k-notification,
.k-numerictextbox,
.k-picker-wrap,
.k-numeric-wrap,
.k-colorpicker,
.k-list-container,
.k-calendar-container,
.k-calendar td,
.k-calendar .k-link,
.k-treeview .k-in,
.k-editor-inline,
.k-tooltip,
.k-tile,
.k-slider-track,
.k-slider-selection,
.k-upload {
  border-radius: 4px;
}
.k-tool {
  text-align: center;
  vertical-align: middle;
}
.k-tool.k-group-start,
.k-toolbar .k-split-button .k-button,
.k-toolbar .k-button-group .k-group-start {
  border-radius: 4px 0 0 4px;
}
.k-rtl .k-tool.k-group-start {
  border-radius: 0 4px 4px 0;
}
.k-tool.k-group-end,
.k-toolbar .k-button-group .k-group-end,
.k-toolbar .k-split-button .k-split-button-arrow {
  border-radius: 0 4px 4px 0;
}
.k-rtl .k-tool.k-group-end {
  border-radius: 4px 0 0 4px;
}
.k-group-start.k-group-end.k-tool {
  border-radius: 4px;
}
.k-calendar-container.k-state-border-up,
.k-list-container.k-state-border-up,
.k-autocomplete.k-state-border-up,
.k-multiselect.k-state-border-up,
.k-dropdown-wrap.k-state-border-up,
.k-picker-wrap.k-state-border-up,
.k-numeric-wrap.k-state-border-up,
.k-window-content,
.k-filter-menu {
  border-radius: 0 0 4px 4px;
}
.k-autocomplete.k-state-border-up .k-input,
.k-dropdown-wrap.k-state-border-up .k-input,
.k-picker-wrap.k-state-border-up .k-input,
.k-picker-wrap.k-state-border-up .k-selected-color,
.k-numeric-wrap.k-state-border-up .k-input {
  border-radius: 0 0 0 4px;
}
.k-multiselect.k-state-border-up .k-multiselect-wrap {
  border-radius: 0 0 4px 4px;
}
.k-window-titlebar,
.k-block > .k-header,
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item,
.k-tabstrip-items .k-link,
.k-calendar-container.k-state-border-down,
.k-list-container.k-state-border-down,
.k-autocomplete.k-state-border-down,
.k-multiselect.k-state-border-down,
.k-dropdown-wrap.k-state-border-down,
.k-picker-wrap.k-state-border-down,
.k-numeric-wrap.k-state-border-down {
  border-radius: 4px 4px 0 0;
}
.k-split-button.k-state-border-down > .k-button {
  border-radius: 4px 0 0 0;
}
.k-split-button.k-state-border-up > .k-button {
  border-radius: 0 0 0 4px;
}
.k-split-button.k-state-border-down > .k-split-button-arrow {
  border-radius: 0 4px 0 0;
}
.k-split-button.k-state-border-up > .k-split-button-arrow {
  border-radius: 0 0 4px 0;
}
.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input {
  border-radius: 3px 0 0 3px;
}
.k-rtl .k-dropdown-wrap .k-input,
.k-rtl .k-picker-wrap .k-input,
.k-rtl .k-numeric-wrap .k-input {
  border-radius: 0 3px 3px 0;
}
.k-numeric-wrap .k-link {
  border-radius: 0 3px 0 0;
}
.k-numeric-wrap .k-link + .k-link {
  border-radius: 0 0 3px 0;
}
.k-colorpicker .k-selected-color {
  border-radius: 3px 0 0 3px;
}
.k-rtl .k-colorpicker .k-selected-color {
  border-radius: 0 3px 3px 0;
}
.k-autocomplete.k-state-border-down .k-input {
  border-radius: 4px 4px 0 0;
}
.k-dropdown-wrap.k-state-border-down .k-input,
.k-picker-wrap.k-state-border-down .k-input,
.k-picker-wrap.k-state-border-down .k-selected-color,
.k-numeric-wrap.k-state-border-down .k-input {
  border-radius: 4px 0 0 0;
}
.k-numeric-wrap .k-link.k-state-selected {
  background-color: #f35800;
}
.k-multiselect.k-state-border-down .k-multiselect-wrap {
  border-radius: 3px 3px 0 0;
}
.k-dropdown-wrap .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-datetimepicker .k-select + .k-select,
.k-list-container.k-state-border-right {
  border-radius: 0 4px 4px 0;
}
.k-rtl .k-dropdown-wrap .k-select,
.k-rtl .k-picker-wrap .k-select,
.k-rtl .k-numeric-wrap .k-select,
.k-rtl .k-datetimepicker .k-select + .k-select,
.k-rtl .k-list-container.k-state-border-right {
  border-radius: 4px 0 0 4px;
}
.k-numeric-wrap.k-expand-padding .k-input {
  border-radius: 4px;
}
.k-textbox > input,
.k-autocomplete .k-input,
.k-multiselect-wrap {
  border-radius: 3px;
}
.k-list .k-state-hover,
.k-list .k-state-focused,
.k-list .k-state-highlight,
.k-list .k-state-selected,
.k-fieldselector .k-list .k-item,
.k-dropzone {
  border-radius: 3px;
}
.k-slider .k-button,
.k-grid .k-slider .k-button {
  border-radius: 13px;
}
.k-draghandle {
  border-radius: 7px;
}
.k-scheduler-toolbar > ul li:first-child,
.k-scheduler-toolbar > ul li:first-child .k-link {
  border-radius: 4px 0 0 4px;
}
.k-rtl .k-scheduler-toolbar > ul li:first-child,
.k-rtl .k-scheduler-toolbar > ul li:first-child .k-link,
.km-view.k-popup-edit-form .k-scheduler-toolbar > ul li:last-child,
.km-view.k-popup-edit-form .k-scheduler-toolbar > ul li:last-child .k-link {
  border-radius: 0 4px 4px 0;
}
.k-scheduler-phone .k-scheduler-toolbar > ul li.k-nav-today,
.k-scheduler-phone .k-scheduler-toolbar > ul li.k-nav-today .k-link,
.k-edit-field > .k-scheduler-navigation {
  border-radius: 4px;
}
.k-scheduler-toolbar .k-nav-next,
.k-scheduler-toolbar ul + ul li:last-child,
.k-scheduler-toolbar .k-nav-next .k-link,
.k-scheduler-toolbar ul + ul li:last-child .k-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.k-rtl .k-scheduler-toolbar .k-nav-next,
.k-rtl .k-scheduler-toolbar ul + ul li:last-child,
.k-rtl .k-scheduler-toolbar .k-nav-next .k-link,
.k-rtl .k-scheduler-toolbar ul + ul li:last-child .k-link {
  border-radius: 4px 0 0 4px;
}
.k-scheduler div.k-scheduler-footer ul li,
.k-scheduler div.k-scheduler-footer .k-link {
  border-radius: 4px;
}
.k-more-events,
.k-event,
.k-task-single,
.k-task-complete,
.k-event .k-link {
  border-radius: 3px;
}
.k-scheduler-mobile .k-event {
  border-radius: 2px;
}
.k-grid-mobile .k-column-active + th.k-header {
  border-left-color: #2e2e2e;
}
html .km-pane-wrapper .km-widget,
.k-ie .km-pane-wrapper .k-widget,
.k-ie .km-pane-wrapper .k-group,
.k-ie .km-pane-wrapper .k-content,
.k-ie .km-pane-wrapper .k-header,
.k-ie .km-pane-wrapper .k-popup-edit-form .k-edit-field .k-button,
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-edit-label,
.km-pane-wrapper .k-mobile-list .k-edit-field {
  color: #2e2e2e;
}
@media screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  div.km-pane-wrapper a {
    color: #2e2e2e;
  }
  .km-pane-wrapper .k-icon {
    background-image: url("Default/sprite_2x.png");
    background-size: 21.2em 21em;
  }
}
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check {
  border-top: 1px solid #c5c5c5;
  background-color: #fff;
}
.km-pane-wrapper .k-mobile-list .k-edit-field textarea {
  outline-width: 0;
}
.km-pane-wrapper .k-mobile-list .k-item.k-state-selected {
  border-top-color: #f35800;
  background-color: #f35800;
}
.km-pane-wrapper
  .k-mobile-list
  .k-recur-view
  > .k-edit-field
  .k-check:first-child {
  border-top-color: transparent;
}
.km-pane-wrapper .k-mobile-list .k-item:last-child {
  -webkit-box-shadow: inset 0 -1px 0 #c5c5c5;
  box-shadow: inset 0 -1px 0 #c5c5c5;
}
.km-pane-wrapper .k-mobile-list > ul > li > .k-link,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3),
.km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child {
  color: #797979;
}
.km-pane-wrapper .k-mobile-list > ul > li > .k-link {
  border-bottom: 1px solid #c5c5c5;
}
.km-pane-wrapper .k-mobile-list .k-edit-field {
  -webkit-box-shadow: 0 1px 1px #c5c5c5;
  box-shadow: 0 1px 1px #c5c5c5;
}
.km-actionsheet .k-grid-delete,
.km-actionsheet .k-scheduler-delete,
.km-pane-wrapper .k-scheduler-delete,
.km-pane-wrapper .k-filter-menu .k-button[type="reset"] {
  color: #fff;
  border-color: #e20000;
  background-color: red;
  background-image: -webkit-gradient(
    linear,
    50% 0,
    50% 100%,
    from(rgba(255, 255, 255, 0.3)),
    to(rgba(255, 255, 255, 0.15))
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.15)
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.15)
  );
  background-image: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.15)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.15)
  );
}
.km-actionsheet .k-grid-delete:active,
.km-actionsheet .k-scheduler-delete:active,
.km-pane-wrapper .k-scheduler-delete:active,
.km-pane-wrapper .k-filter-menu .k-button[type="reset"]:active {
  background-color: #900;
}
.k-autocomplete.k-state-default,
.k-picker-wrap.k-state-default,
.k-numeric-wrap.k-state-default,
.k-dropdown-wrap.k-state-default {
  border-color: #c5c5c5;
  background-color: #e9e9e9;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.6) 0, rgba(
            255,
            255,
            255,
            0
          )
          100%);
  background-image:
    none,
    -moz-linear-gradient(top, rgba(255, 255, 255, 0.6) 0, rgba(255, 255, 255, 0)
          100%);
  background-image:
    none,
    -o-linear-gradient(top, rgba(255, 255, 255, 0.6) 0, rgba(255, 255, 255, 0)
          100%);
  background-image: none,
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.6) 0,
      rgba(255, 255, 255, 0) 100%
    );
  background-position: 50% 50%;
}
.k-autocomplete.k-state-hover,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-dropdown-wrap.k-state-hover {
  border-color: #bcb4b0;
  background-color: #bcb4b0;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(
            255,
            255,
            255,
            0
          )
          100%);
  background-image:
    none,
    -moz-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0)
          100%);
  background-image:
    none,
    -o-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0)
          100%);
  background-image: none,
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.4) 0,
      rgba(255, 255, 255, 0) 100%
    );
  background-position: 50% 50%;
}
.k-multiselect.k-header {
  border-color: #c5c5c5;
}
.k-multiselect.k-header.k-state-hover {
  border-color: #bcb4b0;
}
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-multiselect.k-header.k-state-focused {
  border-color: #bcb4b0;
  background-color: #bcb4b0;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(
            255,
            255,
            255,
            0
          )
          100%);
  background-image:
    none,
    -moz-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0)
          100%);
  background-image:
    none,
    -o-linear-gradient(top, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0)
          100%);
  background-image: none,
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.4) 0,
      rgba(255, 255, 255, 0) 100%
    );
  background-position: 50% 50%;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
}
.k-list-container {
  color: #2e2e2e;
}
.k-dropdown .k-input,
.k-dropdown .k-state-focused .k-input,
.k-menu .k-popup {
  color: #2e2e2e;
}
.k-state-default > .k-select {
  border-color: #c5c5c5;
}
.k-state-hover > .k-select {
  border-color: #bcb4b0;
}
.k-state-focused > .k-select {
  border-color: #bcb4b0;
}
.k-tabstrip-items .k-state-default .k-link,
.k-panelbar > li.k-state-default > .k-link {
  color: #2e2e2e;
}
.k-tabstrip-items .k-state-hover .k-link,
.k-panelbar > li.k-state-hover > .k-link,
.k-panelbar > li.k-state-default > .k-link.k-state-hover {
  color: #2e2e2e;
}
.k-panelbar .k-state-focused.k-state-hover {
  background: #bcb4b0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-tabstrip-items .k-state-default,
.k-ie7 .k-tabstrip-items .k-state-default .k-loading {
  border-color: #c5c5c5;
}
.k-tabstrip-items .k-state-hover,
.k-ie7 .k-tabstrip-items .k-state-hover .k-loading {
  border-color: #bcb4b0;
}
.k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-items .k-state-active,
.k-ie7 .k-tabstrip-items .k-state-active .k-loading {
  border-color: #f35800;
  background-color: #fff;
  background-image: none;
}
.k-tabstrip .k-content.k-state-active {
  color: #2e2e2e;
  background-color: #fff;
}
.k-menu.k-header,
.k-menu .k-item {
  border-color: #c5c5c5;
}
.k-column-menu,
.k-column-menu .k-item,
.k-overflow-container .k-overflow-group {
  border-color: #c5c5c5;
}
.k-overflow-container .k-overflow-group {
  box-shadow:
    inset 0 1px 0 #fff,
    0 1px 0 #fff;
}
.k-toolbar-first-visible.k-overflow-group,
.k-overflow-container .k-overflow-group + .k-overflow-group {
  box-shadow: 0 1px 0 #fff;
}
.k-toolbar-last-visible.k-overflow-group {
  box-shadow: inset 0 1px 0 #fff;
}
.k-column-menu .k-separator {
  border-color: #c5c5c5;
  background-color: transparent;
}
.k-menu .k-group {
  border-color: #c5c5c5;
}
.k-grid-filter.k-state-active {
  background-color: #fff;
}
.k-grouping-row td,
.k-group-footer td,
.k-grid-footer td {
  font-weight: bold;

  color: #2e2e2e;
  border-color: #c5c5c5;
}
.k-grouping-header {
  color: #2e2e2e;
}
.k-grid td.k-state-focused {
  -webkit-box-shadow: inset 0 0 0 1px inset 0 0 5px 1px #bcb4b0;
  box-shadow: inset 0 0 0 1px inset 0 0 5px 1px #bcb4b0;
}
.k-header,
.k-grid-header-wrap,
.k-grid .k-grouping-header,
.k-grid-header,
.k-pager-wrap,
.k-pager-wrap .k-textbox,
.k-pager-wrap .k-link,
.k-grouping-header .k-group-indicator,
.k-gantt-toolbar .k-state-default {
  border-color: #c5c5c5;
}
.k-primary,
.k-overflow-container .k-primary {
  color: #fff;
  border-color: #ef671a;
  background-color: #f35800;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, #f6813f 0, #f35a03 100%);
  background-image:
    none,
    -moz-linear-gradient(top, #f6813f 0, #f35a03 100%);
  background-image:
    none,
    -o-linear-gradient(top, #f6813f 0, #f35a03 100%);
  background-image: none, linear-gradient(to bottom, #f6813f 0, #f35a03 100%);
  background-position: 50% 50%;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-primary:focus,
.k-primary.k-state-focused {
  color: #fff;
  border-color: #e84200;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, #f1713f 0, #ed4503 100%);
  background-image:
    none,
    -moz-linear-gradient(top, #f1713f 0, #ed4503 100%);
  background-image:
    none,
    -o-linear-gradient(top, #f1713f 0, #ed4503 100%);
  background-image: none, linear-gradient(to bottom, #f1713f 0, #ed4503 100%);
  -webkit-box-shadow: 0 0 3px 1px #e10000;
  box-shadow: 0 0 3px 1px #e10000;
}
.k-primary:hover {
  color: #fff;
  border-color: #ef671a;
  background-color: #ed4300;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, #f1713f 0, #ed4503 100%);
  background-image:
    none,
    -moz-linear-gradient(top, #f1713f 0, #ed4503 100%);
  background-image:
    none,
    -o-linear-gradient(top, #f1713f 0, #ed4503 100%);
  background-image: none, linear-gradient(to bottom, #f1713f 0, #ed4503 100%);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-primary:focus:active:not(.k-state-disabled):not([disabled]),
.k-primary:focus:not(.k-state-disabled):not([disabled]) {
  -webkit-box-shadow: 0 0 3px 1px #e10000;
  box-shadow: 0 0 3px 1px #e10000;
}
.k-primary:active {
  color: #fff;
  border-color: #ef671a;
  background-color: #f35800;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, #f6813f 0, #f35a03 100%);
  background-image:
    none,
    -moz-linear-gradient(top, #f6813f 0, #f35a03 100%);
  background-image:
    none,
    -o-linear-gradient(top, #f6813f 0, #f35a03 100%);
  background-image: none, linear-gradient(to bottom, #f6813f 0, #f35a03 100%);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-primary.k-state-disabled,
.k-state-disabled .k-primary,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:hover,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:active,
.k-primary.k-state-disabled:active {
  color: #fab58e;
  border-color: #ef671a;
  background-color: #f35800;
  background-image: url("textures/highlight.png");
  background-image:
    none,
    -webkit-linear-gradient(top, #f6813f 0, #f35a03 100%);
  background-image:
    none,
    -moz-linear-gradient(top, #f6813f 0, #f35a03 100%);
  background-image:
    none,
    -o-linear-gradient(top, #f6813f 0, #f35a03 100%);
  background-image: none, linear-gradient(to bottom, #f6813f 0, #f35a03 100%);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.k-pager-numbers .k-link,
.k-treeview .k-in {
  border-color: transparent;
}
.k-treeview .k-icon,
.k-scheduler-table .k-icon,
.k-grid .k-hierarchy-cell .k-icon {
  border-radius: 4px;
  background-color: transparent;
}
.k-scheduler-table .k-state-hover .k-icon {
  background-color: transparent;
}
.k-button:focus {
  outline: 0;
}
.k-editor .k-tool:focus {
  border-color: #c5c5c5;
  outline: 0;
  -webkit-box-shadow: inset 0 0 5px 1px #bcb4b0;
  box-shadow: inset 0 0 5px 1px #bcb4b0;
}
.k-checkbox-label:before {
  border-color: transparent;
  border-radius: 3px;
}
.k-checkbox-label:after {
  border-color: #c5c5c5;
  border-radius: 3px;
  background: #fff;
}
.k-checkbox-label:hover:after,
.k-checkbox:checked + .k-checkbox-label:hover:after {
  border-color: #a99f9a;
  box-shadow: none;
}
.k-checkbox:checked + .k-checkbox-label:after {
  color: #f36311;
  border-color: #c5c5c5;
  border-radius: 3px;
  background-color: #fff;
}
.k-checkbox-label:active:before {
  border-color: transparent;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 #a99f9a;
}
.k-checkbox-label:active:after {
  border-color: #a99f9a;
  border-radius: 3px;
}
.k-checkbox:checked + .k-checkbox-label:active:after {
  border-color: #a99f9a;
}
.k-checkbox:checked + .k-checkbox-label:active:before {
  border-radius: 3px;
  box-shadow: 0 0 3px 0 #a99f9a;
}
.k-checkbox:disabled + .k-checkbox-label {
  color: #909090;
}
.k-checkbox:disabled + .k-checkbox-label:hover:after,
.k-checkbox:disabled + .k-checkbox-label:active:before {
  box-shadow: none;
}
.k-checkbox:checked:disabled + .k-checkbox-label:after {
  color: #909090;
  background: #ededed;
}
.k-checkbox:disabled + .k-checkbox-label:after,
.k-checkbox:checked:disabled + .k-checkbox-label:active:after,
.k-checkbox:disabled + .k-checkbox-label:hover:before,
.k-checkbox:checked:disabled + .k-checkbox-label:hover:after {
  border-color: #7f7f7f;
  border-radius: 3px;
  background: #ededed;
}
.k-radio-label:before {
  border-width: 1px;
  border-color: #c5c5c5;
  border-radius: 50%;
  background-color: #fff;
}
.k-radio-label:hover:before,
.k-radio:checked + .k-radio-label:hover:before {
  border-color: #a99f9a;
  box-shadow: none;
}
.k-radio:checked + .k-radio-label:before {
  border-color: #7f7f7f;
}
.k-radio:checked + .k-radio-label:after {
  border-radius: 50%;
  background-color: #f36311;
}
.k-radio-label:active:before {
  border-color: #a99f9a;
  border-radius: 50%;
  box-shadow: 0 0 3px 0 #a99f9a;
}
.k-radio:checked + .k-radio-label:active:before {
  border-color: #7f7f7f;
  border-radius: 50%;
  box-shadow: 0 0 3px 0 #a99f9a;
}
.k-radio:disabled + .k-radio-label {
  color: #909090;
}
.k-radio:disabled + .k-radio-label:before {
  border-color: #bfbfbf;
}
.k-radio:disabled + .k-radio-label:active:before {
  background: #efefef;
  box-shadow: none;
}
.k-radio:disabled + .k-radio-label:before {
  background: #ededed;
}
.k-radio:disabled + .k-radio-label:hover:after,
.k-radio:disabled + .k-radio-label:hover:before {
  box-shadow: none;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .k-icon:not(.k-loading),
  .k-grouping-dropclue,
  .k-drop-hint,
  .k-callout,
  .k-tool-icon,
  .k-state-hover .k-tool-icon,
  .k-state-active .k-tool-icon,
  .k-state-active.k-state-hover .k-tool-icon,
  .k-state-selected .k-tool-icon,
  .k-state-selected.k-state-hover .k-tool-icon,
  .k-column-menu .k-sprite,
  .k-mobile-list .k-check:checked,
  .k-mobile-list .k-edit-field [type="checkbox"]:checked,
  .k-mobile-list .k-edit-field [type="radio"]:checked {
    background-image: url("Default/sprite_2x.png");
    background-size: 340px 336px;
  }
  .k-dropdown-wrap .k-input,
  .k-picker-wrap .k-input,
  .k-numeric-wrap .k-input {
    border-radius: 3px 0 0 3px;
  }
  .k-i-kpi-decrease,
  .k-i-kpi-denied,
  .k-i-kpi-equal,
  .k-i-kpi-hold,
  .k-i-kpi-increase,
  .k-i-kpi-open {
    background-image: url("Default/sprite_kpi_2x.png");
    background-size: 96px 16px;
  }
}
@media screen and (-ms-high-contrast: active) {
  .k-editor-toolbar-wrap .k-dropdown-wrap.k-state-focused,
  .k-editor-toolbar-wrap .k-button-group .k-tool:focus {
    border-color: #fff;
  }
}

﻿@import url("../fonts/Roboto/stylesheet.css");

button:focus {
  outline: 0 !important;
}

html {
  min-height: 100%;
  position: relative;
  font-family: "robotolight", sans-serif !important;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "robotolight", sans-serif !important;
  width: 100%;
  font-size: 100%;
  min-height: 100vh;
  background-color: black;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body.modal-open {
  overflow: hidden;
}

.header-container {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.header-nav-vertical-center {
  /*position: absolute !important;  */
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.modal-backdrop {
  z-index: 10 !important;
}

.modal {
  margin-top: 0 !important;
}

.towline-logo {
  outline: none;
  border: none;
  margin-bottom: 8px;
  margin-top: 5px;
}

.nav-display-table {
  display: table;
}

#frmTow {
  min-height: 100vh;
}

label {
  font-family: "robotolight", sans-serif;
}

.panel-heading .accordion-toggle:after {
  font-family: "Glyphicons Halflings";
  content: "\e114";
  float: right;
  color: #ffffff;
}

.panel-heading .accordion-toggle.collapsed:after {
  content: "\e080";
  color: #ffffff;
}

.panel-footer-map {
  background-color: transparent;
  padding: 2px 2px;
  border: 2px solid red;
}

#wrapper {
  position: relative;
  min-height: 100vh;
  font-family: "robotolight", sans-serif;
  margin: 0 auto -60px;
  background: transparent url("../images/bgPatterns/pattern5.png") repeat top
    left;
}

#Notice {
  font-family: "robotolight", sans-serif;
  background-color: #cb314a;
  color: #fff;
  padding: 8px;
  text-align: center;
  box-shadow: 0px 4px 5px rgba(2, 2, 2, 0.7);
}

#Notice a {
  text-decoration: underline;
}

#SearchBy {
  background-color: rgb(221, 221, 221);
}

.glyphicon-lock:before {
  content: "\e033";
}

h4 {
  margin-bottom: 8px;
  margin-top: 24px;
}

.page-title {
  font-size: 40px;
  font-family: "robotolight", sans-serif;
}

.page-title-container {
  display: inline-block;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 20px;
}

.page-title-icon {
  font-size: 40px;
}

tr th,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: rgba(86, 100, 110, 1);
}

.table-striped > tbody > tr.table-row-background {
  background-color: rgba(86, 100, 110, 1);
}

.tableHeader.sort {
  cursor: pointer;
  white-space: nowrap;
}

.tableHeader.sort .fa {
  font-size: 75%;
  line-height: 23px;
}

.col-centered {
  float: none;
  margin: 0px auto;
}

.lower-case {
  text-transform: lowercase;
}

.no-transform {
  text-transform: none;
}

code,
pre {
  font-family: "robotolight", sans-serif;
}

.btn-info {
  border: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: rgba(0, 31, 63, 0.8);
}

.btn-danger {
  background-color: rgba(203, 49, 74, 1);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-color: rgba(175, 47, 72, 1);
}

.btn-info {
  background-color: rgba(0, 31, 63, 0.8);
}

.btn-info:hover {
  background-color: rgba(0, 31, 63, 1);
}

/*.btn-group > .btn:focus {
    outline: thin dotted #000000 !important;
    outline-offset: 15px !important;
}

.btn-group > .label:focus {
    outline: thin dotted #000000 !important;
    outline-offset: 15px !important;
}*/

.version:after {
  content: "";
  /*9-9*/
  color: #666;
}

[data-toggle="buttons"] label.focus {
  outline: thin dotted;
  outline-offset: -2px;
}

#content {
  padding: 10px;
  /* Height of the footer element */
  padding-bottom: 60px;
}

.panel-heading {
  font-family: "robotolight", sans-serif;
  background-color: rgba(116, 141, 168, 1);
  text-align: start;
}

.panel-heading.row {
  margin: auto 0px;
}

.form-control {
  vertical-align: baseline;
}

.list-group .list-group-item {
  background-color: transparent;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-top: none;
  border-color: rgba(255, 255, 255, 0.2);
}

.top5 {
  margin-top: 5px;
}

.top10 {
  margin-top: 10px;
}

.top15 {
  margin-top: 15px;
}

.top20 {
  margin-top: 20px;
}

.top30 {
  margin-top: 30px;
}

.ms-sel-ctn .ms-sel-item .ms-close-btn {
  background-image: none;
  font-family: FontAwesome;
  margin: 1px 2px 0 5px;
}

.ms-sel-ctn .ms-sel-item .ms-close-btn:before {
  content: "\f00d";
}

/**==============HEADER==============**/
#header {
  padding: 10px;
  height: 75px;
}

#main-header {
  padding: 10px;
  height: 75px;
}

#navigation {
}

#navigation nav {
  height: 100%;
}

#navigation li a {
  font-size: 18px;
}

#navigation li.tdropDown {
  /*width: 150px;*/
  height: 100%;
  display: inline-block;
  line-height: 0px;
  padding-top: 0px;
  /*vertical-align: middle;*/
}

#navigation li.tdropDown .currentUser {
  background-color: transparent;
  height: 100%;
  padding: 12px 0;
  margin-left: 0;
  text-align: left;
}

#navigation li.tdropDown .currentFilter {
  font-size: 70%;
  line-height: 14px;
}

#navigation li.tdropDown .impersonateUser {
  background-color: rgba(72, 53, 100, 0.8);
  height: 100%;
  padding: 12px 0;
}

#navigation .x2.fa-user,
#navigation .x2.glyphicon-eye-close {
  position: relative;
  font-size: 30px;
  width: 30px;
}

#navigation .fa-user.fa-lg,
#navigation .fa-search.fa-lg {
  vertical-align: 0;
}

#navigation .x2.glyphicon-eye-close {
  top: 2px;
}

.searchLink {
  margin-right: 17px;
}

#navigation .username {
  font-size: 12px;
  font-family: "robotolight", sans-serif;
  width: 100%;
}

#navigation .userInformation {
  display: inline-block;
  /* width: 65%; */
  text-align: left;
  text-overflow: clip;
  white-space: nowrap;
  margin-left: 0;
  padding-left: 0;
  margin-right: 7px;
}

#navigation .impersonateUser .userInformation {
  width: 45%;
}

#navigation .userInformation .btn-link {
  padding: 0px 0px 0px 0px;
  display: block;
  border: none;
}

#navigation .userInformation button.btn-link {
  font-style: normal;
  text-transform: capitalize;
  color: #fff;
  text-align: left;
  margin-top: 1px;
}

#navigation .userInformation .btn-link:last-child {
  color: rgba(206, 47, 72, 1);
}

#navigation .nameText {
  max-width: 75px;
  line-height: 14px;
  /*display: inline-block;*/
  overflow: hidden;
  text-overflow: ellipsis;
}

#triangle-bottomright {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 40px solid rgba(100, 80, 145, 0.7);
  border-left: 40px solid transparent;
}

#triangle-bottomright span:before {
  position: absolute;
  left: -20px;
  top: 15px;
}

#triangle-bottomright span:hover {
  cursor: pointer;
}

#triangle-topleft {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 40px solid #222;
  border-right: 40px solid transparent;
}

#triangle-topleft span:before {
  position: absolute;
  left: 5px;
  top: -35px;
}

#triangle-topleft span:hover {
  cursor: pointer;
}

#ajaxLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*position: fixed;
    top: 50%;
    left: 50%;*/
  /*width: 100%;
    height: 100%;*/
  /*margin-left: -100px;
    margin-top: -50px;*/
  background: rgba(0, 0, 0, 0.5);
  /*border-radius: 4px;*/
  z-index: 9999;
}

#ajaxLoader .loadingGif {
  position: fixed;
  top: 60%;
  left: 50%;

  /*width: 100%;
    height: 100%;*/
  margin-left: -12px;
  margin-top: -11px;
  background: url("../images/spinner.gif") no-repeat top left transparent;
  background-size: contain;
}

#ajaxLoader .message {
  padding: 10px 5px;
  color: #fff;
  font-family: "robotolight", sans-serif;
  background: #333;
  text-align: center;
}

.ajaxLoaderSmall {
  width: 20px;
  height: 20px;
  background: url("../images/spinner.gif") no-repeat top left transparent;
  background-size: contain;
  display: inline-block;
  float: right;
}

#AJAXErrorModal {
  color: black;
  overflow: hidden;
}

.row-centered {
  text-align: center;
}

.col-centered {
  display: inline-block;
  float: none;
  /* reset the text-align */
  text-align: left;
  /* inline-block space fix */
  margin-right: -4px;
}

/**================FOOTER==============**/
#footer {
  background-color: #222;
  /*line-height: 40px;*/
  width: 100%;
  min-height: 50px;
  font-family: "robotolight", sans-serif;
  position: absolute;
  bottom: 0;
  /*left: 0;*/
}

/**==============Barge/Boat Details==============**/

#BargeDetailsList,
#BoatDetailsList {
  max-height: 560px;
}

#BargeDetailsList .row,
#BoatDetailsList .row {
  margin: 0px;
}

#BargeDetails .data.row {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid #f2f2f2;
  border-top: none;
}

#BargeDetails .well.grey .col-xs-5,
#BargeDetails .well.grey .col-xs-7 {
  padding: 10px;
  border-top: 1px solid #f2f2f2;
  overflow: hidden;
  text-overflow: ellipsis;
}

#BargeDetails .well.grey .col-xs-5 {
  white-space: nowrap;
}

#BargeDetails .well.grey .col-xs-7 {
  border-left: 1px solid #f2f2f2;
}

.bargeSummary,
.boatSummary {
  cursor: pointer;
  margin: 0 -15px;
  padding: 0px 15px;
}

.bargeSummary.panel-heading {
  margin: 0;
}

.boatSummary > .imgContainer {
  max-width: 400px;
  height: 215px;
  margin: 0 8px 16px 8px;
  float: left;
}

.imgwrapper img {
  width: 100%;
}

.trans-well {
  background-color: none;
  background-color: #222 !important;
}

.grey {
  background-color: rgba(86, 100, 110, 1);
}

.label {
  color: #ccc;
  font-size: 1em;
  font-weight: 500;
  white-space: nowrap !important;
  padding: 0px 4px;
}

.bargeSummary div,
.boatSummary div {
  margin: 0 -15px;
  padding: 0 15px;
}

.boatSummary > div {
  color: #fff;
}

.bargeSummary .label,
.boatSummary .label {
  display: block;
  text-align: left;
  margin: 2px 0 0;
  padding: 0px;
}

.bargeSummary .bottom,
.boatSummary .bottom {
  padding-bottom: 10px;
}

.bargeSummary .bargeDetails,
.bargesInTow {
  background-color: transparent;
  display: none;
  margin: 0 -15px;
  padding: 0px 15px 5px;
}

.bargeDetails h4,
.bargesInTow h4 {
  margin-top: 0px;
  padding-top: 10px;
}

.bargesInTow .barge:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

div.arrow {
  position: absolute;
  top: -27px;
  left: -25px;
  margin: 0px;
  padding: 0px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 26px 25px 0 25px;
  border-color: #fff transparent transparent transparent;
}

div.arrowBorder {
  position: relative;
  margin: 0px;
  padding: 0px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 25px 25px 0 25px;
  border-color: #ccc transparent transparent transparent;
}

.selected {
  background-color: #f2f2f2;
}

.horizontal#TowDiagram div#unslotted {
  height: auto;
  max-height: none;
  margin-top: 10px;
}

#unslotted .barge {
  float: left;
  margin: 0 2px;
}

/*================Tow Diagram=================*/
#TowDiagram {
  white-space: nowrap;
}

#TowDiagramPlaceholder h3 {
  text-align: center;
}

#TowDiagram > div > span,
#unslotted > span {
  display: inline-block;
}

#TowDiagram > div > span.highlight,
#TowDiagram .highlight.barge,
#TowDiagram > div > .barge:hover,
#TowDiagram > .barge:hover,
#unslotted > span.highlight,
#unslotted .highlight.barge,
#unslotted > .barge:hover,
#unslotted > .barge:hover,
.highlight.rake.none span,
.rake.none:hover span {
  background-color: #cb314a;
  border-color: #cb314a;
}

#TowDiagram .boat,
#TowDiagram .barge,
#unslotted .boat,
#unslotted .barge {
  background-color: #f2f2f2;
  margin-top: 3px !important;
}

#TowDetails .barge {
  float: left;
  margin: 8px;
}

#unslotted > div {
  padding: 8px 0;
}

.boat span {
  display: none;
}

.rake .rake {
  display: none;
  position: relative;
  margin: 0px;
  padding: 0px;
  width: 0px;
  height: 0px;
  border-style: solid;
}

.rake.none .rake {
  display: block;
  border: none;
  background-color: #f2f2f2;
}

.rake.front .rake {
  display: block;
  border-width: 5px 12px 0 12px;
  border-color: #f2f2f2 transparent transparent transparent;
}

.rake.back {
  position: relative;
}

.rake.back .rake {
  display: block;
}

/*Horizontal Tow*/
#TowDiagram,
#unslotted {
  line-height: 15px;
}

#TowDiagram.horizontal .empty {
  height: 0;
}

div#TowDiagram.horizontal,
div#unslotted.horizontal {
  height: auto;
  margin: 15px auto;
}

#TowDiagram.horizontal div,
#unslotted.horizontal div {
  max-height: 28px;
  height: auto;
}

#TowDiagram.horizontal .boat.first,
.boat.first span {
  margin-bottom: -10px;
  height: 20px;
  vertical-align: top;
}

#TowDiagram.horizontal .boat.second,
.boat.second span {
  height: 20px;
  vertical-align: bottom;
}

#TowDiagram.horizontal > div > span,
#unslotted.horizontal > span {
  position: relative;
  width: 46px;
  height: auto;
  margin-right: 10px;
}

.horizontal boat .rake {
  left: 23px;
  height: 12px;
  width: 5px;
}

.horizontal .rake.none .rake {
  left: 23px;
  height: 24px;
  width: 25px;
}

.horizontal .rake.front .rake {
  left: 46px;
  border-width: 12px 0px 12px 5px;
  border-color: transparent transparent transparent #f2f2f2;
}

.horizontal .rake.back {
  left: 5px;
}

.horizontal .rake.back .rake {
  left: -4px;
  border-width: 12px 5px 12px 0px;
  border-color: transparent #f2f2f2 transparent transparent;
}

.horizontal .highlight.rake.front .rake,
.rake.front:hover .rake {
  border-color: transparent transparent transparent #cb314a;
}

.horizontal .highlight.rake.back .rake,
.rake.back:hover .rake {
  border-color: transparent #cb314a transparent transparent;
}

.horizontal .rake .bargeName {
  left: 0;
  height: 0;
  position: absolute;
  background: transparent;
  color: #666;
  top: 12px;
  font-size: 10px;
  line-height: 0;
  width: 46px;
  text-align: center;
}

.horizontal .rake.back .bargeName {
  left: -2px;
}

.horizontal .highlight.rake .bargeName,
.rake:hover .bargeName {
  color: #fff;
}

#TDBargeDetailBox .heading {
  background-color: #f2f2f2;
  color: #222;
  margin: 0 -15px;
  padding: 8px;
  font-weight: bold;
}

.heading .loadStatus {
  float: right;
}

#TDBargeDetailBox .detailsLink {
  font-size: 75%;
  float: right;
}

#TDBargeDetailBox .detailsLink a {
  color: white;
}

/*================ReportCenter=================*/
#reportcenter .panel-heading .report-panel-heading .label {
  line-height: 47px;
  background-color: transparent;
  color: #333;
}

#reportcenter #FilterDiv {
  text-align: right;
  line-height: 45px;
}

#CreateFilterLink {
  margin-top: 20px;
}

#ReportFilterOptions {
  margin-bottom: 16px;
}

/*================User Reports=================*/
#ReportTitle {
  margin: 8px;
}

#ReportTitle input {
  width: 100%;
}

#ReportColumns {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px;
}

#IncludedColumns {
  overflow: visible;
  padding: 0px;
}

#IncludedColumns ul {
  height: 100%;
}

div.glyphicon {
  cursor: pointer;
}

span.glyphicon {
  top: 0px;
}

#SelSortable,
#UserFilters ul {
  list-style: none;
  margin-bottom: 8px;
}

#SelSortable li {
  border: 0;
  line-height: 0px;
  min-height: 45px;
  padding-top: 6px;
}

#SelSortable li > div {
  line-height: 33px;
}

#SortByModal label {
  display: inline-block;
  vertical-align: top;
  line-height: 31px;
  width: 60px;
}

#UserFilters li {
  margin: 2px auto;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  color: black;
}

#UserFilters ul li.selected button {
  background-color: #336699;
  color: #fff;
}

#UserFilters ul li.noFilter.selected button {
  background-color: red;
  color: #fff;
}

#SelSortable .deleted {
  display: none;
}

#SaveReport {
  margin: 0 0px 8px;
}

#ReportsButtonRow button.btn.cancel-button:hover {
  color: rgba(203, 49, 74, 1);
}

div.glyphicon.glyphicon-ok,
div.glyphicon.glyphicon-remove,
div.ASC,
div.DESC,
div.glyphicon-ban-circle {
  line-height: 33px;
}

.new .input-group {
  width: 100%;
}

.new div.glyphicon.glyphicon-remove {
  background: red;
  color: #fff;
  margin-top: -1px;
}

.new div.glyphicon.glyphicon-ok {
  background: #169e31;
  color: #fff;
  margin-top: -1px;
}

.new div.ASC,
.new div.DESC {
  background: #336699;
  color: #fff;
  height: 33px;
}

.new div.glyphicon-ban-circle {
  color: red;
}

.noSort {
  white-space: nowrap;
  font-size: 80%;
  min-height: 33px;
}

.noSort:after {
  content: "No Sort";
  color: #cb314a;
  line-height: 33px;
}

.ms-res-ctn {
  width: 100% !important;
}

[id*="ms-res-ctn"] {
  position: absolute;
}

.ms-ctn {
  margin-top: 2px;
  height: 29px !important;
}

#SortByModal .ms-ctn {
  margin-top: 0;
  display: inline-block;
}

.ms-helper {
  display: none !important;
}

.ms-sel-ctn .ms-sel-item,
.ms-sel-ctn .ms-sel-item:hover {
  font-size: 15px;
}

div[id*="ms-ctn-"] {
  margin-left: 25px;
}

input[id*="ms-input-"] {
  background: transparent;
}

.ms-value {
  padding-left: 24px;
}

#SelSortable .ms-close-btn {
  display: none;
}

#SelSortable li:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.3);
}

#SelSortable .input-group {
  display: inline-block;
}

.new,
.confirmed,
.unchanged {
  height: 32px;
}

ul li:nth-child(even) .confirm,
ul li:nth-child(even) .unchanged {
  background: #fff;
}

.confirmed > div,
.confirmed div.col-xs-1.pull-right.glyphicon.glyphicon-pencil,
.unchanged > div,
.unchanged div.col-xs-1.pull-right.glyphicon.glyphicon-pencil {
  line-height: 32px !important;
}

.row > div.col-xs-4 {
  margin-right: -15px;
}

.new div[class*="col"] > input[type="text"] {
  width: 100%;
  height: 28px;
  line-height: 28px;
  margin-top: 2px;
  border: 1px solid #333;
}

.handle {
  float: left;
  padding: 8px 13px 13px;
  background: #ccc;
  color: #fff;
  cursor: move;
  z-index: 99;
  height: 29px;
  position: relative;
  top: 2px;
}

.handle span {
  width: 0;
  height: 0;
  position: absolute;
  top: 7px;
  left: 6px;
}

.row.columnLabels > div.col-xs-4 {
  margin-left: -25px;
  color: #f2f2f2;
  font-family: "robotolight", sans-serif;
}

.row.columnLabels div.col-xs-4:first-child {
  margin-left: 55px;
}

button .glyphicon-ok {
  color: red;
}

button.active glyphicon {
  color: white;
  background-color: #336699;
}

.useDefault {
  display: inline-block;
  margin: 35px 15px 0 0;
}

#custFilters {
  max-height: 250px;
  overflow-y: auto;
}

#custFilters li {
  color: #222;
  cursor: pointer;
}

#custFilters li:hover {
  background-color: #f2f2f2;
}

#custFilters .filterName {
  display: inline-block;
  width: 75%;
  padding-left: 12px;
}

/*Settings Page*/
#Settings h2 {
  margin: 20px 0 10px 0;
  line-height: 75px;
  padding: 0 20px;
}

#Settings a:hover {
  text-decoration: none;
}

#Settings .panel-default {
  padding-bottom: 40px;
}

#Settings .row div {
  margin: 0 15px;
}

#Settings .row div,
#Visibility.row a > div,
#MessageType.row a > div {
  text-align: center;
  color: #fff;
  padding: 20px;
  height: 150px;
  cursor: pointer;
  border-radius: 0;
}

#Settings .row div.panel-heading:hover,
#Visibility.row div.panel-heading:hover,
#MessageType.row div.panel-heading:hover {
  background-color: #ce2f48 !important;
}

#Settings .row div.disabled,
#Visibility.row div.disabled,
#MessageType.row div.disabled {
  color: #aaa !important;
  cursor: default !important;
}

#Settings .row div.panel-heading.disabled:hover,
#Visibility.row div.panel-heading.disabled:hover,
#MessageType.row div.panel-heading.disabled:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

#Settings .row div .glyphicon,
#Settings .row div .fa,
#Visibility.row div .glyphicon,
#Visibility.row div .fa,
#MessageType.row div .glyphicon,
#MessageType.row div .fa {
  display: block;
  font-size: 45px;
}

#Settings .row div .caption,
#Visibility.row div .caption,
#MessageType.row div .caption {
  display: block;
  padding-top: 16px;
  word-wrap: break-word;
  font-family: "robotolight", sans-serif;
  font-size: 14px;
}

#Settings .row {
  user-select: none !important;
}

#Settings .row .button-control {
  margin: 0;
  padding: 0;
  height: auto;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#Settings .row .button-control .glyphicon {
  font-size: 20px;
  display: inline;
  cursor: pointer;
}

#Settings .row .button-control input {
  width: 50px;
  text-align: center;
}

#ChangePassContent h1 {
  display: none;
}

#ChangePassContent {
  height: 400px;
}

#ChangeUserList li {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

#ChangeUserList li:hover,
#ChangeUserList li.selected {
  cursor: pointer;
  background-color: #f0f0f0;
  margin-left: -19px;
  margin-right: -19px;
  padding-left: 34px;
  border-color: #dedede;
}

#ChangeUserList li.selected {
  background-color: #fff;
}

/*Conditions*/
.conditionsContainer {
  text-align: left;
}

.details {
  text-align: left;
}

.panel-heading {
  font-size: 18px;
  background-color: rgba(116, 141, 168, 1);
}

.table tbody > tr > th {
  border-top-color: #333;
}

.section {
  width: 100%;
}

.section td:first-child {
  font-family: "robotolight";
}

#LDData .severity {
  background-color: #2dbb28;
  width: 3px;
  padding: 0px;
  float: none;
  display: table-cell;
}

#LDData .severity.red {
  background-color: #fa0007;
}

#LDData .severity.yellow {
  background-color: #fae93b;
}

table tr {
  position: relative;
}

table tr.link:hover {
  cursor: pointer;
  background-color: rgba(116, 141, 168, 0.3);
}

.lblSmall,
.btnSmall,
.colSmall {
  display: none;
}

.lbl {
  display: inline;
  font-family: "robotolight", sans-serif;
  color: #d9d9d9;
}

.active .badge,
li:hover .badge {
  color: #222;
  background-color: #fff;
}

.compass {
  height: 32px;
}

.compass.N {
  /*No Rotation, Already North*/
}

.compass.S {
  /* Firefox */
  -moz-transform: rotate(180deg);
  /* Safari and Chrome */
  -webkit-transform: rotate(180deg);
  /* Opera */
  -o-transform: rotate(180deg);
  /* IE9 */
  -ms-transform: rotate(180deg);
}

.compass.E {
  /* Firefox */
  -moz-transform: rotate(90deg);
  /* Safari and Chrome */
  -webkit-transform: rotate(90deg);
  /* Opera */
  -o-transform: rotate(90deg);
  /* IE9 */
  -ms-transform: rotate(90deg);
}

.compass.W {
  /* Firefox */
  -moz-transform: rotate(270deg);
  /* Safari and Chrome */
  -webkit-transform: rotate(270deg);
  /* Opera */
  -o-transform: rotate(270deg);
  /* IE9 */
  -ms-transform: rotate(270deg);
}

.compass.Idle {
  display: none;
}

.header {
  /* Headers on Conditions page needed pointer finger */
  cursor: pointer;
}

.dropdown-menu {
  width: auto;
}

.tdropDown {
  color: #fff;
  text-align: center;
}

.tdropDown:hover {
  color: #fff;
}

.tdropDown ul {
  background: #222;
}

.white-hover:hover {
  color: #fff;
}

#SearchByList,
#SearchByList:hover {
  color: #000;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: rgba(116, 141, 168, 1) !important;
  color: white;
}

.dropdown-menu > li > a {
  color: #fff !important;
}

.wysihtml5-toolbar .dropdown-menu > li > a,
#ReportsButtonRow .dropdown-menu > li > a {
  color: #000 !important;
}

.bootstrap-datetimepicker-widget.dropdown-menu.bottom.pull-right {
  width: auto;
}

.btn.dropdown-toggle {
  width: 100%;
}

/*Filters, Emails*/
#MyFilters td:nth-child(1) {
  width: 32px;
  height: 45px;
}

#MySchedules td:nth-child(1) {
  width: 80%;
  height: 45px;
}

#MyFilters td span,
#UserFilters td,
#EmailDist td span,
#EmailDist td,
#FTPServers td span,
#FTPServers td,
#MySchedules td span,
#UserFilters td,
#OD td span,
#OD td {
  line-height: 35px;
}

#MyFilters td button,
#EmailDist td button,
#FTPServers td button,
#MySchedules td button,
#OD td button {
  padding: 0px 24px;
  margin: 0px 2px;
}

#MyFilters .row.well,
#EmailDist .row.well,
#FTPServers .row.well,
#MySchedules .row.well,
#OD .row.well {
  background: transparent;
  border: none;
}

#FilterEdits .row > div > label,
#EmailDist .row > div > label,
#FTPServers .row > div > label,
#OD .row > div > label {
  margin: 12px 0 2px 0;
}

#MyFilters .container > button,
#EmailDist .container > button,
#FTPServers .container > button,
#MySchedules .container > button,
#OD .container > button {
  margin: 12px;
}

#MyFilters li {
  color: white;
}

.tooltip-inner {
  font-size: 150%;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

/*Scheduler*/

/*ul#UserScheduleTimes{
    max-width: 110px;
}*/

.border-button {
  position: relative;
  display: block;
  margin: 0;
  padding: 5px 20px;
  font-size: 18px;
  line-height: 1.8;
  box-shadow: none;
  border-radius: 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0);
  border: solid 1px #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1;
  transition: 0.08s ease-in;
  -o-transition: 0.08s ease-in;
  -ms-transition: 0.08s ease-in;
  -moz-transition: 0.08s ease-in;
  -webkit-transition: 0.08s ease-in;
}

.border-button:focus {
  outline: none;
  color: #000000;
  background-color: #ffffff;
}

.border-button:active,
.border-button.active {
  color: #000;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.border-button:visited,
.border-button.visited {
  color: #ffffff;
}

.border-button-fill:hover {
  color: #000000;
}

.border-button-fill:before {
  content: "";
  position: absolute;
  background: #ffffff;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: top 0.09s ease-in;
}

/*Change button color in Filter Modal*/
#filterCreateModal .border-button-fill:before {
  background: rgba(206, 47, 72, 1);
}

.border-button-fill:hover:before {
  top: 0;
}

.border-button-fill:hover:after {
  color: #000000;
}

.cancel-button {
  position: relative;
  display: block;
  margin: 0;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.8;
  box-shadow: none;
  border-radius: 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0);
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}

.cancel-button:focus,
.cancel-button.focus {
  outline: none;
  color: black;
}

.cancel-button:hover,
.cancel-button.hover {
  color: black;
}

.panel-body .cancel-button:hover,
.panel-body .cancel-button.hover {
  color: #fff;
}

.cancel-button:active,
.cancel-button.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: black;
}

ul#UserScheduleTimes {
  max-width: 150px;
}

.towLogo {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  color: #000;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  top: 0;
}

/*Cooper Import*/

a {
  color: rgba(255, 255, 255, 0.9);
}

a:hover,
a:focus {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: underline;
}

.panel a,
.modal-body a,
.btn-link,
.btn-link:hover {
  color: rgba(116, 141, 168, 1);
}

.panel a:hover,
.panel a:focus,
.modal-body a:hover,
.modal-body a:focus {
  color: rgba(116, 141, 168, 1);
  text-decoration: underline;
}

.btn-link {
  vertical-align: baseline;
  font-size: 12px;
  font-style: italic;
}

.btn-link:focus {
  outline: none;
}

h1,
h2,
/*h3,*/
h4 {
  color: #fff;
  letter-spacing: 1px;
}

.modal-content {
  background-color: #f9f9f9;
}

.modal-footer .btn-link {
  color: #fff;
  padding: 15px 4px 0 15px;
}

/**==============Index==============**/
#main {
  overflow: hidden;
}

#big-video-wrap {
  height: 0px !important;
}

#big-video-vid_html5_api {
  position: static !important;
}

.left-inner-addon {
  position: relative;
}

.left-inner-addon input {
  padding-left: 30px;
}

.left-inner-addon i {
  position: absolute;
  padding: 10px 12px;
  pointer-events: none;
}

.btn-hover {
  font-weight: normal;
  color: #333333;
  cursor: pointer;
  background-color: inherit;
}

.btn-hover-alt {
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
  background-color: inherit;
}

.btn-no-border {
  border: 0;
}

.btn-no-border:hover {
  border: 1px solid gray;
}

.reportCenterInnerCells-S,
.reportCenterInnerCells-M,
.reportCenterInnerCells-L {
  position: relative;
}

@media (min-width: 768px) {
  .reportCenterInnerCells-S {
    display: block;
  }
}

@media (min-width: 992px) {
  .reportCenterInnerCells-M {
    display: table-cell;
  }
}

@media (min-width: 1200px) {
  Cells-L {
    display: table-cell;
  }
}

.reportCenterInner {
  margin-left: 0;
}

.reportCenterMap {
  clear: both !important;
  padding-top: 40px;
}

.reportImage {
  fill: rgba(255, 255, 255, 0.8);
}

.reportImageDark {
  fill: rgba(0, 0, 0, 1);
}

.reportImage:hover {
  fill: rgba(196, 57, 57, 1);
}

.reportRunButton {
  padding: 5px;
  margin: 2px 2px 2px 2px;
  background-color: rgba(203, 49, 74, 1);
}

.reportOptionsButton {
  padding: 5px;
  margin: 2px 2px 2px 2px;
  background-color: rgba(255, 255, 255, 1);
}

.reportOptionsButton:hover {
  border-color: black;
  outline-color: black;
}

.reportOptionsButton:visited,
.reportOptionsButton:active,
.reportOptionsButton:link,
.reportOptionsButton:focus {
  text-decoration: underline !important;
  /*background-color: transparent !important;*/
  /*color: white;*/
  border: none !important;
  border-color: transparent !important;
  outline: none;
}

.reportScheduleButton {
  text-decoration: underline !important;
  padding: 5px;
  margin: 2px 2px 2px 2px;
  border: none;
  border: none !important;
  border-color: transparent !important;
}

.reportScheduleButton:hover {
  text-decoration: underline;
  background-color: transparent !important;
  color: white;
  border: none;
  border: none !important;
  border-color: transparent !important;
}

.reportScheduleButton:visited,
.reportScheduleButton:active,
.reportScheduleButton:link,
.reportScheduleButton:focus {
  text-decoration: underline !important;
  background-color: transparent !important;
  color: white;
  border: none !important;
  border-color: transparent !important;
  outline: none;
}

.addReport {
  height: 238px;
  min-width: 180px;
  border: 3px dashed rgba(150, 150, 150, 0.5);
  border-radius: 8px;
}

.addReport:hover {
  cursor: pointer;
  color: #cb314a;
}

.addReport span {
  display: block;
  text-align: center;
  line-height: 225px;
  font-size: 100px;
}

.panel-title {
  font-family: inherit;
}

.panel-title > a:hover {
  color: black;
}

.panel-default,
.panel-footer,
.div {
  border-style: none;
  border-width: 0px;
  background-position: 0% 0%;
  background-color: rgba(30, 30, 30, 0.9);
  color: #fff;
  border-color: inherit;
  background-image: none !important;
  background-repeat: repeat !important;
  background-attachment: scroll !important;
}

.tableStyle {
  color: white !important;
}

.tableHeader {
  color: white !important;
  font-size: 18px;
  font-weight: 300;
}

.panel-heading,
.panel-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
  background-color: rgba(116, 141, 168, 1);
  font-family: "robotolight", sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
}

.panel-default > .panel-heading {
  background-color: rgba(116, 141, 168, 1);
  color: #ffffff;
}

/*------------------ Map CSS --------------------*/

#mapPanelDefaultMV.panel-default {
  background-color: rgba(86, 100, 110, 1);
  color: #ffffff;
  width: 250px;
  font-family: "robotolight", sans-serif !important;
  letter-spacing: normal;
}

#mapPanelDefaultMV.panel-default > .panel-heading {
  background-color: rgba(86, 100, 110, 1);
  color: #ffffff;
  font-family: "robotomedium", sans-serif !important;
  letter-spacing: normal;
}

#mapPanelDefaultMV.panel-default > .panel-title {
  background-color: rgba(86, 100, 110, 1);
  color: #ffffff;
  font-family: "robotomedium", sans-serif !important;
  letter-spacing: normal;
}

#mapPanelDefaultMV.panel-default > .panel-title > a:hover {
  background-color: rgba(86, 100, 110, 1);
  color: #ffffff;
  font-family: "robotomedium", sans-serif !important;
  letter-spacing: normal;
}

#mapPanelDefaultMV.panel-default > .panel-heading > a:hover {
  background-color: rgba(86, 100, 110, 1);
  color: #ffffff;
  font-family: "robotolight", sans-serif !important;
  letter-spacing: normal;
  text-decoration: none;
}

#mapPanelDefaultMV.panel-default > .panel-body {
  background-color: rgba(86, 100, 110, 1);
  color: #ffffff;
  font-family: "robotothin", sans-serif !important;
  text-overflow: ellipsis;
}

#mapListMV {
  font-size: 12px;
  width: 100%;
  color: #ffffff;
  font-family: "robotolight", sans-serif !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  table-layout: fixed;
  list-style-type: none;
}

#mapListMV li a {
  color: #ffffff;
  font-family: "robotolight", sans-serif !important;
  overflow: visible;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  list-style-type: none;
}

#mapListMV li a:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: underline;
  list-style-type: none;
}

#mapPanelDefaultFAC.panel-default {
  background-color: rgba(86, 100, 110, 1);
  color: #ffffff;
  width: 250px;
  font-family: "robotolight", sans-serif !important;
  letter-spacing: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#mapPanelDefaultFAC.panel-default > .panel-heading {
  background-color: rgba(86, 100, 110, 1);
  color: #ffffff;
  font-family: "robotolight", sans-serif !important;
  letter-spacing: normal;
}

#mapPanelDefaultFAC.panel-default > .panel-title {
  background-color: rgba(86, 100, 110, 1);
  color: #ffffff;
  font-family: "robotomedium", sans-serif !important;
  letter-spacing: normal;
}

#mapPanelDefaultFAC.panel-default > .panel-body {
  background-color: rgba(86, 100, 110, 1);
  color: #ffffff;
  font-family: "robotolight", sans-serif !important;
}

#mapListFAC {
  font-size: 12px;
  position: relative;
  width: 100%;
  color: #ffffff;
  font-family: "robotolight", sans-serif !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  table-layout: fixed;
  list-style-type: none;
}

#mapListFAC li {
  list-style-type: none;
}

#mapListFAC li a {
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  font-family: "robotolight", sans-serif !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  list-style-type: none;
}

#mapListFAC li a:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: underline;
  list-style-type: none;
}

.mapLinks {
  position: absolute;
  top: 40px;
  right: 0px;
  white-space: normal;
  z-index: 1;
  overflow: visible;
}

@media (min-width: 768px) {
  .mapLinks {
    width: 75%;
    top: 140px;
  }
}

/*.panel a, .modal-body a, .btn-link, .btn-link:hover
{

}*/

/*End Map CSS*/

.report-panel-heading {
  background-color: #56646e !important;
  color: white;
}

.report-panel-heading .reportTitle {
  font-size: 12px;
  height: 34px;
  overflow: hidden;
  display: inline-block;
  width: 79%;
  padding-top: 4px;
  text-overflow: ellipsis;
  text-align: left;
  margin-left: 5px;
}

.report-panel-footer {
  background-color: #56646e !important;
  color: white;
}

.panel-heading-2 {
  background-color: #33495f !important;
  color: white;
  /*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)" !important;
    filter: alpha(opacity=50) !important;
    -moz-opacity: 0.5 !important;
    -khtml-opacity: 0.5 !important;
    opacity: 0.5 !important; */
}

.panel-header-1 {
  color: black;
  font-size: 30px;
}

.panel-header-2 {
  color: white;
  font-size: 20px;
}

.panel-body {
  padding-top: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.table-striped {
  color: white;
  background-color: rgba(00, 00, 00, 0.3);
}

.table-striped > tbody > tr:nth-child(odd) > td {
  background-color: transparent;
}

.codeBreakWord {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  display: inline-block;
}

.codeBlackFont {
  color: white;
}

.codeRemoveBackground {
  background-color: transparent !important;
  border: 0;
}

header {
  padding: 0 0 0 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  left: 0;
  top: 0;
}

header h1#logo {
  margin: 4px 0;
  padding: 2px 2px 10px 2px;
}

nav ul.nav {
  text-align: right;
  padding-top: 0px;
  margin: 0px;
  font-size: 14px;
}

nav ul.nav li {
  display: inline;
  margin: 0 15px;
}

nav ul.nav li.divider {
  padding: 0px;
}

#miniNavDiv {
  padding-left: 0;
  overflow: hidden;
}

.chart-container {
  padding-bottom: 60px;
  position: relative;
  height: 40vh;
  width: 80vw;
}

footer {
  color: #666;
  padding: 10px 0 0 0;
}

#footer .nav {
  text-align: left;
  padding: 0;
}

#footer nav ul.nav li {
  /* font-size: 75%; */
  margin: 0px;
}

#footer nav ul.nav li:after {
  content: "|";
}

#footer nav ul.nav li a {
  color: #666;
}

nav ul.nav li:last-child {
  margin-right: 0;
}

nav ul.nav li.active a {
  color: white;
  text-decoration: none;
}

nav a {
  color: white;
  -webkit-transition: background 0.5s ease;
  -moz-transition: background 0.5s ease;
  -o-transition: background 0.5s ease;
  transition: background 0.5s ease;
}

nav li:hover a,
nav a.active {
  /*color:#33495f;*/
  text-decoration: none;
  font-family: "robotolight", sans-serif;
}

@media (min-width: 768px) {
  .navbar {
    padding: 20px 0;
    -webkit-transition:
      background 0.5s ease-in-out,
      padding 0.5s ease-in-out;
    -moz-transition:
      background 0.5s ease-in-out,
      padding 0.5s ease-in-out;
    transition:
      background 0.5s ease-in-out,
      padding 0.5s ease-in-out;
  }

  .top-nav-collapse {
    padding: 0;
  }
}

.nav1 {
  padding-left: 0px;
  list-style: none;
}

.nav1:before,
.nav1:after {
  display: table;
  content: " ";
}

.nav1:after {
  clear: both;
}

.nav1:before,
.nav1:after {
  display: table;
  content: " ";
}

.nav1:after {
  clear: both;
}

.nav1 > li {
  position: relative;
  display: block;
  padding-left: 0px;
}

.nav1 > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav1 > li > a:hover,
.nav1 > li > a:focus {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.3);
}

.nav1 > li.disabled > a {
  color: #999999;
}

.nav1 > li.disabled > a:hover,
.nav1 > li.disabled > a:focus {
  color: #999999;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

.nav1 .open > a,
.nav1 .open > a:hover,
.nav1 .open > a:focus {
  background-color: #eeeeee;
  border-color: #428bca;
}

.nav1 .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.nav1 > li > a > img {
  max-width: none;
}

.nav1-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav1-tabs > li > a {
  font-family: "robotolight", sans-serif;
  background-color: rgba(255, 255, 255, 0.5);
  color: #222;
  margin-right: 2px;
  line-height: 1.428571429;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav1-tabs > li > a:hover {
  background-color: rgba(209, 49, 74, 0.5);
  color: #fff;
}

.nav1-tabs > li.active > a,
.nav1-tabs > li.active > a:hover,
.nav1-tabs > li.active > a:focus {
  color: #fff;
  cursor: default;
  background-color: #cb314a;
  border: 1px solid #cb314a;
  border-bottom-color: transparent;
}

.nav1-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;
}

.nav1-tabs.nav-justified > li {
  float: none;
}

.nav1-tabs.nav-justified > li > a {
  text-align: center;
}

@media (min-width: 768px) {
  .nav1-tabs.nav1-justified > li {
    display: table-cell;
    width: 1%;
  }
}

.nav1-tabs.nav1-justified > li > a {
  margin-right: 0;
  border-bottom: 1px solid #dddddd;
}

.nav1-tabs.nav1-justified > .active > a {
  border-bottom-color: #ffffff;
}

.nav1-pills > li {
  float: left;
}

.nav1-pills > li > a {
  border-radius: 5px;
}

.nav1-pills > li.active > a,
.nav1-pills > li.active > a:hover,
.nav1-pills > li.active > a:focus {
  color: #ffffff;
  background-color: rgba(116, 141, 168, 1);
}

.nav1-stacked > li {
  float: none;
}

.nav1-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}

.nav1-justified {
  width: 100%;
}

.nav-justified > li {
  float: none;
}

.nav1-justified > li > a {
  text-align: center;
}

@media (min-width: 768px) {
  .nav1-justified > li {
    display: table-cell;
    width: 1%;
  }
}

.nav1-tabs-justified {
  border-bottom: 0;
}

.nav1-tabs-justified > li > a {
  margin-right: 0;
  border-bottom: 1px solid #dddddd;
}

.nav1-tabs-justified > .active > a {
  border-bottom-color: #ffffff;
}

video#bgvid {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: cover;
}

.black-font {
  color: black;
}

.white-font {
  color: white !important;
}

.sort-button {
  background: none;
  background-color: unset;
  border: 0;
  font-size: 16.5px;
  font-family: robotolight, sans-serif;
}

.white-hover:hover {
  color: white;
}

.modal-header-red {
  background-color: #cb314a;
}

.contact-name {
  font-weight: bold;
  font-size: 20px;
}

.contactAnchor {
  position: relative;
  top: -120px;
  visibility: hidden;
  display: block;
}

.mailLink:hover {
  color: white;
  text-decoration: underline;
}

.MSVE_MapContainer img {
  max-width: none;
}

/*End Cooper*/

/*Dashboard*/
#MessageContainer {
  font-size: 100%;
  background-color: rgba(0, 31, 63, 0.8);
}

.iconImg.message {
  background: transparent url("../images/messages.png") no-repeat center center;
  background-size: contain;
}

#MessageContainer p {
  /*margin-bottom: 0;
     font-size: 100%;*/
}

#MessageContainer .msg {
  background-color: rgba(0, 31, 63, 0.8);
  font-family: "robotolight";
  color: #fff;
  font-size: 120%;
  line-height: 50px;
  text-align: left;
  cursor: pointer;
  height: 100px;
  margin-bottom: 5px;
  display: inline-block;
}

#MessageContainer .msg:hover {
  background-color: rgba(0, 31, 63, 1);
}

#MessageContainer .msg .btn-sm {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  margin: 4px -11px;
}

#MessageContainer .msgTitle {
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 4px -11px;
  font-family: "robotolight";
  background-color: rgba(0, 31, 63, 0.8);
}

#DashboardTiles > div {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

#DashboardTiles .tile {
  background-color: rgba(180, 40, 60, 0.8);
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 4px -11px;
  overflow: hidden;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

#DashboardTiles .tile.blue {
  background-color: rgba(116, 141, 168, 0.8);
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 4px -11px;
}

#DashboardTiles .tile.double {
  background-color: rgba(180, 40, 60, 0.8);
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 4px -11px;
  height: 207px;
  overflow: hidden;
}

#DashboardTiles .tile:hover {
  background-color: rgba(180, 40, 60, 1);
}

#DashboardTiles .tile.single {
  height: 100px;
}

#DashboardTiles .tileTrans {
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 4px -11px;
  height: 100px;
}

#DashboardTiles .title {
  font-size: 100%;
  color: #fff;
  line-height: 15px;
  padding: 15px 0 4px 0;
  text-align: center;
  height: auto;
  overflow: visible;
}

#DashboardTiles .data {
  font-size: 150%;
  line-height: 41px;
  text-align: center;
  font-family: "robotolight";
}

#DashboardTiles .alt .data {
  font-size: 250%;
  padding: 18px 0 9px;
}

#DashboardTiles .alt .title {
  padding: 0;
}

#DashboardTiles .settings {
  float: right;
  position: relative;
  top: -25px;
  right: 10px;
  width: 15px;
  height: 0;
  color: #fff;
}

#DashboardTiles .ETA.button-control {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#DashboardTiles .tile .hasFocus button {
  border: none;
}

#DashboardTiles .tile .hasFocus .focusInfo {
  text-align: center;
  font-size: 15px;
  height: 30px;
}

#DashboardTiles .tile .hasFocus .focusInfo .data {
  font-size: 100%;
}

.tile.double .display:first-child {
  height: 76px;
  overflow: hidden;
}

.tile.double .display:last-child {
  height: 100%;
}

#DashboardTiles .button-control > div {
  text-align: center;
  height: 38px;
}

#DashboardTiles .ETA input {
  width: 75px;
  line-height: 18px;
  text-align: center;
}

#DashboardTiles button,
#DashboardTiles .noFocus,
#DashboardTiles .hasFocus {
  height: 100%;
  width: 100%;
  margin: 0;
}

#DashboardTiles .hasFocus button {
  float: left;
  height: 70px;
}

#DashboardTiles .hasFocus.btn-two button {
  width: 50%;
}

#DashboardTiles .hasFocus.btn-three button {
  width: 33%;
}

#DashboardTiles .double .hasFocus button {
  height: 46px;
}

.data span.imgIcon {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}

#MessageTile .imgIcon {
  background-image: url("../images/mail.png");
}

.ETA {
  text-align: center;
  padding-top: 32px;
}

.ETA .border-button {
  display: inline-block;
  margin: -4px -3px 0;
  padding-top: 6px;
  background-color: #cb314a;
}

.ETA input {
  height: 44px;
  width: 75px;
  text-align: center;
}

.boatIcon {
  height: 55px;
  background: transparent url("../images/Towboat.png") no-repeat center center;
  background-size: 75px;
}

.msg-body {
  background-color: #ddd;
}

#dashboard #lblCurrentFilter {
  max-width: 185px;
  display: inline-block;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 20px;
}

#dashboard #lblCurrentFilter:after {
  content: " Filter";
}

/*End Dashboard*/

/* Barge Reports */

#BargeReport .flex {
  width: 100%;
  height: auto;
}

#BargeReportTiles {
  display: flex;
  flex-wrap: wrap-reverse;
  width: 20%;
  height: auto;
  position: fixed;
  top: 60px;
  left: 0;
  overflow: hidden;
  margin: 10px;
}

#BargeReportTiles .tile.single {
  height: 75px;
  min-width: 130px;
  margin: 3px;
}

#BargeReportTiles .tile {
  background-color: rgba(180, 40, 60, 0.8);
  color: white;
  border: none;
  border: solid black 1px;
}

#BargeReportTiles .stats {
  font-size: 40px;
  font-weight: 800;
  text-align: center;
}

#BargeReportTiles .title {
  font-size: 13px;
  text-align: center;
}

#BargeReport #not-valid-user {
  color: black;
  border: solid 2px rgba(180, 40, 60, 0.8);
  width: 21rem;
  margin: 0 auto;
  padding: 3rem 5rem;
  background-color: grey;
  font-size: 1.5rem;
  font-weight: 900;
}

.filter-container {
  width: 25%;
  position: fixed;
  bottom: 60px;
  left: 0;
  background-color: grey;
  border: black 1px solid;
  height: 40%;
  margin: 10px;
  padding: 5px;
}

.datepicker-label {
  color: white;
  margin-bottom: 0;
  text-align: center;
}

.history-through {
  color: white;
}

.btn-group .btn {
  padding: 3px 12px;
}

.filter-container .btn-group {
  width: 100%;
  margin-bottom: 3px;
}

.input-group {
  margin-bottom: 3px;
}

.filter-container .dropdown-menu {
  overflow: scroll;
  max-height: 10rem;
}

.chart-container {
  height: 40vh;
  width: 70vw;
  display: block;
  float: right;
  position: fixed;
  top: 60px;
  right: 0;
  overflow: hidden;
  z-index: auto;
  margin: 10px;
  border: solid black 1px;
  background-color: grey;
  padding: 10px;
}

.table-container {
  height: 40vh;
  width: 70vw;

  /*overflow-y: auto;
    overflow-x: auto;*/
  overflow: hidden;
  float: right;
  margin: 10px;
  display: block;
  position: fixed;
  bottom: 60px;
  right: 0;
  color: black;
  border: solid black 1px;
  background-color: grey;
  padding: 10px;
}

.table-container .table-condensed {
  font-size: 13px;
}

.table {
  margin-bottom: 0 !important;
}

#BargeReportTable th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
}

#BargeReportTable th tr {
  position: sticky !important;
}

#BargeReportTable .redClass {
  color: #eb6767;
  font-weight: 800;
}

#BargeReportTable_wrapper .dataTables_scroll {
  width: 68.5vw !important;
}

#BargeReportTable_wrapper th:hover {
  opacity: 0.5 !important;
}

#BargeReportTable th:hover {
  opacity: 0.5 !important;
}

#BargeReportTable td {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
}

@media only screen and (max-width: 1360px) {
  #BargeReportTiles {
    width: 25%;
  }
}

/*1025px and smaller*/
@media only screen and (max-width: 1025px) {
  #BargeReportTiles .tile.single {
    height: 60px;
    min-width: 110px;
    margin: 1px;
  }

  #BargeReportTiles .stats {
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }

  #BargeReportTiles .title {
    font-size: 10px;
    text-align: center;
  }
}

/*790px and smaller*/
/*@media only screen and (max-width: 790px) {*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .chart-container {
    height: 60vh;
    width: auto;
    float: none;
    position: unset;
  }

  .table-container {
    height: 38vh !important;
    width: auto !important;
    float: none;
    position: unset;
    margin-bottom: 94px;
  }

  /* Force table to not be like tables anymore */
  .table {
    margin-bottom: 0 !important;
  }

  #BargeReportTable_wrapper .dataTables_scroll {
    width: auto !important;
  }

  #BargeReportTable_wrapper .dataTables_scrollHead {
    display: block !important;
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }

  #BargeReportTable table {
    display: block;
  }

  #BargeReportTable thead {
    display: block;
  }

  #BargeReportTable tbody {
    display: block;
  }

  #BargeReportTable th {
    display: block !important;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #BargeReportTable thead tr {
    display: block !important;
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }

  #BargeReportTable tr {
    display: block;
    border: 1px solid #ccc;
    max-height: 12px !important;
  }

  #BargeReportTable td {
    display: block;
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 60%;
    float: none;
    min-height: 31px;
  }

  #BargeReportTable td:before {
    /* Now like a table header */
    position: absolute;
    display: block;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  #BargeReportTable td:nth-of-type(1):before {
    content: "Barge";
  }

  #BargeReportTable td:nth-of-type(2):before {
    content: "Status";
  }

  #BargeReportTable td:nth-of-type(3):before {
    content: "PO";
  }

  #BargeReportTable td:nth-of-type(4):before {
    content: "Sched";
  }

  #BargeReportTable td:nth-of-type(5):before {
    content: "Custody";
  }

  #BargeReportTable td:nth-of-type(6):before {
    content: "Loca";
  }

  #BargeReportTable td:nth-of-type(7):before {
    content: "Load Dk";
  }

  #BargeReportTable td:nth-of-type(8):before {
    content: "Disch Dk";
  }

  #BargeReportTable td:nth-of-type(9):before {
    content: "ETA";
  }

  #BargeReportTable td:nth-of-type(10):before {
    content: "Est Trip";
  }

  #BargeReportTable td:nth-of-type(11):before {
    content: "Std Trip";
  }

  #BargeReportTable td:nth-of-type(12):before {
    content: "Late Tran Dy";
  }

  #BargeReportTable td:nth-of-type(13):before {
    content: "Cargo";
  }

  #BargeReportTable td:nth-of-type(14):before {
    content: "Dedic'd";
  }

  #BargeReportTable td:nth-of-type(15):before {
    content: "Arv to Ld";
  }

  #BargeReportTable td:nth-of-type(16):before {
    content: "Place LD";
  }

  #BargeReportTable td:nth-of-type(17):before {
    content: "Late Place Dy";
  }

  #BargeReportTable td:nth-of-type(18):before {
    content: "Rel LD";
  }

  #BargeReportTable td:nth-of-type(19):before {
    content: "Place Disch";
  }

  #BargeReportTable {
    margin: 0 auto;
    width: 100% !important;
    clear: both;
    border-collapse: collapse;
    table-layout: fixed;
    word-wrap: break-word;
  }

  .filter-container {
    width: auto;
    float: none;
    position: unset;
  }

  #BargeReportTiles {
    width: 100%;
    float: none;
    position: unset;
    align-content: space-evenly;
  }

  #footer {
    height: 92px;
    position: fixed;
  }
}

/*Admin UI*/
#breadNav ol {
  background-color: transparent;
}

/******** MESSAGE STYLES **********/
#Messages .panel-body {
  padding: 0;
}

/*****Social Media Icons******/
a.btn.navbar-btn {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0;
  margin-top: 0;
  /*vertical-align: baseline;*/
}

a.btn.navbar-btn.white-hover:hover {
  color: rgb(255, 255, 255);
}

a.btn.navbar-btn.white-hover {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0px;
  /*vertical-align: baseline;*/
}

#iconImg,
#IconImgConf,
.iconImg {
  background-repeat: no-repeat;
  line-height: 80px;
  vertical-align: top;
  font-size: 65px;
  max-width: 80px;
}

.read .iconImg {
  color: #666;
}

.preview {
  text-align: left;
  overflow: hidden;
  padding: 12px;
  margin: 0px auto;
  border-top: 1px solid rgba(90, 90, 90, 0.5);
  border-bottom: 1px solid rgba(40, 40, 40, 0.9);
  cursor: pointer;
}

.preview:first-child {
  border-top: none;
}

.preview:last-child {
  border-bottom: none;
}

.preview:hover {
  background-color: rgba(40, 40, 40, 1);
}

.preview .title {
  font-family: "robotomedium";
}

.preview .title,
.preview .summary {
  display: inline-block;
  width: 100%;
}

/******** Announcements *********/
#Visibility.row .selected div.panel-heading,
#MessageType.row .selected div.panel-heading {
  background-color: #cc324b !important;
}

.msgType.selected,
.visType.selected {
  background: none;
}

.form-control.textarea,
.form-control.inputText {
  width: 100%;
  max-width: 810px;
  margin: 8px 0;
}

.custList,
.userList {
  list-style: none;
  padding: 16px 0 0px;
}

.custList li,
.userList li {
  display: inline-block;
  width: 15%;
  margin: 4px 8px;
  padding: 4px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100px;
  vertical-align: middle;
  /**/
  cursor: pointer;
}

.custList li input,
.userList li input {
  margin: 0 4px;
}

ul#audienceConf,
ul#targetConf {
  list-style: none;
  background: #fff;
  border: 1px solid #ccc;
  text-align: left;
  max-width: 400px;
  margin: 0 auto;
  padding: 0;
}

#audienceConf li,
#targetConf li {
  padding: 8px;
}

#audienceConf li.alt,
#targetConf li.alt {
  background: #f2f2f2;
  padding: 8px;
}

.custList li.selected,
.userList li.selected {
  background-color: #669933;
  color: #fff;
}

.btn.jumbo {
  font-size: 20px;
  font-weight: normal;
  padding: 14px 24px;
  margin-right: 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.bootstrap-datetimepicker-widget thead {
  position: static;
}

.bootstrap-datetimepicker-widget thead th {
  background-color: transparent;
  border: none;
}

#AnnConfirm h3 {
  color: #fff;
}

input.startDate.form-control[readonly],
input.endDate.form-control[readonly] {
  cursor: pointer;
}

.counter {
  text-align: right;
  font-size: 75%;
}

a[title*="Insert image"] {
  display: none;
}

.fullMessage .fa,
.fullMessage .glyphicon {
  font-size: 75px;
  text-align: center;
}

#MessageList.hideExpired .expired {
  display: none;
}

#MessageList.hideRead .read {
  display: none;
}

#Messages .container > button {
  margin: 2px;
}

/*Admin UI*/

.mobileNavbutton {
  margin: 5px !important;
  color: rgba(255, 255, 255, 1) !important;
  height: ​ 50px !important;
  width: ​ 96px !important;
  min-height: ​ 50px !important;
  min-width: ​ 96px !important;
  display: ​ inherit !important;
  outline: ​ none;
  /*position:​ static !important;*/
  padding: 15px !important;
  /*display: inline-block;*/
  margin: 0 1em;
  border: none;
  background: none;
  float: left;
}

.mobileNavbutton span {
  display: block;
}

.anibtn {
  margin: 5px !important;
  color: rgba(255, 255, 255, 1) !important;
  opacity: ​ 1 !important;
  height: ​ 50px !important;
  width: ​ 96px !important;
  min-height: ​ 50px !important;
  min-width: ​ 96px !important;
  display: ​ inherit !important;
  outline: ​ none !important;
  position: ​ static !important;
  padding: 15px !important;
}

.anibtn .closeMobileNav {
  margin: 5px !important;
  color: rgba(255, 255, 255, 1) !important;
  opacity: ​ 1 !important;
  height: ​ 50px !important;
  width: ​ 96px !important;
  min-height: ​ 50px !important;
  min-width: ​ 96px !important;
  display: ​ inherit !important;
  outline: ​ none !important;
  position: ​ static !important;
  padding: 15px !important;
}

.closeMobileNav {
  float: left;
  opacity: 1;
  font-size: 100%;
  line-height: 1;
  color: rgba(255, 255, 255, 1) !important;
}

.close,
.close:hover,
.close:focus {
  color: #fff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.anibtn
  .anibtn:active
  .anibtn:hover
  .anibtn:visited
  .anibtn:link
  .anibtn:active {
  color: rgba(255, 255, 255, 1) !important;
  opacity: ​ 1 !important;
}

#navDiv {
  position: inherit;
  /*background-color: #222;*/
}

header {
  background-color: #222;
}

button:focus {
  outline: 0;
}

.buttons-container {
  margin: auto;
  text-align: center;
}

.grid-button {
  padding: 2rem;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.grid-button .grid {
  width: 1rem;
  height: 1rem;
  background: #ecf0f1;
  color: #ecf0f1;
  /* Not in use when the colors are specified below */
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.grid-button.closeMobileNav .grid {
  -moz-transform: rotate3d(0, 0, 1, -45deg) scale3d(0.8, 0.8, 0.8);
  -ms-transform: rotate3d(0, 0, 1, -45deg) scale3d(0.8, 0.8, 0.8);
  -o-transform: rotate3d(0, 0, 1, -45deg) scale3d(0.8, 0.8, 0.8);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) scale3d(0.8, 0.8, 0.8);
  transform: rotate3d(0, 0, 1, -45deg) scale3d(0.8, 0.8, 0.8);
}

.grid-button.rearrange .grid {
  -moz-box-shadow:
    -1.25rem -1.25rem,
    0 -1.25rem,
    1.25rem -1.25rem,
    -1.25rem 0,
    1.25rem 0,
    -1.25rem 1.25rem,
    0 1.25rem,
    1.25rem 1.25rem;
  -webkit-box-shadow:
    -1.25rem -1.25rem,
    0 -1.25rem,
    1.25rem -1.25rem,
    -1.25rem 0,
    1.25rem 0,
    -1.25rem 1.25rem,
    0 1.25rem,
    1.25rem 1.25rem;
  box-shadow:
    -1.25rem -1.25rem,
    0 -1.25rem,
    1.25rem -1.25rem,
    -1.25rem 0,
    1.25rem 0,
    -1.25rem 1.25rem,
    0 1.25rem,
    1.25rem 1.25rem;
}

.grid-button.rearrange.closeMobileNav .grid {
  -moz-box-shadow:
    0 -1rem,
    0 -2rem,
    1rem 0,
    -2rem 0,
    2rem 0,
    -1rem 0,
    0 2rem,
    0 1rem;
  -webkit-box-shadow:
    0 -1rem,
    0 -2rem,
    1rem 0,
    -2rem 0,
    2rem 0,
    -1rem 0,
    0 2rem,
    0 1rem;
  box-shadow:
    0 -1rem,
    0 -2rem,
    1rem 0,
    -2rem 0,
    2rem 0,
    -1rem 0,
    0 2rem,
    0 1rem;
}

.grid-button.collapse .grid {
  -moz-box-shadow:
    -1.25rem 0,
    -1.25rem 1.25rem,
    1.25rem 0,
    1.25rem -1.25rem,
    0 -1.25rem,
    -1.25rem -1.25rem,
    0 1.25rem,
    1.25rem 1.25rem;
  -webkit-box-shadow:
    -1.25rem 0,
    -1.25rem 1.25rem,
    1.25rem 0,
    1.25rem -1.25rem,
    0 -1.25rem,
    -1.25rem -1.25rem,
    0 1.25rem,
    1.25rem 1.25rem;
  box-shadow:
    -1.25rem 0,
    -1.25rem 1.25rem,
    1.25rem 0,
    1.25rem -1.25rem,
    0 -1.25rem,
    -1.25rem -1.25rem,
    0 1.25rem,
    1.25rem 1.25rem;
}

.grid-button.collapse.closeMobileNav .grid {
  -moz-box-shadow:
    -1rem 0,
    0 0 transparent,
    1rem 0,
    0 0 transparent,
    0 -1rem,
    0 0 transparent,
    0 1rem,
    0 0 transparent;
  -webkit-box-shadow:
    -1rem 0,
    0 0 transparent,
    1rem 0,
    0 0 transparent,
    0 -1rem,
    0 0 transparent,
    0 1rem,
    0 0 transparent;
  box-shadow:
    -1rem 0,
    0 0 transparent,
    1rem 0,
    0 0 transparent,
    0 -1rem,
    0 0 transparent,
    0 1rem,
    0 0 transparent;
}

/* ====================== lines button ==================================================*/
.lines-button {
  padding: 2rem 1rem;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-border-radius: 0.57143rem;
  -webkit-border-radius: 0.57143rem;
  border-radius: 0.57143rem;
}

.lines-button:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}

.lines-button:active {
  -moz-transition: 0;
  -o-transition: 0;
  -webkit-transition: 0;
  transition: 0;
}

.lines {
  display: inline-block;
  width: 3.5rem;
  height: 0.44rem;
  background: #ecf0f1;
  -moz-border-radius: 0.28571rem;
  -webkit-border-radius: 0.28571rem;
  border-radius: 0.28571rem;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}

.lines:before,
.lines:after {
  display: inline-block;
  width: 3.5rem;
  height: 0.44rem;
  background: #ecf0f1;
  -moz-border-radius: 0.28571rem;
  -webkit-border-radius: 0.28571rem;
  border-radius: 0.28571rem;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  left: 0;
  content: "";
  -moz-transform-origin: 0.28571rem center;
  -ms-transform-origin: 0.28571rem center;
  -o-transform-origin: 0.28571rem center;
  -webkit-transform-origin: 0.28571rem center;
  transform-origin: 0.28571rem center;
}

.lines:before {
  top: 0.9rem;
}

.lines:after {
  top: -0.9rem;
}

.lines-button:hover .lines:before {
  top: 1rem;
}

.lines-button:hover .lines:after {
  top: -1rem;
}

.lines-button.closeMobileNav {
  -moz-transform: scale3d(0.8, 0.8, 0.8);
  -ms-transform: scale3d(0.8, 0.8, 0.8);
  -o-transform: scale3d(0.8, 0.8, 0.8);
  -webkit-transform: scale3d(0.8, 0.8, 0.8);
  transform: scale3d(0.8, 0.8, 0.8);
}

.lines-button.arrow.closeMobileNav .lines:before,
.lines-button.arrow.closeMobileNav .lines:after {
  top: 0;
  width: 2.22222rem;
}

.lines-button.arrow.closeMobileNav .lines:before {
  -moz-transform: rotate3d(0, 0, 1, 40deg);
  -ms-transform: rotate3d(0, 0, 1, 40deg);
  -o-transform: rotate3d(0, 0, 1, 40deg);
  -webkit-transform: rotate3d(0, 0, 1, 40deg);
  transform: rotate3d(0, 0, 1, 40deg);
}

.lines-button.arrow.closeMobileNav .lines:after {
  -moz-transform: rotate3d(0, 0, 1, -40deg);
  -ms-transform: rotate3d(0, 0, 1, -40deg);
  -o-transform: rotate3d(0, 0, 1, -40deg);
  -webkit-transform: rotate3d(0, 0, 1, -40deg);
  transform: rotate3d(0, 0, 1, -40deg);
}

.lines-button.arrow-up.closeMobileNav {
  -moz-transform: scale3d(0.8, 0.8, 0.8) rotate3d(0, 0, 1, 90deg);
  -ms-transform: scale3d(0.8, 0.8, 0.8) rotate3d(0, 0, 1, 90deg);
  -o-transform: scale3d(0.8, 0.8, 0.8) rotate3d(0, 0, 1, 90deg);
  -webkit-transform: scale3d(0.8, 0.8, 0.8) rotate3d(0, 0, 1, 90deg);
  transform: scale3d(0.8, 0.8, 0.8) rotate3d(0, 0, 1, 90deg);
}

.lines-button.minus.closeMobileNav .lines:before,
.lines-button.minus.closeMobileNav .lines:after {
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  -webkit-transform: none;
  transform: none;
  top: 0;
  width: 4rem;
}

.lines-button.x.closeMobileNav .lines {
  background: transparent;
}

/* .lines-button.x.closeMobileNav .lines:before, .lines-button.x.closeMobileNav .lines:after {
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        top: 0;
        width: 4rem;
    }

    .lines-button.x.closeMobileNav .lines:before {
        -moz-transform: rotate3d(0, 0, 1, 45deg);
        -ms-transform: rotate3d(0, 0, 1, 45deg);
        -o-transform: rotate3d(0, 0, 1, 45deg);
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
    }

    .lines-button.x.closeMobileNav .lines:after {
        -moz-transform: rotate3d(0, 0, 1, -45deg);
        -ms-transform: rotate3d(0, 0, 1, -45deg);
        -o-transform: rotate3d(0, 0, 1, -45deg);
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
    } */

.lines-button.x2 .lines {
  -moz-transition: -moz-background 0.3s 0.5s ease;
  -o-transition: -o-background 0.3s 0.5s ease;
  -webkit-transition: -webkit-background 0.3s 0.5s ease;
  transition: background 0.3s 0.5s ease;
}

.lines-button.x2 .lines:before,
.lines-button.x2 .lines:after {
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -moz-transition:
    top 0.3s 0.6s ease,
    -moz-transform 0.3s ease;
  -o-transition:
    top 0.3s 0.6s ease,
    -o-transform 0.3s ease;
  -webkit-transition:
    top 0.3s 0.6s ease,
    -webkit-transform 0.3s ease;
  transition:
    top 0.3s 0.6s ease,
    transform 0.3s ease;
  -moz-transition:
    top 0.3s 0.6s ease,
    -moz-transform 0.3s ease;
  -o-transition:
    top 0.3s 0.6s ease,
    -o-transform 0.3s ease;
  -webkit-transition:
    top 0.3s 0.6s ease,
    -webkit-transform 0.3s ease;
  transition:
    top 0.3s 0.6s ease,
    transform 0.3s ease;
}

.lines-button.x2.closeMobileNav .lines {
  -moz-transition: -moz-background 0.3s 0s ease;
  -o-transition: -o-background 0.3s 0s ease;
  -webkit-transition: -webkit-background 0.3s 0s ease;
  transition: background 0.3s 0s ease;
  background: transparent;
}

.lines-button.x2.closeMobileNav .lines:before,
.lines-button.x2.closeMobileNav .lines:after {
  -moz-transition:
    top 0.3s ease,
    -moz-transform 0.3s 0.5s ease;
  -o-transition:
    top 0.3s ease,
    -o-transform 0.3s 0.5s ease;
  -webkit-transition:
    top 0.3s ease,
    -webkit-transform 0.3s 0.5s ease;
  transition:
    top 0.3s ease,
    transform 0.3s 0.5s ease;
  -moz-transition:
    top 0.3s ease,
    -moz-transform 0.3s 0.5s ease;
  -o-transition:
    top 0.3s ease,
    -o-transform 0.3s 0.5s ease;
  -webkit-transition:
    top 0.3s ease,
    -webkit-transform 0.3s 0.5s ease;
  transition:
    top 0.3s ease,
    transform 0.3s 0.5s ease;
  top: 0;
  width: 4rem;
}

.lines-button.x2.closeMobileNav .lines:before {
  -moz-transform: rotate3d(0, 0, 1, 45deg);
  -ms-transform: rotate3d(0, 0, 1, 45deg);
  -o-transform: rotate3d(0, 0, 1, 45deg);
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}

.lines-button.x2.closeMobileNav .lines:after {
  -moz-transform: rotate3d(0, 0, 1, -45deg);
  -ms-transform: rotate3d(0, 0, 1, -45deg);
  -o-transform: rotate3d(0, 0, 1, -45deg);
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.btn-circle.trans {
  background-color: transparent;
  color: #fff;
  border: 0.5px solid #fff;
}

.btn-circle.trans:hover {
  background-color: #fff;
  color: #000;
  border: 0.5px solid #000;
}

/*Flexy Map Container*/
/* Flexible iFrame */

.FlexyMap-container {
  position: relative;
  padding-bottom: 30px;
  padding-top: 30px;
  /*height: 0;*/
  overflow: hidden;
  /*padding-left:25%;*/
}

.FlexyMap-container iframe,
.FlexyMap-container object,
.FlexyMap-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.infoboxStyle {
  font-size: 12px;
  font-family: "robotolight", sans-serif;
  color: black;
  line-height: normal;
}

.infoboxStyle a {
  color: black;
}

.infoboxStyle a:hover {
  color: rgba(206, 47, 72, 1);
}

.infoboxStyleTitle {
  font-size: 14px;
  font-family: "robotolight", sans-serif;
  color: black;
  line-height: normal;
}

.icon {
  position: relative;
  width: 64px;
  height: 64px;
}

@media (max-width: 991px) {
  #content {
    padding: 0 0 93px 0;
  }

  /*Navigation - Center on smaller screens*/
  #navigation {
    width: 100%;
  }

  #navigation ul {
    margin: 0 auto;
    width: 150px;
  }

  .currentUser {
    text-align: center !important;
  }

  /* sidenav */
  /* header */
  .lines {
    width: 2.5rem;
    height: 0.3rem;
  }

  .lines:before,
  .lines:after {
    width: 2.5rem;
    height: 0.3rem;
  }

  .lines:before {
    top: 0.6rem !important;
  }

  .lines:after {
    top: -0.6rem !important;
  }

  /* .lines-button.x.closeMobileNav .lines:before, .lines-button.x.closeMobileNav .lines:after {
                -moz-transform-origin: 50% 50%;
                -ms-transform-origin: 50% 50%;
                -o-transform-origin: 50% 50%;
                -webkit-transform-origin: 50% 50%;
                transform-origin: 70% 50% !important;
                top: 0;
                width: 4rem;
            } */
}

@media (max-width: 768px) {
  #header,
  #main-header {
    padding: 0;
  }

  #content {
    padding: 0 0 132px 0;
    margin: 0;
  }

  #header,
  header,
  #main-header {
    height: auto;
    max-height: none;
    position: static;
  }

  #footer {
    height: 92px;
  }

  .well {
    border-radius: 0;
  }

  #requestModal .well {
    margin: 0px;
  }

  .table-responsive > .table {
    background-color: rgba(0, 0, 0, 0.2);
  }

  #tabs1 li {
    padding: 0;
    margin: 0;
  }

  .nav1 > li a {
    height: 62px;
  }

  .responsive-list .table tbody > tr > td {
    border: none;
  }

  .responsive-list tr,
  .responsive-list td {
    display: block;
    float: left;
    font-family: "robotolight", sans-serif;
  }

  .responsive-list td span {
    white-space: normal;
  }

  .responsive-list tbody tr {
    display: table-row;
    border-top: none;
    float: none;
  }

  .responsive-list td:nth-child(2n + 1),
  .responsive-list td:last-child {
    clear: both;
  }

  .responsive-list td:nth-child(2),
  .responsive-list td:nth-child(4) {
    /* max-width: 300px; */
  }

  .responsive-list tr td:first-child a {
    color: #fff !important;
    font-family: "robotolight", sans-serif;
  }

  .panel-body {
    padding: 0;
  }

  .responsive-list .reference {
    display: none;
  }

  .lblSmall {
    display: inline;
    font-family: "robotolight", sans-serif;
    color: #d9d9d9;
  }

  .btnSmall {
    display: inline;
  }

  .colSmall {
    display: block;
  }

  .section {
    border-bottom: 1px solid #fff;
  }

  .section .milepoint,
  .section .asof0500 {
    clear: left;
    text-indent: 35px;
  }

  #Savere {
    margin-top: 4px;
  }

  .headingText {
    font-weight: bold;
  }

  .responsive-list table tr td.arrow:last-child {
    position: absolute;
    right: 16px;
    margin-top: 10px;
  }

  #DashboardTiles .tile.single .focusInfo {
    height: 57px;
  }

  #DashboardTiles .hasFocus button {
    text-align: center;
    height: auto;
    padding-left: 0;
    padding-right: 0;
  }

  #DashboardTiles .hasFocus.btn-two button {
    float: left;
    width: 50%;
  }

  #navigation li.tdropDown {
    width: 100%;
  }

  #navigation li.tdropDown .currentUser {
    display: inline-block;
  }

  /*Vertical Tow*/
}

.btn-red {
  background-color: #cb314a !important;
  border-color: #cb314a !important;
  width: 225px;
  height: 60px;
}

.main-panel-icon {
  font-size: 25px;
  color: rgba(206, 47, 72, 0.9);
}

.main-panel-title {
  font-size: 25px;
  display: inline-block;
}

.main-panel-return-link {
  font-style: italic;
  letter-spacing: normal;
  font-size: 12px;
}

.bargeBoatDetailsMap {
  height: 300px;
}

.indent {
  padding-left: 35px;
  padding-bottom: 10px;
  word-wrap: break-word;
  white-space: normal;
}

/*Fix for mobile zooming*/
select,
textarea,
input[type="text"],
input[type="password"],
textarea:focus,
input[type="datetime"],
input[type="datetime-local"],
input[type="password"],
input[type="date"],
input[type="month"],
input[type="time"],
select:focus,
input[type="week"],
input[type="number"],
input[type="email"],
input[type="text"]:focus,
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  font-size: 16px;
}

/*Font Fixes*/
strong {
  font-family: "robotolight", sans-serif !important;
}

@media (min-width: 768px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: "robotolight", sans-serif !important;
  }
}

dt {
  font-family: "robotolight", sans-serif !important;
}

pre {
  font-family: "robotolight", sans-serif !important;
}

#label {
  font-family: "robotolight", sans-serif !important;
}

label {
  font-family: "robotolight", sans-serif !important;
}

.label {
  font-family: "robotomedium", sans-serif !important;
  letter-spacing: 1px;
}

.badge {
  font-family: "robotolight", sans-serif !important;
}

.alert {
  font-family: "robotolight", sans-serif !important;
}

.alert-link {
  font-family: "robotolight", sans-serif !important;
}

.close.carousel-control {
  font-family: "robotolight", sans-serif !important;
}

.icon-prev {
  font-family: "robotolight", sans-serif !important;
}

.carousel-control {
  font-family: "robotolight", sans-serif !important;
}

.icon-next {
  font-family: "robotolight", sans-serif !important;
}

button,
input,
select,
textarea {
  font-family: "robotolight", sans-serif !important;
}

input,
button,
select,
textarea,
select optgroup {
  font-family: "robotolight", sans-serif !important;
}

.barge-details-height {
  /*min-height: 350px;*/
}

.wrapWord {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.modal-scrollable {
  height: 100%;
  width: 100%;
}

/* Filter Modal */

#filter-dialog .form-group {
  margin-left: 0;
  margin-right: 0;
}

#filter-dialog #FilterName {
  width: 90%;
  padding-left: 2%;
  text-overflow: ellipsis;
}

#filter-dialog #FilterName[readonly="true"],
#filter-dialog #FilterName[readonly="readonly"] {
  box-shadow: none !important;
}

#filter-dialog .editing {
  background-color: #fff;
  border: 1px solid #ccc;
  display: inline;
  padding: 0px;
  cursor: initial;
}

#filter-dialog li input {
  background-color: #f5f5f5;
  border: 0px;
  display: inline;
  padding: 0px;
  cursor: pointer;
}

#filter-dialog {
  height: 90%;
}

#filter-dialog .modal-dialog,
#filter-dialog .modal-content {
  height: 100%;
}

#filter-dialog .modal-header,
#filter-dialog .modal-footer {
  height: 10%;
}

#filter-dialog .modal-body {
  height: 80%;
  width: 100%;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

#filter-dialog #filterFrm li {
  width: 100% !important;
}

#filter-dialog .modal-footer {
  margin-top: 0;
}

#filter-dialog .row-hidden .form-group {
  margin: auto;
}

#filter-dialog #UserFilters li span.glyphicon {
  color: #dedede;
}

#filter-dialog #UserFilters .btnEdit .glyphicon {
  color: black;
}

#filter-dialog .modal-body form.well {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 0;
}

#filter-dialog .modal-body form#filterFrm {
  height: calc(100% - 55px);
  overflow: auto;
  padding-top: 1%;
}

#filter-dialog #UserFilters ul li.selected button {
  background-color: #336699;
  color: #fff;
}

#filter-dialog .help-block {
  text-align: center;
}

#filter-dialog #UserFilters li span.selected {
  color: rgba(0, 31, 63, 1);
  background-color: transparent;
}

#filter-dialog #UserFilters li span.glyphicon-trash {
  color: rgba(233, 0, 0, 1);
  background-color: transparent;
}

#filter-dialog #UserFilters li span.glyphicon-plus {
  color: rgba(0, 175, 0, 1);
  background-color: transparent;
}

#filter-dialog #UserFilters li span.glyphicon-remove {
  color: rgba(0, 0, 0, 1);
  background-color: transparent;
}

#filter-dialog .modal-body form#frmTow label,
#filter-dialog .modal-body .form-group {
  color: black !important;
}

/* Filter Validation Style */
#filter-dialog #filterFrm i.glyphicon {
  position: absolute;
  top: 5px;
  right: 20px;
  display: inline;
}

#filter-dialog .has-error input {
  border-color: #b94a48;
}

#filter-dialog .has-success input {
  border-color: #3c763d;
}

#filter-dialog #filterFrm .river-marker div {
  padding: 0;
}

#filter-dialog #UserFilters .form-group {
  margin-bottom: 0;
}

/* Filter Name Error Modal */
.filterNameModal {
  width: 50%;
  position: absolute;
  top: 25%;
  left: 25%;
  margin: auto !important;
  color: black !important;
}

#filterFrm .ms-sel-ctn,
#reportcenter .ms-sel-ctn {
  overflow-x: hidden;
  overflow-y: auto;
  width: 94.01330376940133%;
}

#filter-dialog .wrapWord,
#filter-dialog .text-right {
  padding: 0;
}

#filterFrm #Customer {
  height: 100% !important;
}

#filterFrm .ms-sel-item {
  margin-bottom: 3px;
}

/* Request Account Form */
.accountOptions i {
  display: block !important;
}

/* Filter Modal Stop Background Scrolling */
body.filter-modal-open {
  position: fixed !important;
  overflow: hidden !important;
}

body.filter-modal-open .modal-scrollable {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

/* Delete Modal */
#delete-dialog .row {
  margin: 5%;
}

#delete-dialog .row .btn {
  width: 100%;
  height: 100%;
  font-weight: 900;
}

/* Mobile Filter Dialog */

@media (max-height: 755px) {
  #filter-dialog {
    position: absolute;
    height: auto;
    top: 0 !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    left: 35%;
  }

  .UserAdmin #footer {
    position: inherit;
  }

  #admin-page .modal-scrollable {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  #admin-page .filterName {
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  #filter-dialog .form-group {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #filter-dialog .modal-dialog,
  #filter-dialog .modal-content {
    height: initial;
  }

  #filter-dialog .modal-header,
  #filter-dialog .modal-footer {
    height: 75px !important;
  }

  #filter-dialog .modal-body {
    width: 100% !important;
    padding: 0 !important;
  }

  #filterFrm .ms-sel-ctn {
    overflow-x: hidden;
    overflow-y: auto;
    width: 87%;
  }
}

@media (max-width: 979px) {
  #filter-dialog {
    height: initial;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }

  #filter-dialog .modal-body form.well {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    margin-bottom: 0;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }

  #filter-dialog .form-group {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #filter-dialog .modal-dialog,
  #filter-dialog .modal-content {
    height: initial;
  }

  #filter-dialog .modal-header,
  #filter-dialog .modal-footer {
    height: 10% !important;
  }

  #filter-dialog .modal-body {
    height: 80% !important;
    width: 100% !important;
    padding: 0 !important;
  }

  #filter-dialog .close {
    font-size: 2em;
  }
}

@media (max-width: 760px) {
  #filter-dialog .modal-content {
    -webkit-overflow-scrolling: touch;
  }

  #filter-dialog .modal-header,
  #filter-dialog .modal-footer {
    height: 5em !important;
  }

  #filter-dialog .modal-body form.well {
    overflow-y: hidden;
  }

  #filter-dialog .modal-body form#filterFrm {
    overflow: hidden;
  }

  #BargeDetailsPage span.label {
    font-size: 18px !important;
  }
}

/* Dashboard Demurrage Styling */
#dashboard .demurrage .dem-row .dem-section .dem-header {
  color: white;
}

/* Request Account Form */
.accountOptions i {
  display: block;
}

/*Demurrage Charts on Dashboard*/
#chartAll {
  display: inline-block;
  position: absolute;
  top: 0;
  padding-top: 9px;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

#chartDest {
  display: inline-block;
  position: absolute;
  top: 0;
  padding-top: 9px;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

#chartOrigin {
  display: inline-block;
  position: absolute;
  top: 0;
  padding-top: 9px;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

/*END Demurrage Charts on Dashboard*/

/* Notification Page */
#AlertsPage #btnAddAlert {
  margin-bottom: 5px;
}

.email-section,
.text-section {
  position: relative;
}

#AlertsPage .alert-section,
#AlertsPage .alert-btns {
  width: 15%;
  display: inline-block;
  padding-right: 8px;
}

#AlertsPage .filter.alert-section {
  width: 40%;
}

#AlertsPage h4 {
  margin-left: 15px;
  margin-bottom: 25px;
}

#AlertsPage .contact-info {
  display: block;
}

#AlertsPage .contact-info .contact-section {
  display: block;
  margin-bottom: 10px;
}

#AlertsPage .AlertPhone {
  display: block;
}

#AlertsPage .register-mobile {
  color: white;
  cursor: pointer;
}

#AlertsPage #PhoneNumber {
  font-size: 0.8em;
}

#AlertsPage #PhoneNumber::-ms-clear {
  display: none;
}

#AlertsPage .alert-phone-save-icons {
  display: inline-block;
  height: 22px;
  width: 26.5px;
}

#AlertsPage .alert-phone-save-icons .fa-spin {
  margin-left: 5px;
}

#AlertsPage #btnEditalert {
  width: 20%;
}

#AlertsPage .enabled-platforms .fa-times-circle {
  color: white;
}

#AlertsPage .enabled-platforms .fa-check-circle {
  color: white;
}

#AlertsPage #btnDeleteAlert,
#AlertsPage #btnEditAlert {
  cursor: pointer;
  margin: 5px;
}

#AlertsPage .optout-container {
  margin: 0 auto;
}

#AlertsPage .globalOptout ul {
  margin-top: 20px;
}

#AlertsPage .globalOptout button {
  overflow: hidden;
  word-wrap: break-word;
  max-width: 175px;
}

#AlertsPage .alert-section {
  overflow: hidden;
  text-overflow: ellipsis;
}

#AlertsPage .Email label {
  display: inline;
}

#AlertsPage .optout-btn,
#AlertsPage .save-number {
  display: inline-block;
  height: 30px;
  width: 75px;
  border: none;
  font-size: 0.7em;
  float: none;
  border-radius: 4px;
}

#AlertsPage .opted-out {
  opacity: 0.6;
}

#AlertsPage .opted-out:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid white;
  width: 70px;
}

#AlertsPage .optout-btn.btn-success {
  background: linear-gradient(#32b818, #336e1e);
}

#btn-success-notif {
  border: none;
}

#AlertsPage .optout-btn.btn-danger {
  background: linear-gradient(#b94a48, #ab0909);
}

#AlertsPage .optout-btn.btn-success.active {
  background: radial-gradient(#32b818, #336e1e);
}

#AlertsPage .optout-btn.btn-danger.active {
  background: radial-gradient(#b94a48, #ab0909);
}

#AlertsPage .enabled-platforms .AlertPhone {
  display: inline-block;
}

#AlertsPage .enabled-platforms .save-number {
  width: auto;
  float: none;
}

#AlertsPage .enabled-platforms .AlertPhone input {
  height: 30px;
  text-indent: 0.33em;
  border-radius: 4px;
  outline: 0;
  padding: 2px 0.3em;
  line-height: 1.6em;
  border-width: 1px;
  border-style: solid;
  width: 8.5em;
  border-color: #c5c5c5;
}

#AlertsPage .enabled-platforms p,
#alert-email {
  display: inline-block;
  margin: 0;
}

#AlertsPage .enabled-platforms {
  margin-bottom: 20px;
  margin-left: 15px;
}

#AlertsPage .remove-number {
  color: white;
  cursor: pointer;
  margin-left: 5px;
  font-size: 0.75em;
  white-space: nowrap;
}

#AlertsPage .phone-buttons {
  display: inline-block;
}

#filter-modal {
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  #AlertsPage .alert-section {
    width: 80% !important;
    padding-right: 0;
  }

  #AlertsPage .alert-btns {
    width: 20%;
    position: absolute;
    top: 40%;
    padding-right: 0;
  }

  #AlertsPage #NotificationOptionSource {
    width: 100% !important;
  }

  #AlertsPage .form-btns {
    margin-top: 5px;
  }

  #AlertsPage .Email {
    padding: 0;
    word-break: break-all;
  }

  #AlertsPage .form-btns #btnCreateAlert,
  #AlertsPage .form-btns #btnUpdateAlert {
    font-size: 0.9em;
    width: 96px;
  }

  #AlertsPage .form-btns #btnCancelAlert {
    text-align: center;
    font-size: 0.9em;
    width: 71px;
  }
}

@media (max-width: 370px) {
  #AlertsPage .optout-btn,
  #AlertsPage .AlertPhone {
    margin-bottom: 5px;
  }
}

@media (max-width: 439px) {
  #AlertsPage .phone-buttons {
    margin-top: 5px;
  }
}

/*Schedule Page -  Handle Text overflow*/
#UserSchedules .time-display {
  overflow: hidden;
  word-wrap: break-word;
}

/* User Admin */
#bargelist .container {
  position: relative;
}

#bargelist .user-admin-login {
  max-width: 303px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#bargelist .user-admin-company-name {
  max-width: 80px;
}

@media (max-width: 768px) {
  #bargelist .user-admin-active {
    display: none;
  }

  #bargelist .user-admin-edit {
    display: block;
    width: 36px;
    height: 47px;
  }

  .reference th:first-child {
    display: block;
    width: 36px;
    height: 55px;
  }
}

@media (max-width: 738px) {
  #bargelist .user-admin-internal {
    display: none;
  }
}

@media (max-width: 680px) {
  #bargelist .user-admin-company-number {
    display: none;
  }
}

@media (max-width: 645px) {
  #bargelist .user-admin-company-name {
    display: none;
  }

  #bargelist .user-admin-login {
    width: 100%;
  }
}

@media (max-width: 560px) {
  #bargelist .user-admin-name {
    display: none;
  }
}

@media (max-width: 448px) {
  #bargelist .user-admin-login {
    max-width: 303px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 387px) {
  #bargelist .user-admin-login {
    max-width: 200px;
  }
}

/* Dashboard responsive mobile*/

.towLogo {
  padding: 1em;
  text-align: center;
  display: inline-block;
  text-decoration: none !important;
  margin: 0 auto;
}

.p-y-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-t-10 {
  padding-top: 10px;
}

.m-y-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.left {
  float: left;
}

.right {
  float: right;
}

/* shudeles */
#ScheduleEdits {
  text-align: left;
}

.container {
  width: 100%;
  text-align: center;
}

.center {
  text-align: center;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.text-left {
  text-align: left;
}

@media (max-width: 767px) {
  #DashboardTiles .tile.double {
    height: 100px;
  }

  .boatIcon {
    margin-top: 25px;
  }

  .tile.double .display:first-child {
    height: 100%;
  }

  #DashboardTiles .tile .hasFocus .focusInfo {
    height: 57px;
  }
}

/* The side navigation menu */
#sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.2s;
}

.sidenav-text.user-name {
  text-transform: capitalize;
}

#sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#sidenav ul.components {
  padding: 20px 0;
}

#sidenav ul p {
  color: #fff;
  padding: 10px;
}

#sidenav ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidenav li {
  border-bottom: 1px solid #434444;
}

.sidenav-text {
  text-align: center;
  color: white;
  margin-top: 5px;
}

.action-buttons div {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* reportcenter */
.reports-panel-title {
  font-size: 25px;
  text-align: left;
}

/* BARGE LIST */
#BargeData .section.link td {
  text-align: start;
}

/* Boat Postion */
#BPData .section.link td {
  text-align: start;
}

/* validations error */
small.help-block {
  display: inline-block;
}

/* iphone5 */
@media (max-width: 320px) {
  .vessel-icon {
    padding: 0 !important;
  }

  /* boat description tow  130px*/
  #TowDiagramPlaceholder {
    margin-top: 150px !important;
  }
}

@media (max-width: 767px) {
  .panel-default,
  .panel-footer {
    background-color: rgba(30, 30, 30, 0.6);
  }

  .reports-panel-title {
    font-size: 20px;
  }

  .action-buttons div {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .FlexyMap-container {
    position: unset;
    padding: 0;
    height: 100%;
    /*padding-left:25%;*/
  }

  #AddReport {
    padding-left: 25px;
  }

  /* message view */
  .iconImg {
    font-size: 30px;
    line-height: 40px;
  }

  /* .preview .title, .preview .summary{
        display: inline-block;
        width: 100%;
    } */

  .summary {
    width: 150px;
    overflow: hidden;
    display: -webkit-box;
  }

  .preview div {
    padding: 0;
  }

  /* BARGES LIST */
  #BargeData .section.link td {
    width: 100%;
  }

  #BargeData .section.link .BARGE {
    float: left;
    padding: 5px;
  }

  #BargeData .section.link .arrow-btn {
    float: right;
    padding-right: 10px;
  }

  #BargeData .description-td {
    padding-bottom: 0;
    padding-top: 0;
  }

  #BargeData .title-td {
    padding-bottom: 0;
  }

  #BargeData .BARGE {
    padding-bottom: 0;
    font-size: 19px;
    font-weight: 900;
  }

  .description-td span {
    font-size: 14px !important;
  }

  #bargelistContainer .panel-heading {
    background-color: #2e2e29 !important;
  }

  #bargeSortList {
    min-width: 125px;
    text-align: left;
  }

  #bargeSortList a {
    font-size: 16.5px;
    font-family: "robotolight", sans-serif;
  }

  #bargeSortList span {
    color: white;
  }

  #bargeSortList li {
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  /* Boat postions */

  #boatlistContainer .panel-heading {
    background-color: #2e2e29 !important;
  }

  #BPData .section.link td {
    width: 100%;
  }

  #BPData .section.link .BOAT {
    float: left;
    padding: 5px;
  }

  #BPData .section.link .arrow-btn {
    float: right;
    padding-right: 10px;
  }

  #BPData .BOAT {
    padding-bottom: 0;
    font-size: 19px;
    font-weight: 900;
  }

  #BPData .description-td {
    padding-bottom: 0;
    padding-top: 0;
  }

  #BPData .description-td.small {
    width: 50% !important;
  }

  #boatSortList {
    min-width: 100px;
    text-align: left;
  }

  #boatSortList a {
    font-size: 16.5px;
    font-family: "robotolight", sans-serif;
  }

  #boatSortList span {
    color: white;
  }

  #boatSortList li {
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  /* Conditions */

  #riverconditions a {
    padding-left: 3px;
    padding-right: 3px;
  }

  #Conditions .panel-default {
    margin: 0;
  }

  /* mainheader */

  .return-btn {
    display: none !important;
  }

  /* bargeDetails */

  #BargeDetails .container {
    padding: 0;
  }

  /* #BargeDetails .well.grey a{
        padding-top: 10px;
        padding-right: 10px;
    } */

  #BargeDetails .mail-icon {
    margin-top: 10px;
    margin-right: 10px;
  }

  .map-container {
    padding: 0;
  }

  .map-container .well {
    padding: 0;
  }

  #TDBargeDetailBoxSmall {
    text-align: start;
    margin-bottom: 10px;
  }

  #TDBargeDetailBoxSmall .detailsLink {
    text-align: end;
  }

  #TDBargeDetailBoxSmall .name {
    font-weight: 900;
  }

  /* Schudules */
  #ScheduleEdits .title {
    margin-top: 10px;
  }

  #CannedReportSource,
  #ReportFilterSource,
  #EmailListDropdown,
  #FileName {
    width: 100% !important;
  }

  .checkbox-group-days {
    text-align: start;
  }

  #FileTypeGroup label,
  #ReportTypeGroup label {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  /* OD Pairs */

  #ODRiver {
    width: 100% !important;
  }

  /* Boat details */
  #BoatDetails img {
    border: 15px solid rgba(141, 48, 61, 0.5) !important;
  }

  #BoatDetails .well {
    background-color: transparent !important;
    border: none;
  }

  #BoatDetails .title,
  #TowDiagramContainer .title {
    margin-top: 10px;
    font-size: 18px !important;
    font-weight: 900 !important;
  }

  #TowDiagramContainer div:first-child {
    background-color: transparent;
    border: 0;
    padding: 0;
  }

  /* reports */
  .heading-xs {
    background-color: #2e2e29 !important;
  }

  /* Custom reports */

  #createReportName {
    margin: 0 !important;
  }

  #AddColumn {
    margin-top: 10px;
  }

  .reportColumn {
    margin-top: 5px;
  }

  #ReportsPage .noSort {
    font-size: 110%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #ReportsPage .panel-heading {
    background-color: #2e2e29 !important;
  }

  #IncludedColumns input {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  #ReportsButtonRow button {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  /* announcements */

  #AnnouncementDetails .form-group {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  #Visibility.row a > div,
  #MessageType.row a > div {
    margin: 10px;
    padding: 35px 0 0 !important;
  }

  #Announcements button {
    margin-right: 5px;
    margin-left: 5px;
  }

  /* User administration */

  #boatListTemplate {
    background-color: #2e2e29 !important;
  }

  /* New user */
  .reportcenterinner {
    text-align: start;
  }

  .new-user-footer {
    margin-top: 15px !important;
    margin-bottom: 100px !important;
  }

  /* ERROR modal */
  #AJAXErrorModal {
    width: 100%;
    left: 0;
    margin-left: 0;
  }
}

/* Portrait tablets and small desktops - IPAD*/
@media (min-width: 768px) and (max-width: 991px) {
  .iconImg {
    font-size: 40px;
    line-height: 40px;
  }

  .return-btn,
  .main-panel-return-link {
    display: none !important;
  }

  /* container */
  .container {
    max-width: 100%;
  }

  /* Map */

  .FlexyMap-container {
    position: unset;
    padding: 0;
    height: 100%;
  }

  #myMapLinks {
    top: 200px !important;
  }

  #AnnouncementDetails .form-group {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  #Visibility.row a > div,
  #MessageType.row a > div {
    margin: 10px;
    padding: 35px 0 0 !important;
  }

  #Announcements button {
    margin-right: 5px;
    margin-left: 5px;
  }

  /*bargeDetails - tow*/
  #TowDiagramPlaceholder {
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
    margin-top: 0 !important;
  }

  /* dashboard-page */
  .tile.double .display:first-child {
    height: 80px;
  }

  .boatIcon {
    height: 50px;
  }

  #DashboardTiles .double .focusInfo .data {
    line-height: 20px;
  }

  #DashboardTiles .hasFocus.btn-two button {
    margin-top: 5px;
  }
}

#main-header .title {
  color: white;
  font-size: 17.5px;
}

#main-header .header-icon {
  position: relative;
  display: block;
  margin: 0;
  padding: 5px 10px;
  font-size: 20px;
  line-height: 1.8;
  box-shadow: none;
  border-radius: 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
  transition: 0.08s ease-in;
  -o-transition: 0.08s ease-in;
  -ms-transition: 0.08s ease-in;
  -moz-transition: 0.08s ease-in;
  -webkit-transition: 0.08s ease-in;
  float: right;
  border: none;
}

.dropdown-menu.header-dropdown {
  right: 0;
  left: auto;
}

#map-back-button button {
  width: 40px;
  height: 40px;
}

.ellipse {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.three-lines {
  -webkit-line-clamp: 3;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  white-space: normal;
}

#header-main-dropdown {
  text-align: right;
  padding-left: 5px;
  padding-right: 5px;
}

/* Settings */

.list-xs li {
  background-color: rgba(30, 30, 30, 0.6) !important;
}

.list-xs a,
.list-xs span {
  color: white;
}

.list-xs span:first-child {
  padding-right: 10px;
}

.right-arrow-btn {
  padding-right: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

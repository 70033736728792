/*
* Kendo UI v2014.3.1119 (http://www.telerik.com/kendo-ui)
* Copyright 2014 Telerik AD. All rights reserved.
*
* Kendo UI commercial licenses may be obtained at
* http://www.telerik.com/purchase/license-agreement/kendo-ui-complete
* If you do not own a commercial license, this file shall be governed by the trial license terms.
*/
.k-chart .k-mask {
  opacity: 0.68;
  background-color: #fff;
  filter: alpha(opacity=68);
  -moz-opacity: 0.68;
}
.k-chart .k-selection {
  -webkit-transition:
    box-shadow 0.2s linear,
    border-color 0.2s linear;
  -moz-transition:
    box-shadow 0.2s linear,
    border-color 0.2s linear;
  -o-transition:
    box-shadow 0.2s linear,
    border-color 0.2s linear;
  transition:
    box-shadow 0.2s linear,
    border-color 0.2s linear;

  border-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 8px rgba(0, 0, 0, 0.1);
}
.k-chart .k-selection:hover {
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
}
.k-chart .k-handle {
  background-color: #fcfcfc;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.k-chart .k-handle:hover {
  border-color: #b8b8b8;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 2px rgba(111, 101, 96, 0.5);
  -moz-box-shadow: 0 0 0 2px rgba(111, 101, 96, 0.5);
  box-shadow: 0 0 0 2px rgba(111, 101, 96, 0.5);
}
.k-chart .k-navigator-hint .k-tooltip {
  color: #242424;
  border: 3px solid #fff;
  background: #fff;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}
.k-chart .k-navigator-hint .k-scroll {
  height: 4px;

  background: rgba(252, 116, 26, 0.7);
}
.k-map .k-marker {
  background-image: url("Default/markers.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .k-map .k-marker {
    background-image: url("Default/markers_2x.png");
  }
}
.k-map .k-attribution {
  color: #666;
}

/*!
 * Bootstrap Modal
 *
 * Copyright Jordan Schroter
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Boostrap 3 patch for for bootstrap-modal. Include BEFORE bootstrap-modal.css!
 */

body.modal-open,
.modal-open .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {
  margin-right: 0;
}

.modal {
  left: 50%;
  bottom: auto;
  right: auto;
  padding: 0;
  width: 500px;
  margin-left: -250px;
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
}

.modal.container {
  max-width: none;
}
